import {NavLink} from "react-router-dom";
import {useState} from "react";
import {setInQuestionCatalog, toSlug} from "../../../utils/helpers";
import {useAuth} from "../../../hooks/useAuth";
import {setCurrentCatalog, setSelectedMenuPoint} from "../../../utils/breadcrumb";

export default function SidebarLink({category, collapsedSidebar, setCollapsedSidebar, arrowsVisible, setArrowsVisible}) {
    const {makeAuthenticatedRequest} = useAuth();

    useState(() => {},[makeAuthenticatedRequest,category.id]);

    return (
        <NavLink
            id={`erecht24-category-${category.id}`}
            to={`/categories/${category.id}/${toSlug(category.title)}/questions/${category.firstSimpleQuestion.id}/${toSlug(category.firstSimpleQuestion.title)}/0`}
            className={({isActive})=>{
                return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));
            }}
            key={category.id}
            onClick={(e) => {
                e.target.parentElement.classList.add('active');
                setInQuestionCatalog(true);
                setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
                setCurrentCatalog(category.title);
                if (!collapsedSidebar) {
                    setCollapsedSidebar(!collapsedSidebar);
                    setArrowsVisible(!arrowsVisible);
                } else {
                    setCollapsedSidebar(true);
                }
            }}
        >
            <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>{category.title}</span>
        </NavLink>
    );
}

