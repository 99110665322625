import {Link} from "react-router-dom";

export function Message (props) {
    const { error, resetErrorBoundary } = props;
    return (
        <>
            <div className="main-wrapper xl:py-[54px] xl:px-[60px] flex justify-center lg:p-6 w-full pb-[100px] lg:pb-[120px] bg-white lg:bg-ere-background-grey  ">
                <div className="flex flex-col items-center xl:bg-none w-full lg:w-auto lg:scale-[0.78] xl:scale-[1]">
                    <div className="flex h-auto content-wrapper w-full justify-center">
                        <div className="relative w-full">
                            <div className="bg-white outlet-wrapper p-2 lg:p-6 lg:ml-6 lg:w-[1011px] min-h-[724px] h-fit lg:shadow-sm flex flex-col items-center md:justify-start  relative align-baseline ">
                                <div className="flex flex-col text-center text-ere-content-blue justify-center content-center w-[90%] mb-8">
                                    <h1 className="text-xl mb-3">Hier ist ein Fehler aufgetreten: {error.message}</h1>
                                </div>
                                <Link to="/" className="md:w-fit">
                                    <button className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover">
                                        <button className='btn' onClick={resetErrorBoundary}>
                                            Erneut versuchen?
                                        </button>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Message;