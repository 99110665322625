import {useMemo, useState} from "react";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/sort-icon.svg";

export default function SelectColumnFilter({column: { filterValue, setFilter, preFilteredRows, id },}) {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    const [showFilter, setShowFilter] = useState(false);

    return (
        <div className="inline-block relative">
            <button onClick={(e)=> {
                setShowFilter(!showFilter);
                e.stopPropagation();

            }}><FilterIcon /></button>
            {showFilter &&
                <div className="block">
                    <select
                        onClick={(e) => e.stopPropagation()}
                        value={filterValue}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                        }}
                    >
                        <option value="">Alle</option>
                        {options.map((option, i) => (
                            <option key={i} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            }
        </div>
    );
}