import GaugeChart from "react-gauge-chart";

export default function Meter({riskValue}){

    return (
        <div className=" flex flex-col self-center justify-center  mr-[12px]">
            <GaugeChart className="flex justify-center items-center mt-[-10px] scale-[1.2]"
                        style={{ width: '104px', height:'78px'}}
                nrOfLevels={5}
                arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                colors={["#ff6146","#f7854d","#ffd632", "#5eeac3", "#38c499"]}
                percent={1 - riskValue/100}
                arcPadding={0.05}
                textColor="black"
                animDelay ={500}
                hideText={true}
                cornerRadius={1}
                arcWidth={0.3}
            />
            <p className="text-base self-center text-ere-content-blue ml-[22px] mt-[-10px]">Ihr Risiko</p>
        </div>
    );
}


