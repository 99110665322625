import React from "react";
import ReactDOM from "react-dom";

import NotificationsManager from "./NotificationsManager";
import Notification, { Color } from "./Notification";

let notify;

function renderManager() {
    if(document.ert_shadow) {
        ReactDOM.render(
            <NotificationsManager
                setNotify={(notifyFn) => {
                    notify = notifyFn;
                }}
            />,
            document.ert_shadow.querySelector("[id=notifyContainer]")
        );
    } else {
        setTimeout(renderManager, 100)
    }
}
renderManager();

export { Notification, Color };

export function infoNotification(children, autoClose) {
    return notify({
        color: Color.info,
        children,
        autoClose,
    });
}

export function successNotification(children, autoClose) {
    return notify({
        color: Color.success,
        children,
        autoClose,
    });
}

export function warningNotification(children, autoClose) {
    return notify({
        color: Color.warning,
        children,
        autoClose,
    });
}

export function errorNotification(children, autoClose) {
    return notify({
        color: Color.error,
        children,
        autoClose,
    });
}