import {toSlug} from "../../../../utils/helpers";
import {Link} from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow-up.svg";
import {ReactComponent as AttentionIcon} from "../../../../assets/icons/attention-icon.svg";
import {useState} from "react";
import {fetchCategoryQuestions} from "../../../../utils/api/categories";
import {useAuth} from "../../../../hooks/useAuth";
export const COLUMNS = [
    {
        Header:'Kategorie',
        accessor: 'skipped_question',
        disableFilters: true,
        disableSortBy: true,
        Cell:(cellInfo) => {
            const [categoryQuestions, setCategoryQuestions] = useState([]);
            const [isLoading, setIsLoading] = useState(false);
            const {makeAuthenticatedRequest, accessToken} = useAuth();

            useState(() => {
                if(accessToken && cellInfo.row.original.category.answer_progress_by_category_only.questions !== 0) {
                    fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setCategoryQuestions, setIsLoading, cellInfo.row.original.category.id);
                }
            },[makeAuthenticatedRequest, cellInfo.row.original.category.id]);

            function getIndexForOpenQuestion(question_id) {
                return categoryQuestions.indexOf(categoryQuestions.find((question) => question.id === question_id));
            }

            let questions = cellInfo.value.map((question) => (
                <div className="dropdown-item min-h-[4ppx] pt-[5px] pb-[5px] items-center " key={question.id}>
                        {question.title}
                        <Link
                            to={`/categories/${cellInfo.row.original.category.id}/${toSlug(cellInfo.row.original.category.title)}/questions/${question.id}/${toSlug(question.title)}/${getIndexForOpenQuestion(question.id)}`}
                            className="ml-2 text-ere-link-blue uppercase underline inline w-[80px]"
                        >
                            <ArrowRight className="lg:hidden w-5 inline"/>
                            <span className="hidden text-xs lg:inline">Zur Frage</span>
                        </Link>

                </div>
            ))
            return (
                <div className="toggle-dropdown" >
                    <div className="hide category-text" onClick={(e)=>{
                        const button = e.target;
                        button.classList.toggle('hide');
                    }}>
                        <ArrowUp className="sort-icon inline ml-1 mb-1 mr-[10px] cursor-pointer pointer-events-none" />
                        {cellInfo.row.original.category.title}</div>
                    <div className="dropdown-menu mt-[10px] mb-[10px]">
                            {questions}
                    </div>

                </div>
            )
        }
    },
    {
        Header:'Anzahl offene Fragen',

        disableFilters: true,
        accessor: 'skipped_question.length',
        Cell:(cellInfo) => {


            if (cellInfo.value > 1) {
                return <p><span className="flex w-[132px]"><AttentionIcon
                    className="mr-[5px]"/> {cellInfo.value} <span className="hidden md:flex ml-[5px]"> offene Fragen</span></span></p>
            } else {
                return <p><span className="flex w-[132px]"><AttentionIcon
                    className="mr-[5px]"/> {cellInfo.value} <span className="hidden md:flex ml-[5px] "> offene Frage</span></span></p>
            }

        }
    },
]