import { useEffect } from 'react';

const useDsLoaderSolution = url => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        if (process.env.REACT_APP_STAGE === 'shadow') {
            document.ert_shadow.appendChild(script);
        } else {
            document.ert_shadow.body.appendChild(script);
        }

        return () => {
            if (process.env.REACT_APP_STAGE === 'shadow') {
                document.ert_shadow.appendChild(script);
            } else {
                document.ert_shadow.body.appendChild(script);
            }
        }
    }, [url]);
};

export default useDsLoaderSolution;