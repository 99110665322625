import {useState} from "react";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus-icon.svg";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import {deleteContract, getContractFile} from "../../../utils/api/contracts";
import {useAuth} from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {warningNotification} from "../../../notify";

export default function Upload({prefilled}) {
    const [file, setFile] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { makeAuthenticatedRequest, accessToken, makeGeneralRequest } = useAuth();

    function downloadContract(contract) {
        getContractFile(accessToken, makeAuthenticatedRequest, contract, setIsLoading);
    }

    function handleChange(event) {
        setFile(event.target.files)
    }

    return(
        <div className="mb-6">
            { (prefilled && prefilled.filelink)  ?
                <div className="w-full h-[67px] p-[24px] bg-sky-50 rounded-sm border-2 border-sky-600 border-opacity-20 justify-between items-start inline-flex">
                    <div className="h-[19px] justify-start items-end gap-3 flex">
                        <div className="text-sky-950 text-base font-bold">{prefilled.name}</div>
                    </div>
                    <div className="justify-start items-center gap-[20px] flex">
                        <button className="cursor-pointer" onClick={() => {downloadContract(prefilled)}}>
                            <DownloadIcon className="w-[18px] h-[18px]" />
                        </button>
                        <button onClick={() => {
                            deleteContract(accessToken,makeGeneralRequest,setIsLoading, prefilled.id)
                            warningNotification("Die Datei wurde entfernt", false);
                            navigate(-1);
                        }}>
                            <CloseIcon/>
                        </button>
                    </div>
                </div>
            :
                <label className="relative">
                    <input type="file" accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip" name="upload" className=" mt-[105px] h-[40px] block opacity-0 absolute" onChange={handleChange}
                           onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie eine Datei aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                    <div className="w-full h-36 py-[40px] rounded-sm border-2 border-dashed border-sky-600 border-opacity-40 flex-col justify-center items-center gap-1 inline-flex">
                        <header className="px-10 py-8 text-center flex items-center flex-col">
                            <PlusIcon className="w-[22px] h-[22px] cursor-pointer hover:scale-125 ease-out"/>
                            {file.length !== 0 ?
                                <div><span className="text-neutral-700 text-base font-normal "></span><span className="text-sky-600 text-base font-bold  underline  cursor-pointer">{file[0].name}</span></div>
                            :
                                <div><span className="text-neutral-700 text-base font-normal "></span><span className="text-sky-600 text-base font-bold  underline  cursor-pointer">Datei wählen</span><span className="text-neutral-700 text-base font-normal "> zum Hochladen</span></div>
                            }

                            <div className="text-neutral-700 text-opacity-60 text-base font-normal ">
                                .pdf/.xlsx/.docx/.zip, maximale Dateigröße 50 MB
                            </div>
                        </header>
                    </div>

                </label>
            }
        </div>
    );
}