import {useEffect} from "react";

export default function Textfield({questionAnswers, prefilled, texttype, placeholderText, setIsAnswerGiven}) {
    let content;

    useEffect(() => {
        setIsAnswerGiven(true);
    },[prefilled]);
    useEffect(() => {
        setIsAnswerGiven(true);
    },[texttype]);

    if (texttype === "textfield") {
        content = <input type="text" name="textfield" id={`erecht24-textfield-${questionAnswers[0].id}`} className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5" placeholder={placeholderText !== undefined && placeholderText !== null && placeholderText !== '' ? placeholderText : 'Bitte füllen Sie dieses Feld aus'} defaultValue={prefilled !== undefined ? prefilled : ""} required/>
    } else {
        content = <textarea name="textfield" id={`erecht24-textfield-${questionAnswers[0].id}`} rows="4" className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5" placeholder={placeholderText !== undefined && placeholderText !== null && placeholderText !== '' ? placeholderText : 'Bitte füllen Sie dieses Feld aus'} defaultValue={prefilled !== undefined ? prefilled : ""} required/>
    }

    return(
        <div className="my-6 mb-6 ">
            {content}
        </div>
    );
}