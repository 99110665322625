import { ReactComponent as ArrowsSvg } from "../assets/icons/arrows.svg";
import {useEffect, useState} from "react";
import {fetchAddresses} from "../utils/api/adresses";
import {useAuth} from "../hooks/useAuth";
import {loggedInUser} from "../utils/helpers";

export default function Header({collapsed, collapsedFunction, arrowsVisibleFunction}) {
    const [addresses, setAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken } = useAuth();

    useEffect(() => {
        if(addresses.length === 0) {
            fetchAddresses(accessToken, makeAuthenticatedRequest, setAddresses, setIsLoading, "organisation");
        }
    },[makeAuthenticatedRequest, loggedInUser])

    return (
        <div className="h-[74px] w-full absolute top-0 left-0 header">
            {collapsed ?
                <div className="h-[74px] bg-ere-content-blue">
                    <div className="text-2xl text-white font-semibold leading-normal h-[74px] w-[72px] ml-[23px] flex">
                        <span className="icon-collapse-sidebar w-[24px] h-[24px] cursor-pointer transition-all duration-800 ease-in z-2 text-center rotate-180 self-center mb-1" onClick={() => {
                            collapsedFunction();
                            setTimeout(3000);
                            arrowsVisibleFunction();
                        }}>
                            <ArrowsSvg className="w-[30px] h-[30px] scale-[1.2]"/>
                        </span>
                    </div>
                </div>
            :
                <div className="h-[74px] p-6 pt-[20px] justify-center bg-ere-content-blue">
                    {addresses.length === 0 || addresses[0] === null ?
                        <div className="text-2xl text-white font-semibold leading-normal">Firmenname</div>
                    :
                        <div className="text-2xl text-white font-semibold leading-normal" style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{addresses[0].company}</div>
                    }
                </div>
            }
        </div>
    )
}