import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowUpBlue } from "../../../assets/icons/arrow-up-blue.svg";
import '../../../assets/css/table.css';
import {useTable, usePagination, useSortBy, useGlobalFilter, useFilters} from "react-table";
import {COLUMNS} from './Table/columns';
import {GlobalFilter} from "./Table/globalFilter";
import SelectColumnFilter from "./Table/filter";
import {Form} from "react-router-dom";
import {postErtProject} from "../../../utils/api/projects";
import {useAuth} from "../../../hooks/useAuth";
import {useEffect, useState} from "react";
import {loggedInUser} from "../../../utils/helpers";
import Preloader from "../../General/Preloader";
import {fetchToken} from "../../../utils/api/token";
import '../../../assets/css/project-table.css';
import {successNotification, warningNotification} from "../../../notify";

export default function Overview({ertProjects}) {
    const [isLoading, setIsLoading] = useState(false);
    const {makeGeneralRequest, accessToken} = useAuth();

    const tableInstance = useTable({
        columns: COLUMNS,
        data: ertProjects,
        defaultColumn:{ Filter: SelectColumnFilter },
        initialState: { pageIndex: 0, pageSize:5 }
        },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,

    )

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,state,setGlobalFilter,page,nextPage, previousPage, canNextPage, canPreviousPage,state:{pageIndex},pageCount,gotoPage} = tableInstance
    const {globalFilter} = state


    useEffect(()=> {
        document.ert_shadow.querySelectorAll('[name="project"]').forEach((project) => {
            if(loggedInUser && loggedInUser.erecht24_project_uuid == project.value) {
                project.checked = true;
            }
        })
        if(loggedInUser && document.ert_shadow.getElementById('pm20-container') && loggedInUser.erecht24_project_uuid && window.ProjectManager20) {
            window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid)
        }
    },[isLoading, loggedInUser, makeGeneralRequest, window.ProjectManager20]);

    function handleSubmit(e) {
        e.preventDefault();
        if(e.target.project) {
            if(NodeList.prototype.isPrototypeOf(e.target.project)) {
                e.target.project.forEach((currentProject) => {
                    if(currentProject.checked) {
                        setIsLoading(true);
                        successNotification("Das Projekt wurde erfolgreich verlinkt", true);
                        postErtProject(accessToken, makeGeneralRequest, setIsLoading, currentProject.value).then(() => {
                            setIsLoading(true);
                            fetchToken().then(() => {
                                setIsLoading(false);
                                window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid);
                            })
                        });
                    }
                })
            } else {
                if(e.target.project.checked) {
                    setIsLoading(true);
                    successNotification("Das Projekt wurde erfolgreich verlinkt", true);
                    postErtProject(accessToken, makeGeneralRequest, setIsLoading, e.target.project.value).then(() => {
                        setIsLoading(true);
                        fetchToken().then(() => {
                            setIsLoading(false);
                            window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid);
                        })
                    });
                }
            }
        } else {
            warningNotification("Bitte wählen Sie ein Projekt aus", false);
        }
    }

    if (isLoading) {
        return <Preloader/>;
    }

    return (
        <div className="h-fit md:w-full w-full">
            <div className="w-[100%] lg:flex-row">
                <div className="filter-wrapper flex items-center mb-[20px]">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                </div>
                <Form method="post" onSubmit={handleSubmit}>
                    <table {...getTableProps()} state={state}
                           className="w-full light-blue-border text-left text-sm mb-[24px] tasks-table-border project-table">
                        <thead className="light-blue-border uppercase h-[54px] text-ere-content-blue text-[16px]">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className="px-[24px] text-base bg-ere-background-grey h-[65px] " {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className="flex-col inline-flex align-middle pr-[8px]">
                                            {column.render('Header')}
                                        </div>
                                        <div className="h-fit inline-block align-middle">
                                            {
                                                column.isSorted ?
                                                    (
                                                        !column.isSortedDesc ?
                                                            <div className="flex flex-col"><ArrowUp
                                                                className="sort-icon inline ml-1 mb-1"/><ArrowUpBlue
                                                                className="sort-icon rotate-180 inline ml-1 "/></div>
                                                            :
                                                            <div className="flex flex-col"><ArrowUpBlue
                                                                className="sort-icon inline ml-1 mb-1"/><ArrowUp
                                                                className="sort-icon rotate-180 inline ml-1 "/></div>
                                                    )
                                                    :
                                                    <div className="arrows-container flex flex-col"><ArrowUp
                                                        className="sort-icon inline ml-1 mb-1"/><ArrowUp
                                                        className="sort-icon rotate-180 inline ml-1 "/>
                                                    </div>
                                            }

                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="text-base">
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="font-[400] text-[16px] text-ere-grey-alternative h-[53px] bg-white" {...row.getRowProps()} >
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header === "Name") {
                                            return (
                                                <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                    <label className="flex items-center">
                                                        <input style={{accentColor: '#056688'}}
                                                               className="w-4 h-4 bg-ere-checkbox-blue border-ere-checkbox-blue focus:ring-ere-link-blue"
                                                               name="project" type="radio"
                                                               value={cell.render('Cell').props.row.original.uuid}/>
                                                        <svg className="my-check" xmlns="http://www.w3.org/2000/svg"
                                                             width="10"
                                                             height="10" viewBox="0 0 10 10" fill="none">
                                                            <g clip-path="url(#clip0_3766_12870)">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M9.19002 0.743287C9.50526 0.962776 9.59362 1.41221 9.38746 1.74783L4.75145 9.29639C4.69744 9.38434 4.62613 9.45875 4.54245 9.51449C4.45877 9.57023 4.36473 9.60596 4.26681 9.61921C4.16889 9.63246 4.06944 9.62292 3.97533 9.59124C3.88121 9.55957 3.79468 9.50651 3.72171 9.43574L0.721942 6.53245C0.588139 6.40294 0.508142 6.22214 0.49955 6.02983C0.490958 5.83753 0.554475 5.64946 0.676128 5.50701C0.79778 5.36456 0.967604 5.27939 1.14824 5.27025C1.32888 5.2611 1.50553 5.32872 1.63933 5.45823L4.04787 7.789L8.24645 0.953486C8.34542 0.792428 8.50041 0.679807 8.67735 0.64039C8.8543 0.600972 9.0387 0.637984 9.19002 0.743287Z"
                                                                      fill="white"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_3766_12870">
                                                                    <rect width="10" height="10" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span className="">{cell.render('Cell')}</span>
                                                    </label>
                                                </td>
                                            )
                                        } else {
                                            const lastChanged = new Date(cell.render('Cell').props.cell.value)
                                            return (
                                                <td className="border-y border-ere-border-grey py-[17px] px-[24px]" {...cell.getCellProps()}>
                                                    {('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'}
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                    <div className="mt-[16px] w-full flex justify-center pagination-container mb-[16px]">
                        <button disabled={!canPreviousPage}
                                className={`hover:bg-ere-blue hover:text-white w-[30px] h-[30px] rounded-[2px] transition-all duration-100 ease-out  ${!canPreviousPage ? "hidden" : ""}`}
                                onClick={() => previousPage()}><span className="to-left"></span></button>

                        {
                            Array.from(Array(pageCount), (_, index) =>

                                <button type="button"
                                        className={`page-item page-link inline  w-[30px] h-[30px] rounded-[2px] text-xs font-bold hover:bg-ere-blue hover:text-white transition-all duration-100 ease-out ${pageIndex === index ? 'bg-ere-blue text-white' : 'inline'}`}
                                        onClick={() =>
                                            gotoPage(index)
                                        }
                                >
                                    {index + 1}
                                </button>
                            )

                        }

                        <button disabled={!canNextPage}
                                className={`hover:bg-ere-blue hover:text-white w-[30px] h-[30px] rounded-[2px] transition-all duration-100 ease-out ${!canNextPage ? "hidden" : ""}`}
                                onClick={() => nextPage()}><span>></span></button>

                    </div>

                    {isLoading ?
                        <button type="submit"
                                className="hover:btn-secondary flex items-center justify-center h-[45px] w-[200px] place-self-center btn btn-secondary-active-and-hover py-[12px] text-[14px]">
                            <Preloader/>
                        </button>
                        :
                        <button id="erecht24-link-project" type="submit"
                                className="hover:btn-secondary flex items-center justify-center h-[45px] w-[200px] place-self-center btn btn-secondary-active-and-hover py-[12px] text-[14px]">
                            PROJEKT VERLINKEN
                        </button>
                    }
                </Form>
            </div>
        </div>
    );
}