import {apiUrl} from "../helpers";

export async function fetchAnswer(accessToken, makeAuthenticatedRequest, setIsLoading, setState,  questionId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/project-choices/${questionId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    }
}

export async function postAnswer(accessToken, makeGeneralRequest, setIsLoading, formInformation, skipped, questionTree) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/project-choices/${formInformation.question_id}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const body = JSON.stringify({
            question_type: formInformation.question_type,
            answer: formInformation.answer,
            additional_text: formInformation.additional_text,
            additional_data: formInformation.additional_data,
            skipped: skipped,
            question_tree: questionTree
        });
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;

    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchSkippedQuestions(accessToken, makeAuthenticatedRequest, setIsLoading, setState) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/project-choices/list/skipped-by-categories`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}