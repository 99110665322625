import {apiUrl} from "../helpers";

export async function fetchCategories(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/categories`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchCategory(accessToken, makeAuthenticatedRequest, setState, setQuestionTree, setIsLoading,categoryId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/categories/${categoryId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
        if(setQuestionTree !== null) {
            setQuestionTree(data[0].question_tree);
        }
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setState, setIsLoading, categoryId, changedCategoryView) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        if(changedCategoryView) {
            setIsLoading(true)
        }
        const url = `${apiUrl}/questions/category/${categoryId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };

        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
        return data;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}