import man from "../../assets/images/lex2.jpg";
import certificate from "../../assets/images/tuv-zertifikat.png";

export default function Appointment() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };


    return (
        <div className="w-[250px] h-[421px] relative bg-white shadow-sm rounded flex flex-col overflow-hidden p-[24px] text-center items-center">
            <div className="text-2xl font-bold text-ere-content-blue leading-[24px] w-max">Sie haben Fragen?</div>
            <div className="text-sm font-normal text-ere-grey-alternative leading-normal mt-1 ">Kein
                Problem, Ihr persönlicher<br/> Datenschutzbeauftragter<br/> Lev Lexow steht Ihnen zur Verfügung.
            </div>
            <div className="h-20 flex-col self-center align-top text-ere-grey-alternative">
                <div className="mt-[10px]">
                    <span className="font-semibold mt-6 text-xs">Lev Lexow<br /></span>
                    <span className="font-normal break-keep text-xs">TÜV-zertifizierter DSB</span>
                    <img className="w-[100px] h-auto shadow-xs inline" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                </div>

            </div>
            <img className="w-[167px] top-[239px] absolute" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
            <button id="erecht24-question-catalog-appointment" className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 relative text-sm top-[136px] uppercase tracking-[0.233px] font-black"
                    onClick={() => openInNewTab("https://calendly.com/legaltrust")}
            >
                termin vereinbaren
            </button>
        </div>
    )

}