import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {fetchCategory} from "../../utils/api/categories";
import {checkForChildren, setInQuestionCatalog} from "../../utils/helpers";
import Box from "../../components/Content/Category/Box";
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../../components/General/Preloader";
import {resetCurrentCatalog, setCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";

export default function ShowChildren() {
    const location = useLocation();
    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadedCategory, setLoadedCategory] = useState(false);
    const { makeAuthenticatedRequest ,accessToken} = useAuth();

    let {category_id} = useParams();
    let children = Array.isArray(category) ? Object.assign({}, ...category).children : undefined;

    useEffect(()=> {
        setInQuestionCatalog(false);
        if( accessToken ) {
            fetchCategory(accessToken, makeAuthenticatedRequest, setCategory, setIsLoading, category_id).then(() => {
                setLoadedCategory(true);
            })
        }
    },[makeAuthenticatedRequest, category_id, isLoading])

    if (isLoading) {
        return <Preloader/>;
    }

    if(loadedCategory) {
        setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
        setCurrentQuestionCategory(category[0].title, location.pathname);
        resetCurrentCatalog();
    }

    const listCategoryChildren = children?.filter(function(children) {
        if(children.answer_progress_by_category_only.questions !== 0) {
            return children;
        }
    }).map(child => (
        <Box key={child.id} category={child} hasChildren={checkForChildren(child)}/>
    ))

    return(
        <>
            <h1 className="text-2xl font-bold mb-[48px] text-ere-content-blue outlet-title self-start mt-[16px]">{category.length !== 0 ? category[0].title : ""}</h1>
            <div className="w-full category-wrapper  grid md:grid-cols-3 sm:grid-cols-2 xxl:grid-cols-4 flex-wrap gap-[18px] mb-[24px]">
                {listCategoryChildren}
            </div>
        </>
    );
}