import {ReactComponent as AttentionIcon} from "../../../../assets/icons/attention-icon.svg";
import {useEffect} from "react";

export default function AlertBox({warnings}) {
    let listWarnings;

    useEffect(() => {},[warnings])

    if(warnings) {
        listWarnings = warnings.map((warning) => (
            <li className="items-center flex" key={warning.id}> <AttentionIcon className="mr-2 block self-start min-w-[22px] mt-[2px]"/><p className="text-[0.8rem]">{warning.warningtxt}</p></li>
        ))
    }

    return(
        <>
            { warnings.length !== 0 ?
                <div className="border-2 border-ere-orange mb-6 p-2 text-ere-orange">
                    <ul>
                        {listWarnings}
                    </ul>
                </div>
            :
                <></>
            }
        </>
    );
}