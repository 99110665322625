import {Link} from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right.svg";
import {fetchTasks, postTaskDone} from "../../../../utils/api/tasks";
import {useAuth} from "../../../../hooks/useAuth";
import {useState} from "react";
export const COLUMNS =[
    {
        Header:'Aufgabe',
        accessor: 'task.title',
        disableFilters: true,
        Cell:(cellInfo) => {
            const { makeGeneralRequest, makeAuthenticatedRequest, accessToken } = useAuth();
            const [submitIsLoading, setSubmitIsLoading] = useState(false);
            const [tasks, setTasks] = useState([]);

            return (
                <span>{cellInfo.value}</span>

            );
        }
    },
    {
        Header:'Kategorie',
        accessor: 'task_trigger_categories[0].title',
        disableFilters: true,
        Cell:(cellInfo) => {
            if(cellInfo.value !== undefined) {
                return <p>{cellInfo.value}</p>
            } else {
                return <p>-</p>
            }
        }
    },
    {
        Header:'Risiko',
        accessor: 'task.risk.risk_value',
        enableMultiSort:true,
        Cell:(cellInfo)=> {
            if(cellInfo.value > 66){
                return <span><span className="w-[12px] h-[12px] rounded-[50%] mr-[8px] inline-block bg-ere-orange"></span><span className="hidden md:inline">Hohes Risiko</span></span>
            }
            else if(cellInfo.value <= 66 && cellInfo.value >= 33){
                return <span><span className="w-[12px] h-[12px] rounded-[50%] mr-[8px] inline-block bg-ere-yellow"></span><span className="hidden md:inline">Mittleres Risiko</span></span>
            }
            else if(cellInfo.value < 33)
                return <span><span className="w-[12px] h-[12px] rounded-[50%] mr-[8px] inline-block bg-ere-green"></span><span className="hidden md:inline">Niedriges Risiko</span></span>
            else{
                console.log(cellInfo.value)
            }
        }
    },
    {
        Header:'',
        disableSortBy: true,
        disableFilters: true,
        accessor: 'id',
        Cell:(e) => <Link id={`erecht24-task-${e.value}`} className="uppercase text-ere-link-blue underline" to={`/tasks/${e.value}`}><ArrowRight className="lg:hidden w-5" /><span className="hidden lg:flex">Zur Aufgabe</span></Link>
    },

]