import {Link} from "react-router-dom";

export const COLUMNS =[
    {
        Header:'Name',
        accessor: 'organisation_address.company',
        disableFilters: true,
    },
    {
        Header:'E-Mail',
        accessor: 'owner.email',
        disableFilters: true,
    },
    {
        Header: 'Eigentümer Name',
        accessor: 'owner.lastname',
        disableFilters: true,
        Cell: props => <>{props.value}</>
    },
    {
        Header: '',
        disableSortBy: true,
        disableFilters: true,
        accessor: 'id',
        Cell: (e) => <Link className="uppercase text-ere-link-blue underline" to={`/clients/${e.value}`}>Zum
            Mandant</Link>
    }
    /*{
        Header: 'Letzte Änderung',
        accessor: 'modified',
        disableFilters: true
    }*/
]