import Overview from "../../components/Content/Task/Overview";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {setInQuestionCatalog} from "../../utils/helpers";

export default function ShowList() {
    const location = useLocation();

    useEffect(() => {
        setSelectedMenuPoint("Meine Aufgaben", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
    },[])

    return(
        <>
            <h1 className="text-2xl font-bold mb-[16px] text-ere-content-blue outlet-title self-start mt-[16px]">Meine Aufgaben</h1>
            <p className="text-[16px] mb-[48px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Behalten Sie hier Ihre Aufgaben stets im Blick und erledigen diese nach und nach, um Ihren
                Dokumentations- und Nachweispflichten
                nachkommen zu können. Sortieren Sie Ihre Aufgaben nach offenen oder bereits erledigten Aufgaben und
                erkennen schnell und einfach, welche
                Aufgaben dringend zur Senkung Ihres Risikos bearbeitet werden müssen.
            </p>
            <Overview isTaskOverview={true}/>
        </>
    )
}