import Card from "../../components/Content/Risk/Card";
import Greeting from "../../components/General/Greeting";
import AppointmentDashboard from "../../components/Cards/AppointmentDashboard";
import OpenQuestions from "./OpenQuestions";
import OverviewDashboard from "../../components/Content/Task/OverviewDashboard";
import {fetchSkippedQuestions} from "../../utils/api/answer";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {loggedInUser, setInQuestionCatalog} from "../../utils/helpers";
import {resetCurrentCatalog, resetCurrentQuestionCategory, resetSelectedMenuPoint} from "../../utils/breadcrumb";
import {fetchProjects} from "../../utils/api/projects";

export default function Dashboard() {
    const [projects, setProjects] = useState([]);
    const {makeAuthenticatedRequest, accessToken} = useAuth();
    const [skippedQuestionCategories, setSkippedQuestionCategories] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    useEffect(() => {
        if(skippedQuestionCategories.length === 0) {
            fetchSkippedQuestions(accessToken, makeAuthenticatedRequest, setIsLoading, setSkippedQuestionCategories);
        }
        setInQuestionCatalog(false);
        resetSelectedMenuPoint()
        resetCurrentQuestionCategory()
        resetCurrentCatalog()
        if((loggedInUser !== undefined) && ((loggedInUser.protection_officer === 1) || (loggedInUser.support_member === 1)) && projects.length === 0) {
            fetchProjects(accessToken, makeAuthenticatedRequest, setProjects, setIsLoading);
        }
    },[makeAuthenticatedRequest])

    return <>
        <Greeting />
        <Card />
        <div className="flex flex-col md:flex-row grid-cols-3 gap-[24px] w-full mb-[24px]">
            <OverviewDashboard className="grow-1" isTaskOverview={false}/>
            <AppointmentDashboard className="" />
        </div>
        {
            (Object.keys(skippedQuestionCategories).length !== 0 )
                ? <OpenQuestions openQuestions={skippedQuestionCategories}/>
                :  ""
        }
    </>
}