import {apiUrl, setInQuestionCatalog, toSlug} from "../helpers";
import {setCurrentCatalog, setSelectedMenuPoint} from "../breadcrumb";

export async function fetchOwnProcessingActivities(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/opa-categories`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(Array.from(Object.values(data)));
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postOwnProcessingActivity(accessToken, makeGeneralRequest, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true)
        const url = `${apiUrl}/own-processing-activities/0`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        return await makeGeneralRequest(url, customHeaders, 'POST').then((opa) => {
            setInQuestionCatalog(true);
            setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
            setCurrentCatalog(opa.opa_category.title);
            return `/categories/${opa.opa_category.id}/${toSlug(opa.opa_category.title)}/questions/${opa.opa_question.id}/${toSlug(opa.opa_question.title)}/0`;
        });
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function deleteOwnProcessingActivity(accessToken, makeGeneralRequest, setIsLoading, activityId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/own-processing-activities/${activityId}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`
        };

        return await makeGeneralRequest(url, customHeaders, "DELETE");
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}