import {apiUrl} from "../helpers";

export async function createCustomProcessAccess(accessToken, makeGeneralRequest, setCreateIsLoading, type) {
    if (!accessToken) {
        setCreateIsLoading(false);
        return;
    }

    try {
        setCreateIsLoading(true);
        const url = `${apiUrl}/custom-process-access`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const body = JSON.stringify({
            type: type,
            return_url: process.env.REACT_APP_URL
        })
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setCreateIsLoading(false);
    }
}