export default function AdditionalTextfieldBox({textfields, prefilled}) {
    let listTextfields;

    if(textfields) {
        listTextfields = textfields.map((textfield) => (
            <div key={textfield.id} className="my-2">
                <p className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2">Eingabe "{textfield.title}"</p>
                <textarea
                    name={`textfield_${textfield.id}`}
                    rows="4"
                    className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5"
                    placeholder={textfield.placeholder !== undefined && textfield.placeholder !== null && textfield.placeholder !== '' ? textfield.placeholder : `Bitte füllen Sie die Eingabe "${textfield.title}" aus`}
                    defaultValue={prefilled.length !== 0 && prefilled.additional_data && prefilled.additional_data.find((answer) => answer.answer == textfield.id) ? prefilled.additional_data.find((answer) => answer.answer == textfield.id).text : ""}
                    onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                    onInput={e => e.target.setCustomValidity('')}
                />
            </div>
        ))
    }

    return(
        <>
            { textfields.length !== 0 ?
                <div className="mb-3">
                    {listTextfields}
                </div>
            :
                <></>
            }
        </>
    );
}