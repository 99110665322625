import {useState} from "react";
import {ReactComponent as EscapeButton} from "../../../../assets/icons/escape.svg";
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container':{
        /*        backgroundColor:'#1f719f33',
                backgroundOpacity: '20%',
                backdropBlur: '3px',*/
        background: 'rgba(202, 235, 255, 0.40)',
        backdropFilter: 'blur(3px)',
        zIndex:9001,

        '@media (min-width: 320px)': {
            width:'100%',
        },

    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),



    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root':{
        minWidth:'120px',
        '@media (min-width: 1024px)': {
            minWidth:'800px',
        },
        border: 'solid 1px #0094D8',
        borderRadius:'0',
        padding:'20px',
        paddingTop:'30px',
        boxShadow:'none',
        maxWidth:'200px',
        '@media (min-width: 380px)': {
        maxWidth:'800px',
            padding:'40px',
        },

        margin:0,

        '@media (min-width: 340px)': {
            marginLeft:'unset',
            margin:'35px',

        },
    },
    '& .MuiPaper-root h2':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root h1':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root p':{
        fontSize:'16px',
        color:'#444444',
        marginBottom:'20px',
    }
}));

export default function ModalCard({title, content}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <button onClick={handleClickOpen} className="text-ere-link-blue text-left underline mt-[8px]">{title}</button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="min-w-[400px]"
            >
                {/*<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Title
                </DialogTitle>*/}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >

                    <EscapeButton />
                </IconButton>
                <DialogContent
                    /*repositionOnUpdate={false}
                    autoDetectWindowHeight={false}
                    autoScrollBodyContent={false}
                    contentStyle={{
                        width: '100%',
                        maxWidth: '450px',
                        maxHeight: '100% !important'
                    }}
                    bodyStyle={{
                        maxHeight: '100% !important'
                    }}
                    style={{
                        paddingTop:'0 !important',
                        marginTop:'-65px !important',
                        bottom: '0 !important',
                        overflow: 'scroll !important',
                        height: 'auto !important'
                    }}*/>
                    <Typography gutterBottom>
                        <p className="rte-field block text-base text-left text-ere-grey-alternative" dangerouslySetInnerHTML={{__html: content}}/>
                    </Typography>

                </DialogContent>
                {/*<DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions>*/}
            </BootstrapDialog>
        </React.Fragment>
 /*       <>
            <button onClick={showModal} className="text-ere-link-blue text-left underline mt-[8px]">{title}</button>
            {
                modal && (
                    <div className="w-full h-full relative top-0 bottom-0 left-0 right-0 modal-card">
                        <div className="w-full h-full fixed top-0 bottom-0 left-0 right-0 bg-ere-nav-mobile-blue bg-opacity-20 z-100 backdrop-blur-[3px]" onMouseDown={showModal}>
                            <div className=" w-[90%] p-[20px] md:p-[40px] absolute top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:min-w-[200px]  md:max-w-[800px] min-h-[100px] h-fit bg-white flex flex-col animate-menu duration-1000 rounded-sm border border-sky-600">
                                <EscapeButton className="ml-auto absolute right-[5px] top-[5px] md:right-[24px] md:top-[24px] hover:scale-150 hover:cursor-pointer" />
                                <p className="block text-base text-left text-ere-grey-alternative" dangerouslySetInnerHTML={{__html: content}}/>
                            </div>
                        </div>
                    </div>
                )
            }
        </>*/
    );
}