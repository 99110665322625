import React from 'react';
import * as ReactDOM from "react-dom/client";
import {createHashRouter, RouterProvider,} from "react-router-dom";
import Main from "./layout/Main";
import Error from "./error";
import Dashboard from "./views/dashboard/Dashboard";
import ShowCategoryParents from "./views/category/ShowParents";
import ShowCategoryChildren from "./views/category/ShowChildren";
import QuestionCatalog from "./views/category/question/QuestionCatalog";
import ShowTaskList from "./views/task/ShowList";
import ShowTaskDetail from "./views/task/ShowDetail";
import ShowProjectList from "./views/project/ShowList";
import ShowContractList from "./views/contract/ShowList";
import Organisation from "./views/settings/organisation";
import DataProtectionOfficer from "./views/settings/data_protection_officer";
import ShowClientList from "./views/client/ShowList";
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {AuthProvider} from "./contexts/AuthContextProvider"
import {ErrorBoundary} from "react-error-boundary";
import ErrorMessage from "./components/Error/Message";
import NotLoggedIn from "./components/User/NotLoggedIn";
import ShowClientDetail from "./views/client/ShowDetail";
import ShowSkippedQuestions from "./views/skippedquestions/ShowSkippedQuestions";

const router = createHashRouter([
    {
        path: "/",
        element: <ErrorBoundary
            FallbackComponent={ErrorMessage}
            onError={() => console.log("Error happened!")}><AuthProvider><Main/></AuthProvider></ErrorBoundary>,
        errorElement: <Error/>,
        children: [
            {
                path: "/",
                element: <Dashboard/>,
            },
            {
                path: "/categories",
                element: <ShowCategoryParents/>,
            },
            {
                path: "/categories/:category_id/:category_name",
                element: <ShowCategoryChildren/>,
            },
            {
                path: "/categories/:category_id/:category_name/questions/:question_id/:question_name/:index",
                element: <QuestionCatalog/>,
            },
            {
                path: "/tasks",
                element: <ShowTaskList/>,
            },
            {
                path: "/tasks/:task_id",
                element: <ShowTaskDetail/>,
            },
            {
                path: "/projects",
                element: <ShowProjectList/>,
            },
            {
                path: "/contracts",
                element: <ShowContractList/>,
            },
            {
                path: "/organisation",
                element: <Organisation/>,
            },
            {
                path: "/data-protection-officer",
                element: <DataProtectionOfficer/>,
            },
            {
                path: "/clients",
                element: <ShowClientList/>,
            },
            {
                path: "/clients/:id",
                element: <ShowClientDetail/>,
            },
            {
                path: "/categories/skipped-questions",
                element: <ShowSkippedQuestions/>,
            },
        ]
    },
    {
        path: "/no-session",
        element: <NotLoggedIn/>,
    },
]);

if (process.env.REACT_APP_STAGE === 'local') {
    // TODO: don't do that
    document.ert_shadow = document;

    ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
            <RouterProvider router={router}/>
        </React.StrictMode>
    );
}

if (process.env.REACT_APP_STAGE === 'shadow') {
    document.addEventListener('erecht24event', (event) => {
        // TODO: don't do that
        document.ert_shadow = event.detail.shadow;

        ReactDOM.createRoot(event.detail.div).render(
            <React.StrictMode>
                <RouterProvider router={router}/>
            </React.StrictMode>
        );
    })
}