import {NavLink, useLocation} from "react-router-dom";
import "../assets/css/footer.css";
import {ReactComponent as DashboardIcon} from "../../src/assets/icons/footer/dashboard.svg";
import {ReactComponent as TasksIcon} from "../../src/assets/icons/footer/aufgaben.svg";
import {ReactComponent as DataProtectionIcon} from "../../src/assets/icons/footer/datenschutz.svg";
import {ReactComponent as DokumentsIcon} from "../../src/assets/icons/footer/dokumente.svg";
import {ReactComponent as FirmaIcon} from "../../src/assets/icons/footer/firma.svg";
import {useState,useEffect} from "react";


export default function Footer(){
    const [showSubmenu, setShowSubmenu] = useState(false);
   const location = useLocation();
    useEffect(() => {}, [location.pathname]);

    return(
        <div className="footer-wrapper h-[300px]">
        <div className="fixed bottom-nav min-h-[82px] bottom-0 left-0 w-full
         bg-ere-blue px-[20px] z-50 lg:hidden text-xs font-semibold overflow-visible">
            <div className="flex justify-around mt-[15px] xxs:mt-[15px] ">
                <NavLink onClick={()=> setShowSubmenu(false)} className="flex flex-col text-center items-center pr-1 blue" to={`/`}>
                    <DashboardIcon className="w-[26px] h-auto inline-block mt-[12px] " />
                    <span className="mt-[2px] hidden xs:inline text-ere-nav-mobile-blue nav-icon-description">Dashboard</span>
                </NavLink>
                <button className={`has-submenu flex flex-col text-center pr-1 blue items-center relative ${(showSubmenu === true) ? "active" : "" }`}
                onClick={() => {
                    setShowSubmenu(!showSubmenu);
                    const activeNow = document.querySelector('.bottom-nav .active');
                    if(activeNow)
                        activeNow.classList.remove('active');
                }}>
                    {
                        (location.pathname === '/data-protection-officer')
                        ? <FirmaIcon className={`w-[25px] h-auto inline-block mt-[10px] has-to-be-white`} />
                            :
                            <FirmaIcon className={`w-[25px] h-auto inline-block mt-[10px]`} />
                    }

                    <span className={`mt-[5px] hidden xs:inline  text-ere-nav-mobile-blue nav-icon-description ` } >Unternehmen</span>
                    {
                        showSubmenu ?
                            <div
                                className="mobile-submenu absolute bottom-full mb-5 left-0 bg-ere-blue w-[132px] h-[92px] rounded-[4px]">
                                <NavLink onClick={() => setShowSubmenu(false)} to={"/organisation"}
                                         className={({isActive}) => {
                                             return ((isActive ? " active-menu flex items-center hover:text-ere-link-blue  " : "gray flex items-center  hover:text-ere-link-blue "));
                                         }}>
                            <span
                                className={`category-name capitalize h-[46px] flex justify-center items-center w-full pl-0 underline underline-offset-[18px] decoration-[#E0E5EB33]`}>Stammdaten</span>
                                </NavLink>
                                <NavLink onClick={() => setShowSubmenu(false)} to={"/projects"}
                                         className={({isActive}) => {
                                             return ((isActive ? "active-menu flex items-center hover:text-ere-link-blue " : "gray flex items-center hover:text-ere-link-blue"));
                                         }}>
                                    <span
                                        className={`category-name capitalize h-[46px] flex justify-center items-center w-full pl-0`}>Mein Projekt</span>
                                </NavLink>
                            </div>
                            :
                            <div
                                className="hidden mobile-submenu absolute bottom-full mb-5 left-0 bg-ere-blue w-[132px] h-[92px]">
                                <NavLink onClick={() => setShowSubmenu(false)} to={"/organisation"}
                                         className={({isActive}) => {
                                             return ((isActive ? " active-menu flex items-center hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center  hover:text-ere-link-blue  animate-grow transition-height duration-500 "));
                                         }}>
                            <span
                                className={`category-name capitalize h-[46px] flex justify-center items-center w-full pl-0 underline underline-offset-[18px] decoration-[#E0E5EB33]`}>Stammdaten</span>
                                </NavLink>
                                <NavLink onClick={() => setShowSubmenu(false)} to={"/projects"}
                                         className={({isActive}) => {
                                             return ((isActive ? "active-menu flex items-center hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center hover:text-ere-link-blue  animate-grow transition-height duration-500 "));
                                         }}>
                                    <span
                                        className={`category-name capitalize h-[46px] flex justify-center items-center w-full pl-0`}>Mein Projekt</span>
                                </NavLink>
                            </div>
                    }
                </button>
                <NavLink onClick={() => setShowSubmenu(false)}
                         className="flex flex-col text-center pr-1 blue items-center" to={`/categories`}>
                    <DataProtectionIcon className="w-[25px] h-auto inline-block mt-[10px]"/>
                    <span
                        className="mt-[5px] hidden xs:inline  text-ere-nav-mobile-blue nav-icon-description">Datenschutz</span>
                </NavLink>
                <NavLink onClick={() => setShowSubmenu(false)}
                         className="flex flex-col text-center pr-1 blue items-center" to={`/tasks`}>
                    <TasksIcon className="w-[25px] h-auto inline-block mt-[10px]"/>
                    <span
                        className="mt-[5px] hidden xs:inline text-ere-nav-mobile-blue nav-icon-description">Aufgaben</span>
                </NavLink>
                <NavLink onClick={() => setShowSubmenu(false)}
                         className="flex flex-col text-center  pr-1 blue items-center" to={`/contracts`}>
                    <DokumentsIcon className="w-[22px] h-auto inline-block mt-[9px] "/>
                    <span
                        className="mt-[5px] hidden xs:inline text-ere-nav-mobile-blue nav-icon-description">Dokumente</span>
                </NavLink>

            </div>
        </div>
        </div>
    )
}