import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowUpBlue } from "../../../assets/icons/arrow-up-blue.svg";
import '../../../assets/css/table.css';
import '../../../assets/css/clienttable.css';
import {useTable, usePagination, useSortBy, useGlobalFilter, useFilters} from "react-table";
import {COLUMNS} from './Table/columns';
import {GlobalFilter} from "./Table/globalFilter";
import SelectColumnFilter from "./Table/filter";
import {Form} from "react-router-dom";
import {postErtProject} from "../../../utils/api/projects";
import {useAuth} from "../../../hooks/useAuth";
import {useEffect, useState} from "react";
import {loggedInUser} from "../../../utils/helpers";
import Preloader from "../../General/Preloader";
import {fetchToken} from "../../../utils/api/token";
import '../../../assets/css/project-table.css';
import {successNotification, warningNotification} from "../../../notify";

export default function Overview({ertProjects}) {
    const [isLoading, setIsLoading] = useState(false);
    const {makeGeneralRequest, accessToken} = useAuth();
    const tableInstance = useTable({
            columns: COLUMNS,
            data: ertProjects,
            defaultColumn:{ Filter: SelectColumnFilter }},
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,state,setGlobalFilter} = tableInstance
    const {globalFilter} = state

    useEffect(()=> {
        document.ert_shadow.querySelectorAll('[name="project"]').forEach((project) => {
            if(loggedInUser && loggedInUser.erecht24_project_uuid == project.value) {
                project.checked = true;
            }
        })
        if(loggedInUser && document.ert_shadow.getElementById('pm20-container') && loggedInUser.erecht24_project_uuid && window.ProjectManager20) {
            window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid)
        }
    },[isLoading, loggedInUser, makeGeneralRequest, window.ProjectManager20]);

    function handleSubmit(e) {
        e.preventDefault();
        if(e.target.project) {
            if(NodeList.prototype.isPrototypeOf(e.target.project)) {
                e.target.project.forEach((currentProject) => {
                    if(currentProject.checked) {
                        setIsLoading(true);
                        successNotification("Das Projekt wurde erfolgreich verlinkt", true);
                        postErtProject(accessToken, makeGeneralRequest, setIsLoading, currentProject.value).then(() => {
                            setIsLoading(true);
                            fetchToken().then(() => {
                                setIsLoading(false);
                                window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid);
                            })
                        });
                    }
                })
            } else {
                if(e.target.project.checked) {
                    setIsLoading(true);
                    successNotification("Das Projekt wurde erfolgreich verlinkt", true);
                    postErtProject(accessToken, makeGeneralRequest, setIsLoading, e.target.project.value).then(() => {
                        setIsLoading(true);
                        fetchToken().then(() => {
                            setIsLoading(false);
                            window.ProjectManager20.mount(document.ert_shadow.getElementById('pm20-container'), process.env.REACT_APP_PROJECT_MANAGER_ENV, loggedInUser.erecht24_project_uuid);
                        })
                    });
                }
            }
        } else {
            warningNotification("Bitte wählen Sie ein Projekt aus", false);
        }
    }

    if (isLoading) {
        return <Preloader/>;
    }

    return (
        <div className="h-fit md:w-full w-full">
            <div className="w-[100%] lg:flex-row">
                <div className="filter-wrapper flex items-center mb-[20px]">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                </div>
                <Form method="post" onSubmit={handleSubmit}>
                    <table {...getTableProps()} state={state} className="w-full light-blue-border text-left text-sm mb-[24px] tasks-table-border project-table client-table">
                        <thead className="light-blue-border uppercase h-[54px] text-ere-content-blue text-[14px]">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className="px-[24px] text-[14px] bg-ere-background-grey h-[65px] " {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className="flex-col inline-flex align-middle pr-[8px]">
                                            {column.render('Header')}
                                        </div>
                                        <div className="h-fit inline-block align-middle">
                                            {
                                                column.isSorted ?
                                                    (
                                                        !column.isSortedDesc ?
                                                            <div className="flex flex-col"><ArrowUp
                                                                className="sort-icon inline ml-1 mb-1"/><ArrowUpBlue
                                                                className="sort-icon rotate-180 inline ml-1 "/></div>
                                                            :
                                                            <div className="flex flex-col"><ArrowUpBlue
                                                                className="sort-icon inline ml-1 mb-1"/><ArrowUp
                                                                className="sort-icon rotate-180 inline ml-1 "/></div>
                                                    )
                                                    :
                                                    <div className="arrows-container flex flex-col"><ArrowUp
                                                        className="sort-icon inline ml-1 mb-1"/><ArrowUp
                                                        className="sort-icon rotate-180 inline ml-1 "/>
                                                    </div>
                                            }

                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="text-[14px]">
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="font-[400] text-[16px] text-ere-grey-alternative h-[53px] bg-white" {...row.getRowProps()} >
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header === "Name") {
                                            return (
                                                <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                    <span className="">{cell.render('Cell')}</span>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td className="border-y border-ere-border-grey py-[17px] px-[24px]" style={{textAlign: "left"}} {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </Form>
            </div>
        </div>
    );
}