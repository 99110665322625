import {useEffect, useState} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Appointment from "../components/Cards/Appointment";
import Report from "../components/Cards/Report";
import Breadcrumb from "./Breadcrumb";
import {fetchGlobalStats} from "../utils/api/globalStats";
import {inQuestionCatalog, loggedInUser, setGlobalQuestionProgress, setGlobalRisk} from "../utils/helpers";
import {useAuth} from "../hooks/useAuth";
import Tutorial from "../components/General/Tutorial";
import {ReactComponent as AttentionIcon} from "../../src/assets/icons/attention-icon-white.svg";

export default function Main() {
    const [collapsed, setCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const {accessToken, makeAuthenticatedRequest, fetchAccessToken} = useAuth();

    useEffect(()=> {
        fetchAccessToken().then(() => {
            fetchGlobalStats(accessToken, makeAuthenticatedRequest, setGlobalQuestionProgress, setGlobalRisk, setIsLoading);
        })
        if(window.ProjectManager20) {
            window.ProjectManager20.unmount();
        }
    },[location, loggedInUser])

    return (
        <>
            <div className="main-wrapper  flex justify-center pb-[80px] lg:pb-[100px] ">
                <div className="flex flex-col items-center w-full bg-ere-body-color">
                    { loggedInUser !== undefined && ((loggedInUser.protection_officer === 1) || (loggedInUser.support_member === 1)) ?
                        <div className="flex flex-col md:flex-row justify-between text-white w-full bg-ere-orange h-fit md:h-[48px] items-center py-[12px] px-[16px] font-bold mb-[32px]">
                            {loggedInUser !== undefined && loggedInUser.current_project.organisation_address !== null ?
                                <div><AttentionIcon className="inline pr-[8px] scale-[1.3] mb-[2px]"/>Sie sind jetzt im Mandant <span> {loggedInUser.current_project.organisation_address.company}!</span></div>
                            :
                                <div><AttentionIcon className="inline pr-[8px] scale-[1.3] mb-[2px]"/>Bitte füllen Sie unter <Link to={"/organisation"} className="underline underline-offset-2">Mein Unternehmen</Link> die Daten zu Ihrem Mandanten aus</div>
                            }
                            <Link to={"/clients"} className="underline underline-offset-2">Mandanten wechseln</Link>
                        </div>
                    :
                        ""
                    }
                    <div className="breadcrumb-wrapper self-start lg:w-[95%] max-w-[1360px] xl:ml-0 h-[32px]">
                        {(process.env.REACT_APP_SHOW_BREADCRUMB === "true") ?
                            <Breadcrumb/>
                        :
                            ""
                        }
                    </div>
                    <div className="flex h-auto content-wrapper w-full justify-center max-w-[1360px] ">
                        <Sidebar collapsedSidebar={collapsed} setCollapsedSidebar={setCollapsed}/>
                        <div className="relative w-full">
                            <div className={`bg-ere-body-color lg:bg-white outlet-wrapper  md:p-[10px] lg:p-[24px] lg:pb-[55px] lg:ml-6  min-h-[490px] lg:min-h-[724px] h-full lg:shadow-sm flex flex-col items-center md:justify-start  relative align-baseline  w-full xl:self-start z-20 lg:w-auto `}>
                                <Tutorial/>
                                <Outlet context={[collapsed]}/>
                            </div>
                        </div>
                        {
                            (collapsed && inQuestionCatalog) ?
                            <div className="hidden w-[300px] min-w-0 max-w-[300px] sidebar-right min-h-[724px] ml-[30px] mr-[30px] lg:flex flex-col space-y-[24px] animate-appear -z-10">
                                <Report/>
                                <Appointment/>
                            </div>
                        :
                            <div className="hidden lg:flex w-0 min-w-0"></div>
                        }
                    </div>
                </div>
                <div id="notifyContainer" className="notification-container"></div>
            </div>
            <Footer/>
        </>
    );
}
