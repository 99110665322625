import {apiUrl} from "../helpers";

export async function fetchGlobalStats(accessToken, makeAuthenticatedRequest, setGlobalQuestionProgress, setGlobalRisk, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        setIsLoading(true);
        const url = `${apiUrl}/globalstats`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`
        };
        // make authenticated request
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setGlobalQuestionProgress(data.question)
        setGlobalRisk(data.risk)
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}