
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info-icon.svg";
import { Tooltip } from 'react-tooltip';

export default function Popup({popupAnswer,toLeftMobile}) {

    return(
        <>
            {/* <InfoIcon className="absolute right-[1px] 3xs:right-[10px] md:right-[10px] bottom-[10px] scale-[1.2] peer peer-checked:white-info-icon" data-tooltip-id="my-tooltip-1"/>*/}
            <div className="block
                        h-[30px]
                        mr-0
                        top-[10px]
                        w-fit
                        absolute
                        right-[10px]

                        ">
                <div className={`relative
                before:content-[attr(data-tip)]
                before:absolute
                before:px-3 before:py-2 before:left-1 before:-top-2
                md:before:min-w-[220px] before:h-fit before:translate-y-8
                
                before:text-ere-grey-alternative
                before:bg-ere-body-color 
                before:opacity-0
                before:transition-all
                before:block
                before:z-50
                before:text-[13px]
                before:shadow-sm

                after:absolute
                after:left-3 after:top-3
                after:h-0 after:w-0
                after:-translate-x-3 after:border-8
                after:border-t-ere-body-color
                after:border-l-transparent
                after:border-b-transparent
                after:border-r-transparent
                after:opacity-0
                after:transition-all
                after:z-50
                after:rotate-180
               
               
                
                
                

                hover:after:opacity-100 hover:before:opacity-100 before:pointer-events-none after:pointer-events-none
                ${toLeftMobile ? " before:w-[200px] before:min-w-[200px] md:before:min-w-[240px] lg:before:min-w-[330px] before:-translate-x-[93%] lg:before:-translate-x-1/2   " : "before:min-w-[250px] before:-translate-x-1/2"}`}
                     data-tip={popupAnswer}>
                    <InfoIcon  className="block scale-[1.2] peer peer-checked:white-info-icon"/>

                </div>
            </div>



        </>
    );
}