import {useState} from "react";
import {toSlug} from "../../utils/helpers";
import {useNavigate} from "react-router-dom";
import {ReactComponent as AttentionIcon} from "../../assets/icons/attention-icon.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right.svg";
import {fetchCategoryQuestions} from "../../utils/api/categories";
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../../components/General/Preloader";

export default function OpenQuestionsBox({category, lastChanged}) {
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {makeAuthenticatedRequest, accessToken} = useAuth();
    const navigate = useNavigate();

    useState(() => {
        if(accessToken && category.category.answer_progress_by_category_only.questions !== 0) {
            fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setCategoryQuestions, setIsLoading, category.category.id);
        }
    },[makeAuthenticatedRequest, category.category.id]);

    function getIndexForFirstOpenQuestion() {
        if(category.skipped_question[0].id) {
            let firstOpenQuestion = category.skipped_question[0].id;
            return categoryQuestions.indexOf(categoryQuestions.find((question) => question.id === firstOpenQuestion));
        } else {
            let firstOpenQuestion = category.skipped_question[0].opa_id;
            return categoryQuestions.indexOf(categoryQuestions.find((question) => question.id === firstOpenQuestion));
        }
    }

    function getFirstOpenQuestions() {
        if(category.skipped_question[0].id) {
            return category.skipped_question[0].id;
        } else {
            return category.skipped_question[0].opa_id;
        }
    }

    return (
        <>
            {categoryQuestions.length !== 0 ?
                <button type="button" onClick={() => {navigate(`/categories/${category.category.id}/${toSlug(category.category.title)}/questions/${getFirstOpenQuestions()}/${toSlug(category.skipped_question[0].title)}/${getIndexForFirstOpenQuestion()}`)}} className="category text-start h-[140px] !flex flex-col justify-between transition-all duration-500 group">
                    <div className="flex flex-col items-start">
                        <h3 className="text-[18px] sm:text-[16px] lg:text-[18px]  text-ere-content-blue text-left">{category.category.title}</h3>
                        {category.category.answer_progress_by_category_only.last_changes_at ?
                            <span className="text-ere-grey-alternative text-[12px]">Zuletzt bearbeitet: <span className="inline-block"> {('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'}</span></span>
                        :
                            <span className="text-ere-grey-alternative text-[12px]">Noch nicht bearbeitet</span>
                        }
                    </div>
                    <div className="flex justify-between w-full items-center">
                        <span className=" transition-all ease-in-out duration-1000 item-right text-sm text-ere-grey-alternative">
                            <AttentionIcon className="inline mr-2 mb-1"/>
                            {category.skipped_question.length}
                            {category.skipped_question.length > 1 ?
                                ' übersprungene Fragen'
                            :
                                ' übersprungene Frage'
                            }
                        </span>
                        <ArrowRight className=" transition-all ease-in-out duration-1000 item-right"/>
                    </div>
                </button>
            :
                <button type="button" className="category text-start h-[140px] !flex flex-col justify-between transition-all duration-500 group">
                    <div className="flex flex-col items-start">
                        <h3 className="text-[18px] sm:text-[16px] lg:text-[18px]  text-ere-content-blue text-left">{category.category.title}</h3>
                        {category.category.answer_progress_by_category_only.last_changes_at ?
                            <span className="text-ere-grey-alternative text-[12px]">Zuletzt bearbeitet: <span className="inline-block"> {('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'}</span></span>
                        :
                            <span className="text-ere-grey-alternative text-[12px]">Noch nicht bearbeitet</span>
                        }
                    </div>
                    <div className="flex justify-between w-full items-center">
                        <span className=" transition-all ease-in-out duration-1000 item-right text-sm text-ere-grey-alternative">
                            <AttentionIcon className="inline mr-2 mb-1"/>
                            {category.skipped_question.length}
                            {category.skipped_question.length > 1 ?
                                ' übersprungene Fragen'
                            :
                                ' übersprungene Frage'
                            }
                        </span>
                        <Preloader/>
                    </div>
                </button>
            }

        </>
    );
}

