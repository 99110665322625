
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {setInQuestionCatalog} from "../../utils/helpers";
import Overview from "../../components/Content/SkippedQuestions/Overview";

export default function ShowSkippedQuestions() {
    const location = useLocation();

    useEffect(() => {
        setSelectedMenuPoint("Offene Fragen", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
    },[])

    return(
        <>
            <h1 className="text-2xl font-bold mb-[16px] text-ere-content-blue outlet-title self-start mt-[16px]">Übersprungene Fragen</h1>
            <p className="text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Hier finden Sie eine Übersicht der von Ihnen übersprungenen Fragen bei den Kategorien. Sie haben nun die Möglichkeit direkt zu den Fragen zu gelangen, ohne sich durch alle Fragen innerhalb der Kategorie klicken zu müssen.
            </p>
            <Overview/>
        </>
    )
}