import {apiUrl} from "../helpers";

export async function fetchClients(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/projects`;
        const customHeaders = {
            Accept: "application/json",
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function loginToClient(accessToken, makeGeneralRequest, setIsLoading, setState, projectId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/projects/${projectId}`;
        const customHeaders = {
            Accept: "application/json",
        };
        const data = await makeGeneralRequest(url, customHeaders, 'POST');
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchErtProjects(accessToken, makeGeneralRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/projects`;
        const customHeaders = {
            Accept: "application/json",
        };
        const data = await makeGeneralRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchErtProject(accessToken, makeAuthenticatedRequest, setIsLoading, setState, uuid) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/projects/${uuid}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postErtProject(accessToken, makeGeneralRequest, setIsLoading, uuid) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/projects/${uuid}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const response = await makeGeneralRequest(url, customHeaders, 'POST');
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchClient(accessToken, makeGeneralRequest, setState, setIsLoading, clientId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/projects/${clientId}`;
        const customHeaders = {
            Accept: "application/json",
        };
        const data = await makeGeneralRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}