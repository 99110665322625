import {useEffect, useState} from "react";
import {fetchContracts, getContractFile} from "../../../utils/api/contracts";
import {createSysFile, fetchSysFiles, getSysFile} from "../../../utils/api/sysFiles";
import {createCustomProcessAccess} from "../../../utils/api/customProcessAccess";
import {Link, useLocation} from "react-router-dom";
import {fetchTasks} from "../../../utils/api/tasks";
import {useAuth} from "../../../hooks/useAuth";
import Preloader from "../../General/Preloader";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../../utils/breadcrumb";
import {loggedInUser, setInQuestionCatalog} from "../../../utils/helpers";
import {ReactComponent as ArrowDownBlue} from "../../../assets/icons/chevron-top-blue.svg";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus-icon.svg";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download-icon.svg";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit-icon.svg";
import "../../../assets/css/documentstable.css";
import "../../../assets/css/contractstable.css";
import CustomProcessAccessPopup from "./CustomProcessAccessPopup";

export default function Overview() {
    const location = useLocation();
    const [tasks, setTasks] = useState([])
    const [sysFiles, setSysFiles] = useState([])
    const [contracts, setContracts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [createIsLoading, setCreateIsLoading] = useState(false);
    const [showProcessDirectories, setShowProcessDirectories] = useState(false);
    const [showToms, setShowToms] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeUrl, setIframeUrl] = useState("");
    const { makeAuthenticatedRequest, makeGeneralRequest, accessToken } = useAuth();

    useEffect(()=> {
        setSelectedMenuPoint("Meine Dokumente", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        if(accessToken) {
            if(tasks.length === 0) {
                fetchTasks(accessToken, makeAuthenticatedRequest, setTasks, setIsLoading);
            }
            if(sysFiles.length === 0) {
                fetchSysFiles(accessToken, makeAuthenticatedRequest, setSysFiles, setIsLoading);
            }
            if(contracts.length === 0) {
                fetchContracts(accessToken, makeAuthenticatedRequest, setContracts, setIsLoading);
            }
        }
    },[makeAuthenticatedRequest])

    if (isLoading) {
        return <Preloader/>;
    }

    function downloadContract(contract) {
        getContractFile(accessToken, makeAuthenticatedRequest, contract, setIsLoading);
    }

    function downloadSysFile(sysfile) {
        getSysFile(accessToken, makeAuthenticatedRequest, sysfile, setIsLoading);
    }

    const processDirectories = sysFiles?.filter(function(sysfile) {
        if(sysfile.type === "processdirectory") {
            return sysfile;
        }
    })

    const listProcessDirectories = processDirectories.map(processDirectory => {
        const updatedAt = new Date(processDirectory.uploaded_at)

        return(
            <tr key={processDirectory.id} className="bg-ere-table-grey border-t border-ere-border-grey">
                <td className="whitespace-nowrap px-2 h-[50px] pl-[24px]">
                    {processDirectory.based_on_customizing > 0 ?
                        "Individualisiert"
                    :
                        ""
                    }
                </td>
                <td className="whitespace-nowrap px-2 h-[50px]"/>
                <td className="whitespace-nowrap px-2 h-[50px]">{processDirectory.uploaded_at ? ('0' + updatedAt.getDate()).slice(-2) + '.' + (('0' + (updatedAt.getMonth() + 1)).slice(-2)) + '.' + updatedAt.getFullYear() + ', ' + ('0' + updatedAt.getHours()).slice(-2) + ':' + ('0' + updatedAt.getMinutes()).slice(-2) + ' Uhr' : "-"}</td>
                <td className="whitespace-nowrap  h-[50px] text-right  flex items-center justify-end pr-[24px]">
                    {createIsLoading ?
                        <>
                            <button type="button" className="bg-ere-body-color rounded-md "><Preloader/></button>
                        </>
                    :
                        <>
                            <button id="erecht24-download-process-directory" type="button" className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[126px] transition-all duration-500 ease-out overflow-x-hidden" onClick={() => {downloadSysFile(processDirectory)}}>
                                <DownloadIcon className="w-[15px] h-[15px] m-[5px]  float-left"/>
                                <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 with-action">Herunterladen</span>
                            </button>
                        </>
                    }
                </td>
            </tr>
        )
    })

    const toms = sysFiles?.filter(function (sysfile) {
        if (sysfile.type === "toms") {
            return sysfile;
        }
    })

    const listToms = toms.map(tom => {
        const updatedAt = new Date(tom.uploaded_at)

        return(
            <tr key={tom.id} className="bg-ere-table-grey border-t border-ere-border-grey">
                <td className="whitespace-nowrap px-2 h-[50px]">
                    {tom.based_on_customizing > 0 ?
                        "Individualisiert"
                    :
                        ""
                    }
                </td>
                <td className="whitespace-nowrap px-2 h-[50px]"/>
                <td className="whitespace-nowrap px-2 h-[50px]">{tom.uploaded_at ? ('0' + updatedAt.getDate()).slice(-2) + '.' + (('0' + (updatedAt.getMonth() + 1)).slice(-2)) + '.' + updatedAt.getFullYear() + ', ' + ('0' + updatedAt.getHours()).slice(-2) + ':' + ('0' + updatedAt.getMinutes()).slice(-2) + ' Uhr' : "-"}</td>
                <td className="whitespace-nowrap pl-4 pr-[24px] h-[50px] text-right  w-full flex items-center justify-end ">
                    {createIsLoading ?
                        <>
                            <button type="button" className="bg-ere-body-color rounded-md "><Preloader/></button>
                        </>
                        :
                        <>
                            <button id="erecht24-download-tom" type="button" className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[126px] transition-all duration-500 ease-out overflow-x-hidden" onClick={() => {downloadSysFile(tom)}}>
                                <DownloadIcon className="w-[15px] h-[15px] m-[5px] float-left"/>
                                <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 with-action">Herunterladen</span>
                            </button>
                        </>
                    }
                </td>
            </tr>
        )
    })

    const listUndoneContracts = contracts?.filter(function(contract) {
        if(contract.uploaded_at === null) {
            return contract;
        }
    }).map(contract => {
        const contractTask = tasks?.filter(function(task) {
            if(task.task.contracts !== null && task.task.subtype === "avv" && task.task.contracts.name === contract.name) {
                return task;
            }
        })

        return(
            <tr key={contract.id} className="bg-white border-t border-ere-border-grey">
                <td className="pl-[24px] pr-2 h-[50px]">{contract.name}</td>
                <td className="whitespace-nowrap px-2 h-[50px]">-</td>
                <td className="whitespace-nowrap pl-4 pr-[24px] h-[50px] text-right  flex items-center justify-end w-[200px] ml-auto mllll">
                    {contractTask.length !== 0 ?
                        <Link id={`erecht24-create-contract-${contract.id}`} type="button" to={`/tasks/${contractTask[0] ? contractTask[0].id : undefined}`} state={{current_task: contractTask[0]}} className="float-left bg-ere-body-color rounded-md inline w-[25px] h-[25px] hover:w-[120px] transition-all duration-500 ease-out overflow-x-hidden ">
                            <PlusIcon className="w-[17px] h-[17px] m-[4px]  float-left"/>
                            <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Neu erstellen</span>
                        </Link>
                    :
                        <></>
                    }
                </td>
            </tr>
        )
    })

    const listDoneContracts = contracts?.filter(function(contract) {
        if(contract.uploaded_at !== null) {
            return contract;
        }
    }).map(contract => {
        const updatedAt = new Date(contract.uploaded_at)
        const contractTask = tasks?.filter(function(task) {
            if(task.task.contracts !== null && task.task.subtype === "avv" && task.task.contracts.name === contract.name) {
                return task;
            }
        })

        return(
            <tr key={contract.id} className="bg-white border-t border-ere-border-grey">
                <td className="pl-[24px] pr-2 h-[50px]">{contract.name}</td>
                <td className="whitespace-nowrap px-2 h-[50px]">{contract.uploaded_at ? ('0' + updatedAt.getDate()).slice(-2) + '.' + (('0' + (updatedAt.getMonth() + 1)).slice(-2)) + '.' + updatedAt.getFullYear() + ', ' + ('0' + updatedAt.getHours()).slice(-2) + ':' + ('0' + updatedAt.getMinutes()).slice(-2) + ' Uhr' : "-"}</td>
                <td className="whitespace-nowrap pl-4 pr-[24px] h-[50px] text-right flex items-center justify-end w-[200px] ml-auto mll">
                    <button id={`erecht24-download-contract-${contract.id}`} type="button" onClick={() => {downloadContract(contract)}} className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[126px] transition-all duration-500 ease-out overflow-x-hidden mr-3 z-30">
                        <DownloadIcon className="w-[15px] h-[15px] m-[5px] float-left "/>
                        <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-1 with-action">Herunterladen</span>
                    </button>
                    <Link id={`erecht24-replace-contract-${contract.id}`} type="button" to={`/tasks/${contractTask[0] ? contractTask[0].id : undefined}`} state={{current_task: contractTask[0]}} className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[90px] transition-all duration-500 ease-out overflow-x-hidden ">
                        <EditIcon className="w-[17px] h-[17px] m-[4px]  float-left"/>
                        <span className="group-hover:bg-ere-body-color inline text-sm pr-2 pl-1 with-action">Ersetzen</span>
                    </Link>
                </td>
            </tr>
        )
    })

    return (
        <>
            <div className="m-0 flex flex-col risk-card  md:w-full  h-fit lg:bg-white md:flex-row xl:justify-between mb-[40px] w-full overflow-x-auto">
                <CustomProcessAccessPopup onClose={() => {
                    setShowIframe(false);
                    setIframeUrl('');
                }} visible={showIframe} url={iframeUrl}></CustomProcessAccessPopup>
                <div className="w-[100%] lg:flex-row">
                    <div className="flow-root items-center mb-[24px]">
                        <h1 className="float-left text-l text-ere-content-blue font-bold">Zieldokumente</h1>
                    </div>
                    <div className="text-sm ">
                        <table className="min-w-full text-left text-sm font-light  border-[1px] border-ere-link-blue border-opacity-40 documents-table">
                            <thead className="bg-ere-background-grey font-medium text-ere-checkbox-blue">
                                <tr>
                                    <th className="px-[24px] h-[50px] w-[25%]">TYP</th>
                                    <th className="px-2 h-[50px] opacity-0 whitespace-nowrap  w-[25%]">ZULETZT HOCHGEL</th>
                                    <th className="px-2 h-[50px]  w-[25%]">DATUM</th>
                                    <th className="pr-[24px] h-[50px] text-right  w-[25%]">AKTIONEN</th>
                                </tr>
                            </thead>
                            <tbody className="text-ere-grey-alternative">
                                <tr className="bg-white border-t border-ere-border-grey  special-row">
                                    <td className="whitespace-nowrap pl-[20px] pr-2 h-[50px] font-bold">
                                        {processDirectories.length !== 0 ?
                                            <button type="button" onClick={() => {setShowProcessDirectories(showProcessDirectories => !showProcessDirectories)}}>
                                                    <ArrowDownBlue className={`inline w-[25px] h-[25px] mb-1 transition-all duration-300 arrow-down mr-[6px] ${!showProcessDirectories ? "" : "rotate-180 gray-arrow"}`}/>
                                                    Verarbeitungsverzeichnis
                                            </button>
                                        :
                                            <span className="pl-[3px]">Verarbeitungsverzeichnis</span>
                                        }
                                        { loggedInUser.protection_officer === 1 ?
                                            <button id="erecht24-create-process-access-process-directory" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[140px] transition-all duration-500 ease-out overflow-x-hidden ${processDirectories.length !== 0 ? "mr-3" : ""}`} onClick={() => {createCustomProcessAccess(accessToken, makeGeneralRequest, setIsLoading, "processdirectory").then((response) => {
                                                setIframeUrl(response.target_url);
                                                setShowIframe(true);
                                            })}}>
                                                <EditIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action font-normal">Individualisieren</span>
                                            </button>
                                        :
                                            ""
                                        }
                                    </td>
                                    <td className="whitespace-nowrap pl-[20px] pr-2 h-[50px] font-bold ">

                                    </td>
                                    <td className="whitespace-nowrap pl-[10px] pr-2 h-[50px]">
                                        {processDirectories[processDirectories.length-1] != undefined && processDirectories[processDirectories.length-1].uploaded_at ?
                                            ('0' + new Date(processDirectories[processDirectories.length-1].uploaded_at).getDate()).slice(-2) + '.' + (('0' + (new Date(processDirectories[processDirectories.length-1].uploaded_at).getMonth() + 1)).slice(-2)) + '.' + new Date(processDirectories[processDirectories.length-1].uploaded_at).getFullYear() + '; ' + ('0' + new Date(processDirectories[processDirectories.length-1].uploaded_at).getHours()).slice(-2) + ':' + ('0' + new Date(processDirectories[processDirectories.length-1].uploaded_at).getMinutes()).slice(-2) + ' Uhr'
                                        :
                                            <>-</>
                                        }
                                    </td>
                                    <td className="whitespace-nowrap pr-[24px] h-[50px] text-right flex items-center justify-end w-[250px] ml-auto mll">
                                        {createIsLoading ?
                                            <>
                                                <button type="button" className="bg-ere-body-color rounded-md "><Preloader/></button>
                                            </>
                                        :
                                            <>
                                                <button id="erecht24-create-process-directory" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[120px] transition-all duration-500 ease-out overflow-x-hidden ${processDirectories.length !== 0 ? "mr-3" : ""}`} onClick={() => {createSysFile(accessToken, makeGeneralRequest, setCreateIsLoading, "processdirectory").then(() => {fetchSysFiles(accessToken, makeAuthenticatedRequest, setSysFiles, setIsLoading);})}}>
                                                    <PlusIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                    <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Neu erstellen</span>
                                                </button>
                                                { loggedInUser.customized_sysfiles.processdirectory ?
                                                    <button id="erecht24-create-process-directory-customize" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[160px] transition-all duration-500 ease-out overflow-x-hidden ${processDirectories.length !== 0 ? "mr-3" : ""}`} onClick={() => {createSysFile(accessToken, makeGeneralRequest, setCreateIsLoading, "processdirectory", true).then(() => {fetchSysFiles(accessToken, makeAuthenticatedRequest, setSysFiles, setIsLoading);})}}>
                                                        <PlusIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                        <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Neu erstellen (indiv.)</span>
                                                    </button>
                                                    :
                                                    <></>
                                                }
                                                { processDirectories.length !== 0 ?
                                                    <button id="erecht24-download-process-directory" type="button" className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[120px] transition-all duration-500 ease-out overflow-x-hidden" onClick={() => {downloadSysFile(processDirectories[processDirectories.length-1])}}>
                                                        <DownloadIcon className="w-[15px] h-[15px] m-[5px]  float-left"/>
                                                        <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-3 pt-[2px] with-action ">Herunterladen</span>
                                                    </button>
                                                :
                                                    ""
                                                }

                                            </>
                                        }
                                    </td>
                                </tr>
                                {showProcessDirectories ?
                                    listProcessDirectories
                                :
                                    <></>
                                }
                                <tr className="bg-white border-t border-ere-border-grey  special-row">
                                    <td className="whitespace-nowrap pl-[20px] h-[50px] font-bold">
                                        { toms.length !== 0 ?
                                            <button type="button" onClick={() => {setShowToms(showToms => !showToms)}}>
                                                <ArrowDownBlue className={`inline w-[25px] h-[25px] mb-1 transition-all duration-300 arrow-down mr-[6px] ${!showToms ? "" : "rotate-180 gray-arrow"}`}/>
                                                Datensicherheit (TOMs)
                                            </button>
                                        :
                                            <span className="pl-[3px]">Datensicherheit (TOMs)</span>
                                        }
                                        { loggedInUser.protection_officer === 1 ?
                                            <button id="erecht24-create-process-access-tom" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[140px] transition-all duration-500 ease-out overflow-x-hidden ${processDirectories.length !== 0 ? "mr-3" : ""}`} onClick={() => {createCustomProcessAccess(accessToken, makeGeneralRequest, setIsLoading, "toms").then((response) => {
                                                setIframeUrl(response.target_url);
                                                setShowIframe(true);
                                            })}}>
                                                <EditIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action font-normal">Individualisieren</span>
                                            </button>
                                            :
                                            ""
                                        }
                                    </td>
                                    <td className="whitespace-nowrap pl-[20px] h-[50px] font-bold"></td>
                                    <td className="whitespace-nowrap px-2 h-[50px]">
                                        {toms[toms.length - 1] !== undefined && toms[toms.length - 1].uploaded_at ?
                                            ('0' + new Date(toms[toms.length - 1].uploaded_at).getDate()).slice(-2) + '.' + (('0' + (new Date(toms[toms.length-1].uploaded_at).getMonth() + 1)).slice(-2)) + '.' + new Date(toms[toms.length-1].uploaded_at).getFullYear() + '; ' + ('0' + new Date(toms[toms.length-1].uploaded_at).getHours()).slice(-2) + ':' + ('0' + new Date(toms[toms.length-1].uploaded_at).getMinutes()).slice(-2) + ' Uhr'
                                        :
                                            <>-</>
                                        }
                                    </td>
                                    <td className="whitespace-nowrap pr-[24px] h-[50px] text-right flex items-center justify-end  w-[250px]  ml-auto ihh">
                                        {createIsLoading ?
                                            <button type="button" className="bg-ere-body-color rounded-md"><Preloader/></button>
                                        :
                                            <>
                                                <button id="erecht24-create-tom" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[120px] transition-all duration-500 ease-out overflow-x-hidden ${toms.length !== 0 ? "mr-3" : ""}`} onClick={() => {createSysFile(accessToken, makeGeneralRequest, setCreateIsLoading, "toms").then(() => {fetchSysFiles(accessToken, makeAuthenticatedRequest, setSysFiles, setIsLoading);})}}>
                                                    <PlusIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                    <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Neu erstellen</span>
                                                </button>
                                                { loggedInUser.customized_sysfiles.toms ?
                                                    <button id="erecht24-create-tom-customize" type="button" className={`bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[160px] transition-all duration-500 ease-out overflow-x-hidden ${toms.length !== 0 ? "mr-3" : ""}`} onClick={() => {createSysFile(accessToken, makeGeneralRequest, setCreateIsLoading, "toms", true).then(() => {fetchSysFiles(accessToken, makeAuthenticatedRequest, setSysFiles, setIsLoading);})}}>
                                                        <PlusIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
                                                        <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Neu erstellen (indiv.)</span>
                                                    </button>
                                                    :
                                                    <></>
                                                }
                                                { toms.length !== 0 ?
                                                    <button id="erecht24-download-tom" type="button" className="bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[120px] transition-all duration-500 ease-out overflow-x-hidden " onClick={() => {downloadSysFile(toms[toms.length-1])}}>
                                                        <DownloadIcon className="w-[15px] h-[15px] m-[5px] float-left"/>
                                                        <span className="group-hover:bg-ere-body-color inline text-sm pl-1 pr-2 pt-[2px] with-action">Herunterladen</span>
                                                    </button>
                                                :
                                                    ""
                                                }
                                            </>
                                        }
                                    </td>
                                </tr>
                                {showToms ?
                                    listToms
                                :
                                    <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="m-0 flex flex-col risk-card  md:w-full  h-fit lg:bg-white md:flex-row xl:justify-between w-full overflow-x-auto mb-[24px]">
                <div className="w-[100%] lg:flex-row">
                    <div className="flow-root items-center">
                        <h1 className="float-left text-l text-ere-content-blue font-bold mb-[24px]">Verträge</h1>
                    </div>
                    {contracts.length !== 0 ?
                        <div className="text-sm">
                            <table className="min-w-full text-left text-sm font-light border-[1px] border-ere-link-blue border-opacity-40 !table-fixed contracts-table">
                                <thead className="bg-ere-background-grey font-medium text-ere-checkbox-blue">
                                    <tr className="border-b">
                                        <th width="200" className="pl-[24px] pr-2 h-[50px] uppercase w-[25%]" >Name</th>
                                        <th className="px-2 h-[50px]  whitespace-nowrap  w-[25%]">ZULETZT HOCHGELADEN</th>
                                        <th className="pr-[24px] h-[50px] text-right  w-[25%]">AKTIONEN</th>
                                    </tr>
                                </thead>
                                <tbody className="text-ere-grey-alternative">
                                    {listUndoneContracts}
                                    {listDoneContracts}
                                </tbody>
                            </table>
                        </div>
                    :
                        <p className="xl:text-[16px] mb-[48px] text-ere-grey-alternative md:self-start self-center leading-[normal]">Derzeit existieren keine Dokumente oder Verträge</p>
                    }
                </div>
            </div>
        </>
    );
}