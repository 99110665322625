
import {useNavigate} from "react-router-dom";
import {setInQuestionCatalog, toSlug} from "../../../utils/helpers";
import {setCurrentCatalog, setSelectedMenuPoint} from "../../../utils/breadcrumb";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit-icon.svg";

export default function ActivityButton({activity}) {
    const navigate = useNavigate();

    function whenClicked() {
        setInQuestionCatalog(true);
        setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
        setCurrentCatalog(activity.title);
        navigate(`/categories/${activity.id}/${toSlug(activity.title)}/questions/${activity.firstSimpleQuestion.id}/${toSlug(activity.firstSimpleQuestion.title)}/0`)
    }

    return (
        <button id={`erecht24-edit-own-processing-activity-${activity}`} type="button" onClick={e => whenClicked()} className="mr-[8px] bg-ere-body-color rounded-[5px] inline w-[25px] h-[25px] hover:w-[100px] transition-all duration-500 ease-out overflow-x-hidden">
            <EditIcon className="w-[17px] h-[17px] m-[4px] float-left"/>
            <span className="group-hover:bg-ere-body-color inline text-sm pr-2 pl-1 with-action pt-[4px]">Bearbeiten</span>
        </button>
    );
}