import {useEffect, useState, Fragment} from "react";
import {fetchTasks} from "../../../utils/api/tasks";
import {Link} from "react-router-dom";
import Preloader from "../../General/Preloader";
import {useAuth} from "../../../hooks/useAuth";
import '../../../assets/css/table.css';
import TasksTable from "./TasksTable";

export default function Overview({isTaskOverview}) {
    const [tasks, setTasks] = useState([]);
    const [showUndone, setShowUndone] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken} = useAuth();

    useEffect(()=> {
        if(accessToken) {
            if(tasks.length === 0) {
                fetchTasks(accessToken, makeAuthenticatedRequest, setTasks, setIsLoading);
            }
        }
    },[makeAuthenticatedRequest]);


    if (isLoading) {
        return <Preloader/>;
    }

    function getUndoneTasks() {
        setShowUndone(true);
    }

    function getDoneTasks() {
        setShowUndone(false);
    }

    const listUndoneTasks = tasks?.filter(function(task) {
        if(task.state === "open") {
            return task;
        }
    })

    const listDoneTasks = tasks?.filter(function(task) {
        if(task.state === "done") {
            return task;
        }
    })

    return(
        <div className={` ${!isTaskOverview ? "h-[280px] overflow-y-hidden  !min-h-[296px] m-0 p-3 flex flex-col  bg-white rounded light-blue-border md:p-[24px] md:flex-row xl:justify-between " : " h-fit md:w-full w-full" }`}>
            <div className="w-[100%] lg:flex-row">

                <div className="text-sm text-center mb-4">
                    <div className="flex -mb-px w-full sm:bottom-[586px]  md:top-[203px]  md:bottom-[unset]">
                        <button type="button" onClick={() => {getUndoneTasks()}} className={`md:w-[130px] inline-block py-2 md:px-4 w-[50%] ${ showUndone ? "text-ere-link-blue border-b-4 border-ere-link-blue" : "text-ere-dark-gray border-b-2 border-ere-dark-gray"} rounded-t-lg uppercase`}>Unerledigt</button>
                        <button type="button" onClick={() => {getDoneTasks()}} className={`md:w-[130px] inline-block py-2 md:px-4 w-[50%] ${ showUndone ? "text-ere-dark-gray border-b-2 border-ere-dark-gray" : "text-ere-link-blue border-b-4 border-ere-link-blue"} rounded-t-lg uppercase`}>Erledigt</button>
                    </div>
                </div>
                { showUndone ?
                    <ul className="list-disc text-ere-grey-alternative w-full text-sm mt-[60px] overflow-x-scroll md:overflow-x-visible">
                        { listUndoneTasks.length !== 0 ?
                            <TasksTable tasksToShow={listUndoneTasks}/>
                        :
                            <>Es gibt keine Aufgaben zu erledigen.</>
                        }
                    </ul>
                    :
                    <ul className="list-disc text-ere-grey-alternative w-full text-sm mt-[60px] overflow-x-scroll md:overflow-x-visible">
                        { listDoneTasks.length !== 0 ?
                            <TasksTable tasksToShow={listDoneTasks}/>
                        :
                            <>Es gibt keine erledigten Aufgaben.</>
                        }
                    </ul>
                }
                    {!isTaskOverview ?
                        <div className="flex flex-col md:flex-none md:flow-root md:items-center mb-[24px]">
                            <h1 className="float-left text-xl text-ere-content-blue font-bold">Meine dringendsten Aufgaben</h1>
                            <Link id="erecht24-dashboard-tasks" to={"/tasks"} className="float-right text-ere-link-blue text-sm uppercase underline mt-[10px] font-bold">Alle Aufgaben ansehen</Link>
                        </div>
                    :
                        <></>
                    }
            </div>
        </div>
    );
}