import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "../../assets/css/notification.css";
import {createPortal} from "react-dom";

let timeToDelete = 300;
let timeToClose = 10000;

export default function Notification({ color = Color.info, autoClose = false, onDelete, children }) {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if(isClosing) {
            const timeoutId = setTimeout(onDelete, timeToDelete);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isClosing, onDelete]);

    useEffect(() => {
        if(autoClose) {
            const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [autoClose]);

    return createPortal(
        <div className={`notification-container ${isClosing ? "shrink" : ""}`}>
            <div className={`notification ${color} ${!isClosing ? "slideIn" : "slideOut"}`}>
                {children}
                <button onClick={() => setIsClosing(true)} className="closeButton">x</button>
            </div>
        </div>,
        document.ert_shadow.querySelector("[id=notifyContainer]")
    );
}

export const Color = {
    info: "bg-ere-link-blue",
    success: "bg-ere-green-notification",
    warning: "bg-ere-orange",
    error: "bg-ere-orange",
};

Notification.propTypes = {
    notificationType: PropTypes.oneOf(Object.keys(Color)),
    autoClose: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    children: PropTypes.element,
};
