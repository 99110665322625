import {useState} from "react";
import {deleteOwnProcessingActivity, fetchOwnProcessingActivities} from "../../../utils/api/ownProcessingActivities";
import {useAuth} from "../../../hooks/useAuth";
import Modal from "./Modal";

export default function DeleteButton({activity}) {
    const [isLoading, setIsLoading] = useState([]);
    const [activities, setActivities] = useState([]);
    const {makeAuthenticatedRequest, makeGeneralRequest, accessToken} = useAuth();

    function whenClicked() {
        deleteOwnProcessingActivity(accessToken, makeGeneralRequest, setIsLoading, activity.id).then(() => {
            fetchOwnProcessingActivities(accessToken, makeAuthenticatedRequest, setActivities, setIsLoading).then(() => {
                window.location.reload();
            });
        });
    }

    return (
       <Modal whenClicked={whenClicked} currentActivity={activity.title} onConfirm={whenClicked}/>
    );
}