import {useEffect, useState} from "react";

export default function AdditionalTextfield({prefilled, additionalInformation}) {
    const [defaultValue, setDefaultValue] = useState('');

    useEffect(() => {
        changeDefaultValue()
    }, [additionalInformation, prefilled])

    function changeDefaultValue() {
        if(prefilled) {
            if (prefilled.length !== 0 && additionalInformation.id === undefined) {
                setDefaultValue(prefilled[0].text)
            } else if (prefilled.length === 0) {
                setDefaultValue('');
            } else if (additionalInformation.id != prefilled[0].answer){
                setDefaultValue('');
            } else {
                setDefaultValue(prefilled[0].text)
            }
        }
    }

    return(
        <div className="my-6 mb-6 ">
            <textarea
                name="textfield"
                rows="4"
                className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5"
                placeholder={additionalInformation.placeholder !== undefined && additionalInformation.placeholder !== null && additionalInformation.placeholder !== '' ? additionalInformation.placeholder : `Bitte füllen Sie dieses Feld aus`}
                defaultValue={defaultValue}
                onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                onInput={e => e.target.setCustomValidity('')}
            />
        </div>
    );
}