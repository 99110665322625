import "../../../assets/css/progressbar.css"
import { ReactComponent as FlagIcon } from "../../../assets/icons/flag-icon.svg";


export default function Bar({done}) {
    return (
        <div className="h-[6px] w-full absolute bottom-0 left-0 hidden lg:block group -z-10">
            { done === 100 ?
                <div style={{"width": `${done}%`}} className="h-[6px] relative progress-bar transition-width duration-1000">
                    <div style={{"left": `${done}%`}} className=""></div>
                    <FlagIcon className="top-[-15px] ml-[-47px] absolute transition-all  text-[34px] left-[100%] hidden group-hover:block" ></FlagIcon>
                    <span className="w-[44px]  text-[14px] text-white absolute left-[100%] ml-[-22px] top-[16px] hidden group-hover:inline" >{done}%</span>
                </div>
            :
                <>
                    {done > 100 ?
                        <div style={{"width": `100%`}} className="h-[6px] relative progress-bar transition-width duration-1000">
                            <div style={{"left": `100%`}} className=""></div>
                            <FlagIcon className="top-[-15px] ml-[-47px] absolute transition-all  text-[34px] left-[100%] hidden group-hover:block"></FlagIcon>
                            <span className="w-[44px]  text-[14px] text-white absolute left-[100%] ml-[-22px] top-[16px] hidden group-hover:inline">100%</span>
                        </div>
                    :
                        <div style={{"width": `${done}%`}}
                             className="h-[6px] progress-bar group relative  transition-width duration-1000">
                            <div style={{"left": `${done}%`}} className=""></div>
                            <FlagIcon
                                className="top-[-15px] ml-[-47px] absolute transition-all text-[34px] left-[100%] hidden group-hover:block"></FlagIcon>
                            <span
                                className="w-[44px] text-[14px] text-white absolute left-[100%] ml-[-22px] top-[16px] font-['Lato'] z-20 hidden group-hover:inline">{done}%</span>
                        </div>
                    }
                </>
            }
        </div>
    )
}



