import {useEffect, useState} from "react";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-icon.svg";
import { ReactComponent as NoIcon } from "../../../assets/icons/no-icon.svg";
import { ReactComponent as AdditionalIcon } from "../../../assets/icons/additional-icon.svg";

export default function ServiceProvider({questionAnswers, prefilled, setIsAnswerGiven, whenChecked, showCustomServiceProviders}) {
    const [providers, setProviders] = useState([]);

    useEffect(()=> {
        setProviders(questionAnswers[0].target[0].object);
        if(document.ert_shadow.querySelectorAll('[name="service_provider"]:checked').length === 0) {
            setIsAnswerGiven(false);
        }
    },[questionAnswers, prefilled, whenChecked])

    useEffect(() => {
        if (prefilled) {
            setIsAnswerGiven(true);
        }
        document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
            provider.checked = false;
        })
        document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
            if(prefilled && provider.value == prefilled.split(",").find((id) => id == provider.value)) {
                provider.checked = true;
                provider.classList.add("checked");
                if(provider.value === '99999') {
                    showCustomServiceProviders(true);
                }
                setIsAnswerGiven(true);
            }
        })
    }, [prefilled, document.ert_shadow.querySelectorAll('[name="service_provider"]').length]);

    function uncheckServiceProviders(event,providerId) {
        if(event.target.checked) {
            document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
                provider.checked = provider.value === providerId;
                if(provider.value !== providerId) {
                    provider.classList.remove("checked");
                }
            })
        }
    }

    function uncheckNone(event) {
        if(event.target.checked) {
            document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
                if(provider.value === '77777' || provider.value === '88888') {
                    provider.checked = false;
                    provider.classList.remove("checked");
                }
            })
        }
    }

    function labelChecked(event) {
        if(event.target.checked) {
            document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
                if(provider.value === event.target.value) {
                    provider.classList.add("checked");
                }
            })
        }
    }

    const listProviders = providers.filter(function(provider) {
        if(provider.id !== 77777 && provider.id !== 88888 && provider.id !== 99999 && provider.top === 1) {
            return provider;
        }
    }).map((provider) => (
        <label id={`erecht24-service-provider-${provider.id}`} key={provider.id} className={` w-fit h-auto relative cursor-pointer ${prefilled && provider.id == prefilled.split(",").find((id) => id == provider.id) ? "ring-ere-checkbox-blue" : "ring-ere-link-blue"}`}>
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider" onChange={(e) => {
                labelChecked(e);
                uncheckNone(e);
                whenChecked(provider, e);
            }}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px] z-10 peer-checked:animate-appearanswer">
                    <CheckIcon />
            </span>
            <div className="peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 min-w-[120px] xs:min-w-[160px] s:min-w-[170px] sm:w-[196px] h-[127px] items-center text-center bg-white p-[10px]
        relative cursor-pointer transition-all active:scale-95 flex justify-center overflow-visible light-blue-border-category py-[20px]">
            {provider.icon ?
                <img className="p-4 w-full object-contain" src={provider.icon} loading="lazy" alt={"provider-icon"}/>
            :
                <header className="flex flex-col justify-center items-center">
                    <p className="w-full">{provider.name}</p>
                </header>
            }
            </div>
        </label>
    ))

    const listNone = providers.filter(function(provider) {
        if(provider.id === 77777) {
            return provider;
        }
    }).map((provider) => (
        <label id={`erecht24-service-provider-${provider.id}`} key={provider.id} className="w-fit h-auto relative cursor-pointer">
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider" onChange={(e) => {
                labelChecked(e);
                uncheckServiceProviders(e, '77777');
                whenChecked(provider, e);
            }}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px] z-10">
                    <CheckIcon />
            </span>
            <div className="peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 min-w-[120px] xs:min-w-[160px] s:min-w-[170px] sm:w-[196px] h-[127px] items-center text-center bg-white p-[10px]
        relative cursor-pointer transition-all active:scale-95 flex justify-center overflow-visible light-blue-border-category">
            <header className="flex flex-col justify-center items-center ">
                <NoIcon />
                <p className="text-base mt-[5px]">Keine</p>
            </header>
            </div>
        </label>
    ))

    const listNo = providers.filter(function(provider) {
        if(provider.id === 88888) {
            return provider;
        }
    }).map((provider) => (
        <label id={`erecht24-service-provider-${provider.id}`} key={provider.id} className="w-fit h-auto relative cursor-pointer">
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider" onChange={(e) => {
                labelChecked(e);
                uncheckServiceProviders(e, '88888');
                whenChecked(provider, e);
            }}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px] z-10">
                    <CheckIcon />
            </span>
            <div className="peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 min-w-[120px] xs:min-w-[160px] s:min-w-[170px] sm:w-[196px] h-[127px] items-center text-center bg-white p-[10px]
        relative cursor-pointer transition-all active:scale-95 flex justify-center overflow-visible light-blue-border-category">
                <header className="flex flex-col justify-center items-center ">
                    <NoIcon />
                    <p className="text-base mt-[5px]">Nein</p>
                </header>
            </div>
        </label>
    ))

    const listOther = providers.filter(function(provider) {
        if(provider.id === 99999) {
            return provider;
        }
    }).map((provider) => (
        <label id={`erecht24-service-provider-${provider.id}`} key={provider.id} className="w-fit h-auto relative cursor-pointer">
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider" onChange={(e) => {
                labelChecked(e);
                uncheckNone(e);
                whenChecked(provider, e);
           }}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px] z-10">
                    <CheckIcon/>
            </span>
            <div className="peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 min-w-[120px] xs:min-w-[160px] s:min-w-[170px] sm:w-[196px] h-[127px] items-center text-center bg-white p-[10px]
        relative cursor-pointer transition-all active:scale-95 flex justify-center overflow-visible light-blue-border-category">
                <header className="flex flex-col justify-center items-center ">
                    <AdditionalIcon/>
                    <p className="text-base mt-[5px]">Sonstige</p>
                </header>
            </div>
        </label>
    ))

    return (
        <div
            className="md:flex grid grid-cols-2 md:flex-wrap justify-center gap-[20px] self-center  answers-wrapper md:w-full mb-[15px] h-auto">
            {listProviders}
            {listNone}
            {listNo}
            {listOther}
        </div>
    );
}