import { ReactComponent as ArrowRightCarousel } from "../../assets/icons/chevron-top.svg";
import Slider from "react-slick";
import {useEffect} from "react";
import {resetCurrentCatalog, resetCurrentQuestionCategory, resetSelectedMenuPoint} from "../../utils/breadcrumb";
import {setInQuestionCatalog} from "../../utils/helpers";
import { useOutletContext } from "react-router-dom";
import OpenQuestionsBox from "./OpenQuestionsBox";

export default function OpenQuestions(openQuestions) {
    const [collapsed] = useOutletContext();
    const settings = {
        infinite: false,
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,

                }
            },
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 590,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],

    }

    useEffect(() => {
        resetSelectedMenuPoint();
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
    }, [openQuestions,settings])


    const listQuestions = Array.from(Object.values(openQuestions.openQuestions))?.map((category) => {
        const lastChanged = new Date(category.category.answer_progress_by_category_only.last_changes_at);
        return (
            <div className="category text-start h-[140px] bg-white light-blue-border-category !flex flex-col justify-between py-[12px] px-[16px]  hover:shadow-lg  transition-all duration-500 hover:bg-ere-category-hover focus:shadow-lg focus:bg-ere-category-hover group">
                <OpenQuestionsBox category={category} lastChanged={lastChanged}/>
            </div>
        )
    }
)

    function SampleNextArrow(props) {
        const {className, style, onClick, slideCount, currentSlide} = props;
        return (
            <div className={className} style={{...style, display: "inline-block", before: ''}} onClick={onClick}>
             <ArrowRightCarousel className="problematic rotate-90 inline-block"/>
                {currentSlide} / {slideCount}
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;

        return (
            <div className={className} style={{...style, display: "inline-block"}} onClick={onClick}>
               <ArrowRightCarousel className=" -rotate-90 inline-block"/>
            </div>
        );
    }

    return (
        (openQuestions.length !== 0)  ?
            <div className={`border-2 rounded mb-[24px] p-[24px] border-ere-link-blue border-opacity-40 block  bg-white transition-width duration-300 ease-in w-full ${collapsed ? "max-w-[1168px]" : "max-w-[947px]"}`}>
                <div className="w-full flex justify-between items-center">
                    <h3 className="text-ere-content-blue text-[20px] font-bold">Meine übersprungenen Fragen</h3>
                </div>
                <div className="mt-[20px]">
                    <Slider {...settings}>
                        {listQuestions}
                    </Slider>
                </div>
            </div>
        :
            <></>
    );
}