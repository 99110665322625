import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import {ReactComponent as EscapeButton} from "../../../../assets/icons/escape.svg";
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question-mark.svg";

import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container':{
/*        backgroundColor:'#1f719f33',
        backgroundOpacity: '20%',
        backdropBlur: '3px',*/
        background: 'rgba(202, 235, 255, 0.40)',
        backdropFilter: 'blur(3px)',

        '@media (min-width: 320px)': {
            width:'100%',
        },

    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),



    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root':{
        minWidth:'120px',
        '@media (min-width: 1024px)': {
            minWidth:'800px',
        },
        border: 'solid 1px #0094D8',
        borderRadius:'0',
        padding:'20px',
        paddingTop:'40px',
        boxShadow:'none',
        maxWidth:'200px',
        '@media (min-width: 380px)': {
        maxWidth:'800px',
            padding:'40px',
        },

        margin:0,

        '@media (min-width: 340px)': {
            marginLeft:'unset',
            margin:'35px',

        },
    },
    '& .MuiPaper-root h2':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root h1':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root p':{
        fontSize:'16px',
        color:'#444444',
        marginBottom:'20px',
    }
}));

export default function ModalQuestion({questionTitle,popupAnswer}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/*<Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button>*/}
            <div className="">
                <h2 className="text-base md:text-lg w-full lg:grid lg:grid-cols-12 flex flex-col items-center" >
                    <span className="lg:col-span-11 lg:ml-[75px]" >{questionTitle}</span>

                    <button className="sticky s:relative z-20 lg:col-span-1 text-right flex  items-center">
                        <QuestionIcon onClick={handleClickOpen}   className="inline-block cursor-pointer z-3  mb-[2px] w-[25px]" /><span  onClick={handleClickOpen} className="hilfe-span text-ere-link-blue text-sm  pb-[2px] hover:cursor-pointer">Hilfe</span>
                    </button>
                </h2>
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="min-w-[400px]"
            >
                {/*<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Title
                </DialogTitle>*/}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >

                    <EscapeButton />
                </IconButton>
                <DialogContent >
                    <Typography gutterBottom>
                        <p className="rte-field block text-base text-left text-ere-grey-alternative" dangerouslySetInnerHTML={{__html: popupAnswer}}/>
                    </Typography>

                </DialogContent>
                {/*<DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions>*/}
            </BootstrapDialog>
        </React.Fragment>
    );
}


/*
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question-mark.svg";
import {useState} from "react";
import {ReactComponent as EscapeButton} from "../../../../assets/icons/escape.svg";

export default function ModalQuestion({questionTitle,popupAnswer}) {
    const [modal,setModal] = useState(false);
    const toggleModal = () => {

        setModal(!modal);
    }
    const showModal = (e) => {
        e.stopPropagation();
        setModal(!modal);
    }

    return(
        <div className="">
            <h2 className="text-base md:text-lg w-full lg:grid lg:grid-cols-12 flex flex-col items-center" >
                <span className="lg:col-span-11 lg:ml-[75px]" >{questionTitle}</span>

                <div className="sticky s:relative z-20 lg:col-span-1 text-right flex  items-center">
                    <QuestionIcon onClick={showModal}   className="inline-block cursor-pointer z-3  mb-[2px] w-[25px]" /><span  onClick={showModal} className="hilfe-span text-ere-link-blue text-sm  pb-[2px] hover:cursor-pointer">Hilfe</span>
                    {
                        modal && (
                            <div className="w-full h-full relative top-0 bottom-0 left-0 right-0   ">
                                <div className="w-full h-full fixed top-0 bottom-0 left-0 right-0 bg-ere-nav-mobile-blue bg-opacity-20 z-100 backdrop-blur-[3px]" onMouseDown={showModal}>
                                    <div className="w-[90%] p-[20px] md:p-[40px] absolute top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:min-w-[200px]  md:max-w-[800px] min-h-[100px] h-fit bg-white flex flex-col animate-menu duration-1000 rounded-sm border border-sky-600">
                                       {/!* <button className="text-ere-grey-alternative opacity-[40%] w-5 h-5 hover:scale-150 rounded-full text-2xl mt-[-5px] md:mt-[-20px] md:mr-[-10px] self-end" onMouseUp={toggleModal}>&times;</button>*!/}
                                        <EscapeButton className="ml-auto absolute right-[5px] top-[5px] md:right-[24px] md:top-[24px] hover:scale-150 hover:cursor-pointer" />
                                        <p className="block text-base text-left text-ere-grey-alternative" dangerouslySetInnerHTML={{__html: popupAnswer}}/>


                                    </div>
                                </div>
                            </div>
                        )
                    }
                   {/!* <div className="absolute popup text-start flex-col justify-between
                   right-[50%] w-[160px] s:w-[700px] s:max-h-[400px] top-[20px] max-w-[170px] s:max-w-[240px] md:max-w-[400px] sm:right-[-10px] lg:right-[-200px]  min-h-[120px] md:min-w-[200px] md:min-h-[100px] h-fit max-h-[300px] overflow-y-auto
                    bg-ere-body-color text-ere-grey-alternative shadow-m border break-words text-sm p-4 z-50
                    hidden peer-hover:flex hover:flex">
                        <p className="pt-[5px]" dangerouslySetInnerHTML={{__html: popupAnswer}}/>
                    </div>*!/}
                </div>
            </h2>
        </div>
    );
}
*/


