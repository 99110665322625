export const COLUMNS =[
    {
        Header:'Name',
        accessor: 'title',
        disableFilters: true,
    },
    {
        Header: 'Letzte Änderung',
        accessor: 'modified',
        disableFilters: true
    }
]