import {GlobalFilter} from "./Filter/GlobalFilter";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {COLUMNS} from "./Filter/columns";
import SelectColumnFilter from "./Filter/Filter1";

import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowUpBlue } from "../../../assets/icons/arrow-up-blue.svg";
export default function TasksTable({tasksToShow}){
    const tableInstance = useTable({
            columns: COLUMNS,
            data: tasksToShow,
            defaultColumn:{ Filter: SelectColumnFilter }
        }, useFilters,useGlobalFilter,useSortBy,usePagination)

    const {getTableProps, getTableBodyProps, headerGroups,rows,prepareRow,page,nextPage, previousPage, canNextPage, canPreviousPage, state, state:{pageIndex},pageCount, setGlobalFilter,currentPageIndex, totalPageCount, gotoPage,resetPagination} = tableInstance
    const {globalFilter} = state

    return(
        <>
        <div className="filter-wrapper flex items-center md:justify-end mb-[24px] md:mt-[-98px]">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>

        <table {...getTableProps()} state={state}  className="w-full light-blue-border text-left text-sm tasks-table rounded-[2px] tasks-table-border">
            <thead className="light-blue-border uppercase h-[54px] text-ere-content-blue ">
            {headerGroups.map((headerGroup)=>(
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            headerGroup.headers.map((column)=>(
                                <th className="px-[24px] " {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <div className="flex-col inline-flex align-middle mr-[3px]">
                                        {column.render('Header')}
                                    </div>
                                    <div className="h-fit inline-block">
                                        {column.isSorted ?
                                            (
                                                !column.isSortedDesc
                                                    ? <div className="flex flex-col"><ArrowUp className="sort-icon inline ml-1 mb-1" /><ArrowUpBlue className="sort-icon rotate-180 inline ml-1 " /></div>
                                                    : <div className="flex flex-col"><ArrowUpBlue className="sort-icon inline ml-1 mb-1" /><ArrowUp className="sort-icon rotate-180 inline ml-1 " /> </div>
                                            )
                                            : <div className="arrows-container flex flex-col"><ArrowUp className="sort-icon inline ml-1 mb-1" /><ArrowUp className="sort-icon rotate-180 inline ml-1 " /></div>
                                        }
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                )
            )}
            </thead>
            <tbody {...getTableBodyProps()}>
            {
                page.map(row => {
                    prepareRow(row)
                    return(
                        <tr {...row.getRowProps()} >
                            {
                                row.cells.map((cell)=>{
                                    return <td className=" border-y border-ere-border-grey" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })
                            }
                        </tr>
                    )
                })
            }

            </tbody>
        </table>
        <div className="mt-[16px] w-full flex justify-center pagination-container">
            <button disabled={!canPreviousPage} className={`hover:bg-ere-blue hover:text-white w-[30px] h-[30px] rounded-[2px] transition-all duration-100 ease-out  ${!canPreviousPage ? "hidden" : ""}`} onClick={()=>previousPage()}><span className="to-left"></span></button>

            {
                Array.from(Array(pageCount), (_, index) =>

                    <button type="button" className={`page-item page-link inline  w-[30px] h-[30px] rounded-[2px] text-xs font-bold hover:bg-ere-blue hover:text-white transition-all duration-100 ease-out ${pageIndex === index ? 'bg-ere-blue text-white' : 'inline'}`}
                            onClick={() =>
                                gotoPage(index)
                            }
                    >
                        {index +1}
                    </button>
                )

            }

            <button disabled={!canNextPage} className={`hover:bg-ere-blue hover:text-white w-[30px] h-[30px] rounded-[2px] transition-all duration-100 ease-out ${!canNextPage ? "hidden" : ""}`} onClick={()=>nextPage()}><span>></span></button>

        </div>
    </>
    );
}