import MasterData from "../../components/Content/Task/MasterData";
import {Form, Link, useLocation, useNavigate} from "react-router-dom";
import {loggedInUser, setInQuestionCatalog} from "../../utils/helpers";
import {postMasterData} from "../../utils/api/tasks";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {fetchToken} from "../../utils/api/token";

export default function Data_protection_officer() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { makeGeneralRequest, accessToken } = useAuth();

    useEffect(()=> {
        setSelectedMenuPoint("Mein Unternehmen", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
    },[]);

    let addressInformation = {
        corporate_form: null,
        firstname: null,
        lastname: null,
        company: null,
        street: null,
        housenumber: null,
        zipcode: null,
        city: null,
        country: null,
        email: null,
        telephone: null,
        fax: null,
        mobile: null,
        website: null,
    };

    function setSessionInformation(event) {
        Object.assign(addressInformation, {
            address_type: "dataProtectionOfficer",
            corporate_form: event.target.corporate_form.value,
            firstname: event.target.firstname.value,
            lastname: event.target.lastname.value,
            company: event.target.company.value,
            street: event.target.street.value,
            housenumber: event.target.housenumber.value,
            zipcode: event.target.zipcode.value,
            city: event.target.city.value,
            country: event.target.country.value,
            email: event.target.email.value,
            telephone: event.target.telephone.value,
            fax: event.target.fax.value,
            mobile: event.target.mobile.value,
            website: event.target.website.value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSessionInformation(e)
        postMasterData(accessToken, makeGeneralRequest, setIsLoading, addressInformation, loggedInUser.current_project.id).then(() => {navigate("/");});
        fetchToken()
    }

    return(
        <>
            <h1 className="text-2xl font-bold mb-[16px] text-ere-content-blue outlet-title self-start mt-[16px]">Mein Unternehmen</h1>
            <p className="xl:text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Geben Sie hier die Stamm- und Kontaktdaten Ihres Unternehmens und Ihres Datenschutzbeauftragten (falls
                vorhanden) ein und verwalten Sie
                diese dauerhaft. Änderungen können Sie jederzeit vornehmen.
            </p>
            <div className="m-0 flex flex-col md:w-full h-fit lg:bg-white  md:p-0 md:flex-row xl:justify-between mb-[24px] w-full">
                <div className="w-[100%] lg:flex-row">
                    <div className="flex flex-col md:flex-none md:flow-root md:items-center">
                        <Form method="post" onSubmit={handleSubmit} className="flex flex-col">
                            <h2 className="float-left text-l text-ere-content-blue mb-[40px] font-bold">Stammdaten</h2>
                            <div className="text-sm text-center mb-8">
                                <div className="flex -mb-px w-full flex-col xs:flex-row text-start xs:text-center">
                                    <Link id="erecht24-organisation" to={"/organisation"} className="inline-block py-2 md:px-4 w-[50%] text-ere-dark-gray border-b-2 border-ere-dark-gray rounded-t-lg uppercase">Organisation</Link>
                                    <button id="erecht24-data-protection-officer" type="button" className={`text-start xs:text-center w-full inline-block py-2 md:px-4 md:w-[50%] text-ere-link-blue border-b-4 border-ere-link-blue rounded-t-lg uppercase`}>Datenschutzbeauftragter</button>
                                </div>
                            </div>
                            <MasterData type="dataProtectionOfficer"/>
                            <div className=" text-xs sm:text-sm flex items-center justify-center w-full">
                                <button id="erecht24-submit-data-protection-officer" type="submit" className="flex justify-center items-center btn btn-secondary hover:btn-secondary-active-and-hover md:w-[148px] max-w-[180px] w-[45%] tracking-[0.233px] h-[36px]">Speichern</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}