import {apiUrl} from "../helpers";

export async function fetchSysFiles(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/sysfiles`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchSysFile(accessToken, makeAuthenticatedRequest, setState, setIsLoading, fileId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/sysfiles/${fileId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function createSysFile(accessToken, makeGeneralRequest, setCreateIsLoading, type, customization = false ) {
    if (!accessToken) {
        setCreateIsLoading(false);
        return;
    }

    try {
        setCreateIsLoading(true);
        const url = `${apiUrl}/sysfiles/create`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const body = JSON.stringify({
            type: type,
            individual: customization
        })
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setCreateIsLoading(false);
    }
}

export async function getSysFile(accessToken, makeAuthenticatedRequest, sysfile, setIsLoading) {
    if (!accessToken || !sysfile) {
        setIsLoading(false);
        return;
    }

    try {
        const headers = {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${accessToken}`
        };

        const returnRawResponse = true;

        const response = await makeAuthenticatedRequest(sysfile.filelink, headers, returnRawResponse );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]),);
        const link = document.createElement('a');

        var filename = "";
        var disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        link.href = url;
        link.setAttribute('download', `${filename}`.toLowerCase());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}