import {useEffect, useState} from "react";
import {Form, Link, useLocation, useNavigate} from "react-router-dom";
import {fetchTask, postMasterData, postTaskComment, postTaskDone, postUpload} from "../../utils/api/tasks";
import Upload from "../../components/Content/Task/Upload";
import {ReactComponent as BackIcon} from "../../../src/assets/icons/back-icon.svg";
import MasterData from "../../components/Content/Task/MasterData";
import {useAuth} from "../../hooks/useAuth";
import {fetchContracts} from "../../utils/api/contracts";
import Preloader from "../../components/General/Preloader";
import {fetchAddresses} from "../../utils/api/adresses";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {loggedInUser, setInQuestionCatalog} from "../../utils/helpers";
import {ReactComponent as CheckIcon} from "../../../src/assets/icons/check.svg";

export default function ShowDetail() {
    const [submitable, setSubmitable] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [submitIsLoading, setSubmitIsLoading] = useState(false);
    const [commentSubmitIsLoading, setCommentSubmitIsLoading] = useState(false);
    const [task, setTask] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const formData = new FormData();
    const { makeAuthenticatedRequest, makeGeneralRequest, accessToken } = useAuth();

    let addressInformation = {
        corporate_form: null,
        firstname: null,
        lastname: null,
        company: null,
        street: null,
        housenumber: null,
        zipcode: null,
        city: null,
        country: null,
        telephone: null,
        fax: null,
        mobile: null,
        website: null,
    };

    let commentInformation = {
        user_id: null,
        user_firstname: null,
        user_lastname: null,
        user_is_data_protection_officer: null,
        taskpool_id: null,
        comment: null,
    };

    useEffect(()=> {
        setSelectedMenuPoint("Meine Aufgaben", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        fetchTask(accessToken, makeAuthenticatedRequest, setTask, setIsLoading, location.pathname.substring(location.pathname.lastIndexOf('/') + 1)).then(() => {
            fetchContracts(accessToken, makeAuthenticatedRequest, setContracts, setIsLoading).then(() => {
                if(task.length !== 0) {
                    fetchAddresses(accessToken, makeAuthenticatedRequest, setAddresses, setIsLoading, task.task.subtype);
                }
            });
        });
        setSubmitable(false);
    },[makeAuthenticatedRequest]);

    function setSessionInformation(event) {
        if(task.task.tasktype === 'jc_contract' || task.task.tasktype === 'free_contract' || task.task.tasktype === 'avv_contract' || task.task.subtype === "avv") {
            formData.append("file", event.target.upload.files[0]);
            formData.append("comment", event.target.comment.value);
        } else {
            Object.assign(addressInformation, {
                address_type: task.task.subtype,
                corporate_form: event.target.corporate_form.value,
                firstname: event.target.firstname.value,
                lastname: event.target.lastname.value,
                company: event.target.company.value,
                street: event.target.street.value,
                housenumber: event.target.housenumber.value,
                zipcode: event.target.zipcode.value,
                city: event.target.city.value,
                country: event.target.country.value,
                telephone: event.target.telephone.value,
                fax: event.target.fax.value,
                mobile: event.target.mobile.value,
                website: event.target.website.value,
            });
            if(task.task.subtype === "organisation") {
                Object.assign(addressInformation, {
                    managing_director: event.target.managing_director.value
                });
            }
        }
    }

    function setSessionCommentInformation(event) {
        Object.assign(commentInformation, {
            user_id: loggedInUser.id,
            user_firstname: loggedInUser.firstname,
            user_lastname: loggedInUser.lastname,
            user_is_data_protection_officer: loggedInUser.protection_officer,
            taskpool_id: task.id,
            comment: event.target.comment.value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(submitable) {
            setSessionInformation(e)
            if (task.task.tasktype === 'jc_contract' || task.task.tasktype === 'free_contract' || task.task.tasktype === 'avv_contract' || task.task.subtype === "avv") {
                postUpload(accessToken, makeGeneralRequest, setSubmitIsLoading, formData, task.task.contracts.id).then(() => {
                    navigate(-1);
                });
            } else {
                postMasterData(accessToken, makeGeneralRequest, setSubmitIsLoading, addressInformation, task.project_id).then(() => {
                    navigate(-1);
                });
            }
        } else {
            setSessionCommentInformation(e);
            postTaskComment(accessToken, makeGeneralRequest, setCommentSubmitIsLoading, commentInformation).then(() => {
                setCommentSubmitIsLoading(false);
                fetchTask(accessToken, makeAuthenticatedRequest, setTask, setIsLoading, location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
            });
            document.ert_shadow.querySelectorAll('[name="comment"]')[0].value = '';
        }
        setSubmitable(false);
    }

    if(isLoading) {
        return <Preloader/>
    }

    return(
        <>
            { task.length !== 0 ?
                <div className="flex flex-col text-center text-ere-content-blue justify-center content-center w-[90%] lg:w-full">
                    <h1 className="text-2xl mb-[40px] text-left font-bold ">Aufgaben</h1>
                    {task.state === "open" ?
                        <h1 className="text-l mb-[12px] text-left font-bold ">{task.task.title}</h1>
                    :
                        <div className="flex justify-between">
                            <h1 className="text-l mb-[12px] text-left font-bold ">{task.task.title}</h1>
                            <CheckIcon/>
                        </div>
                    }

                    { task.state === "open" ?
                        <>
                            { task.task.risk.risk_value <= 33 ?
                                <button className="w-fit h-[20px] px-[8px] rounded-[25px] text-xs bg-ere-green-notification text-white" disabled>
                                    Kleines Risiko
                                </button>
                            : (task.task.risk.risk_value > 66) ?
                                <button className="w-fit px-[8px] h-[20px] rounded-[25px] text-xs bg-ere-orange text-white" disabled>
                                    Hohes Risiko
                                </button>
                            :
                                <button className="w-fit  px-[8px] h-[20px] rounded-[25px] text-xs bg-ere-yellow text-white" disabled>
                                    Mittleres Risiko
                                </button>
                            }

                        </>
                    :
                        <>
                            <button className="w-fit h-[20px] px-[8px] rounded-[25px] text-xs bg-ere-link-blue text-white" disabled>
                                Erledigt
                            </button>
                        </>
                    }
                    <p className="rte-field mt-[27px] text-left mb-[40px] text-base" dangerouslySetInnerHTML={{__html: task.task.description}}/>
                    <Form method="post" onSubmit={handleSubmit} className="flex flex-col w-[100%] ">
                        {/*Die Bedingung "task.task.subtype === "avv"" muss raus*/}
                        { task.task.tasktype === "jc_contract" || task.task.tasktype === "free_contract" || task.task.tasktype === "avv_contract" || task.task.subtype === "avv" ?
                            <Upload prefilled={contracts.find((contract) => contract.id === task.task.contracts.id)} />
                        :
                            <></>
                        }
                        { task.task.tasktype === "form_base" ?
                            <MasterData task={task} type="organisation"/>
                        :
                            <></>
                        }
                        { task.task.tasktype === "form_dpo" ?
                            <MasterData task={task} type="dataProtectionOfficer"/>
                        :
                            <></>
                        }
                        <div className="flex flex-col text-start flex-nowrap lg:pb-[10%] pb-[40%] mt-[32px]">
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <div className="w-full flex flex-col justify-start items-start">
                                        <div className="text-sky-950 text-base font-bold  mb-[16px]">Kommentare</div>
                                        {task.task_pool_comment.length !== 0 ?
                                            <>
                                                {task.task_pool_comment.map((comment) => {
                                                    let comment_date = new Date(comment.created_at);

                                                    return (
                                                        <div
                                                            className="w-full h-fit min-h-[40px] mb-[20px] relative ml-[1px] border-l-2 border-l-sky-600 border-opacity-40 text-left pt-0 mb-2">
                                                            <span
                                                                className="w-full text-neutral-700 text-sm font-normal block pl-[16px]">
                                                                <div
                                                                    className="w-full sm:flex-row justify-between flex flex-col text-ere-grey-alternative relative has-divider pb-[16px] sm:pb-[unset]">
                                                                    <p className="text-sky-950 font-bold float-left flex items-center">{comment.user_firstname} {comment.user_lastname}</p>
                                                                    <span
                                                                        className="text-ere-grey lg:self-end md:self-center items-center mt-[10px] md:mt-0 min-w-[112px] text-xs block md:text-end ml-[32px] md:ml-[unset]">
                                                                        {('0' + comment_date.getDate()).slice(-2) + '.' + (('0' + (comment_date.getMonth() + 1)).slice(-2)) + '.' + comment_date.getFullYear() + ', ' + ('0' + comment_date.getHours()).slice(-2) + ':' + ('0' + comment_date.getMinutes()).slice(-2) + ' Uhr'}
                                                                    </span>
                                                                </div>
                                                                <p>{comment.comment}</p>
                                                            </span>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            :
                                            ""
                                        }
                                    </div>
                                    <div className="relative">
                                        <input type="text" name="comment" placeholder="Kommentar"
                                               className="w-full min-h-[83px] h-fit  rounded-sm border-2 border-sky-600 border-opacity-40 text-base p-[16px] pt-0 focus:outline-none"/>
                                        <button id={`erecht24-submit-comment-${task.id}`} type="submit"
                                                className="flex items-center justify-center text-ere-link-blue mt-2 absolute bottom-[12px] right-[16px] text-sm font-[700]">
                                            Absenden <BackIcon className="rotate-180 mt-[2px]"/>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            className="text-sm flex justify-between gap-3 items-center absolute bottom-0 lg:bottom-[30px] left-[24px] lg:left-[23px] lg:w-[calc(100%-46px)] w-[calc(100%-48px)] md:w-[calc(100%-120px)] md:left-[60px] ">
                            <Link to={-1} className="md:w-fit w-[50%] h-10">
                                <button type="button" className="flex items-center justify-center w-full h-10  btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] max-w-[180px]">
                                    <BackIcon/>Zurück
                                </button>
                            </Link>
                            { task.state === "closed" && task.task.subtype === null ?
                                <></>
                            :
                                <>
                                    {task.task.tasktype !== 'normal' ?
                                        <>
                                            { submitIsLoading ?
                                                <button type="button" className="flex items-center justify-center h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] max-w-[180px] w-[45%]">
                                                    <Preloader/>
                                                </button>
                                            :
                                                <button id={`erecht24-submit-task-${task.id}`} type="submit" onClick={()=> setSubmitable(true)} className="flex items-center justify-center h-10 btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] max-w-[180px] w-[45%]">
                                                    Absenden
                                                </button>
                                            }

                                        </>
                                    :
                                        <>
                                            {task.state === "open" ?
                                                <>
                                                    { submitIsLoading ?
                                                        <button type="button" className="flex items-center justify-center h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] max-w-[180px] w-[45%]">
                                                            <Preloader/>
                                                        </button>
                                                    :
                                                        <button id={`erecht24-submit-task-${task.id}`} type="button" onClick={() => {
                                                            postTaskDone(accessToken, makeGeneralRequest, setSubmitIsLoading, task.id)
                                                                .then(() => {navigate(-1)});
                                                        }} className="flex items-center justify-center h-10 float-left btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] max-w-[180px] w-[45%] cursor-auto">
                                                            Erledigt
                                                            <BackIcon className="rotate-180"/>
                                                        </button>
                                                    }
                                                </>
                                            :
                                                <></>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Form>
                </div>
            :
                <></>
            }
        </>
    );
}