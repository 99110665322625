import Indicator from "../../Progress/Indicator";
import ActivityButton from "../ActivityButton";
import DeleteButton from "../DeleteButton";

export const COLUMNS =[
    {
        Header:'Tätigkeit',
        accessor: 'title',
        disableFilters: true,
        sort: "desc"
    },
    {
        Header:'Kategorie',
        accessor: 'category.name',
        disableFilters: true,
    },
    {
        id:'lastChanged',
        Header: 'Letzte Änderung',
        sortType: 'datetime',
        accessor: (data)=> (data.answer_progress_by_category_only.last_changes_at && new Date(data.answer_progress_by_category_only.last_changes_at))  || (new Date(data.created_at)) || '-',
        disableFilters: true,
        Cell: (props) => (props.value === '-') || <span>{('0' + props.value.getDate()).slice(-2) + '.' + (('0' + (props.value.getMonth() + 1)).slice(-2)) + '.' + props.value.getFullYear() + ', ' + ('0' + props.value.getHours()).slice(-2) + ':' + ('0' + props.value.getMinutes()).slice(-2) + ' Uhr'}</span>
    },
    {
        Header: 'Status',
        accessor: 'answer_progress_by_category_only.progress_value',
        disableFilters: true,
        Cell:(e) => <Indicator done={e.value} small={true}/>
    },
    {
        Header: '',
        disableSortBy: true,
        disableFilters: true,
        accessor: 'id',
        Cell:props =>
            <>
                <ActivityButton activity={props.row.original}/>
                <DeleteButton activity={props.row.original}/>
            </>
    },

]
