import {useEffect, useRef, useState} from "react";
import {Form, unstable_useBlocker, useLocation, useNavigate, useParams} from "react-router-dom";
import {toSlug, getCheckedCollection, getPrefilledAdditionalData, getValueOfTextfield, setInQuestionCatalog} from "../../../utils/helpers";
import {fetchAnswer, postAnswer} from "../../../utils/api/answer";
import {fetchCategory, fetchCategoryQuestions} from "../../../utils/api/categories";
import {deleteCustomServiceProvider, fetchServiceProviders, postCustomServiceProvider, updateCustomServiceProvider} from "../../../utils/api/serviceProviders";
import ListElement from "../../../components/Content/Answer/ListElement";
import ServiceProvider from "../../../components/Content/Answer/ServiceProvider";
import YesNo from "../../../components/Content/Answer/YesNo";
import Textfield from "../../../components/Content/Answer/Textfield";
import ListCustom from "../../../components/Content/Answer/CustomServiceProvider/ListCustom";
import FormCustom from "../../../components/Content/Answer/CustomServiceProvider/FormCustom";
import AlertBox from "../../../components/Content/Answer/Warning/AlertBox";
import AdditionalTextfield from "../../../components/Content/Answer/Additional/AdditionalTextfield";
import {useAuth} from "../../../hooks/useAuth";
import { ReactComponent as BackIcon } from "../../../assets/icons/back-icon.svg";
import Preloader from "../../../components/General/Preloader";
import AdditionalTextfieldBox from "../../../components/Content/Answer/Additional/AdditionalTextfieldBox";
import Bar from "../../../components/Content/Progress/Bar";
import ModalQuestion from "../../../components/Content/Answer/Hints/ModalQuestion";
import {resetCurrentQuestionCategory, setCurrentCatalog, setSelectedMenuPoint} from "../../../utils/breadcrumb";
import {fetchCountries} from "../../../utils/api/countries";
import {fetchQuestion} from "../../../utils/api/questions";
import {round} from "@floating-ui/utils";
import {successNotification} from "../../../notify";
import ExitDialog from "../../../utils/exitDialog";

export default function QuestionCatalog() {
    const {makeAuthenticatedRequest, makeGeneralRequest, accessToken} = useAuth();
    const navigate= useNavigate();
    const location = useLocation();
    const params = useParams();
    const currentIndex = parseInt(useParams().index);
    const [isLoading, setIsLoading] = useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [changedCatalog, setChangedCatalog] = useState(false);
    const [isAnswerGiven, setIsAnswerGiven] = useState(false);
    const [isSkippable, setIsSkippable] = useState(false);
    const [mountSkipButton, setMountSkipButton] = useState(false);

    // Question
    const currentQuestionId = useParams().question_id;
    const [question, setQuestion] = useState([]);
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    const [foreignSubQuestion, setForeignSubQuestion] = useState([]);
    let addSubQuestions = false;

    // Category
    const currentCategoryId = useParams().category_id;
    const [parentCategory, setParentCategory] = useState([]);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [categoryForProgress, setCategoryForProgress] = useState([]);
    const [opaCategory, setOpaCategory] = useState();
    const [questionTree, setQuestionTree] = useState([]);

    // Answer
    const [prefilledAnswer, setPrefilledAnswer] = useState([]);
    const [additionalAnswer, setAdditionalAnswer] = useState([]);

    // Provider
    const [serviceProviders, setServiceProviders] = useState([]);
    const [formCustomServiceProvider, setFormCustomServiceProvider] = useState(<></>);
    const [customServiceProviderId, setCustomServiceProviderId] = useState();
    const [displayCreateCustom, setDisplayCreateCustom] = useState(true);
    const [displayCustomServiceProviders, setDisplayCustomServiceProviders] = useState(false);

    // Additional Information
    const [alertBox, setAlertBox] = useState(<></>);
    const [warning, setWarning] = useState([]);
    const [additionalTextfieldBox, setAdditionalTextfieldBox] = useState(<></>);
    const [additionalTextfield, setAdditionalTextfield] = useState([]);
    const [formAction, setFormAction] = useState("submit_answer");
    const [countries, setCountries] = useState([]);

    const [isVisibleDialog, setVisibleDialog] = useState(false);
    const [isBlocked, setIsBlocked] = useState(true);
    const questionCatalogRef = useRef(null);

    let sessionInformation = {question_type: question.answertype, answer: null, additional_text: null, additional_data: []};
    let customProviderInformation = {company: null, street: null, housenumber: null, zipcode: null, city: null, country: null, eu: null, provider_category_id: null, triggered_question_id: null}

    /*unstable_useBlocker((location) => {
        setIsBlocked(false);
        return (!location.nextLocation.pathname.includes("questions") && categoryQuestions[currentIndex+1] && isBlocked) ? 'Navigation ist gesperrt' : null;
    })*/

    useEffect(()=> {
        setIsWaiting(true);
        setInQuestionCatalog(true);
        if( accessToken ) {
            setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
            if(currentCategory.length === 0) {
                fetchCategory(accessToken, makeAuthenticatedRequest, setCurrentCategory, null, setIsLoading, currentCategoryId).then(() => {
                    if(currentCategory.length !== 0) {
                        if((!categoryQuestions[currentIndex+1] || !categoryQuestions[currentIndex-1]) && currentCategory[0].parent !== 0) {
                            if(parentCategory.length === 0) {
                                fetchCategory(accessToken, makeAuthenticatedRequest, setParentCategory, null, setIsLoading, currentCategory[0].parent);
                            }
                        }
                        if(currentCategory[0].parent === 0) {
                            resetCurrentQuestionCategory();
                        }
                        setCurrentCatalog(currentCategory[0].title);
                    }
                })
            }
            if(question.answertype === "serviceprovider" && serviceProviders.length === 0) {
                fetchServiceProviders(accessToken, makeAuthenticatedRequest, setServiceProviders ,setIsLoading)
                fetchCountries(accessToken, makeAuthenticatedRequest, setCountries, setIsLoading);
            }
            setTimeout(declareQuestionTree(), 100);
            fetchQuestion(accessToken, makeAuthenticatedRequest, setQuestion, setIsLoading, currentQuestionId)
        }
        setAlertBox(<AlertBox warnings={warning}/>);
        setAdditionalTextfieldBox(<AdditionalTextfieldBox textfields={additionalTextfield} prefilled={prefilledAnswer}/>);
    },[makeAuthenticatedRequest, setQuestion, setCategoryQuestions, setCategoryForProgress, setParentCategory, question.answertype, setServiceProviders, formCustomServiceProvider, customServiceProviderId, warning, setPrefilledAnswer]);

    useEffect(() => {
        setAdditionalAnswer([]);
    },[])

    useEffect(() => {
        setWarning([]);
        setAdditionalAnswer([]);
        setFormCustomServiceProvider(<></>);
        setDisplayCustomServiceProviders(false);
    }, [location]);

    useEffect(() => {
        fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setCategoryQuestions, setChangedCatalog, currentCategoryId, true)
        fetchCategory(accessToken, makeAuthenticatedRequest, setCurrentCategory, null, setIsLoading, currentCategoryId)
        setIsAnswerGiven(false);
    }, [params.category_id])

    /*useEffect(() => {
        const handleClickOutside = (event) => {
            if (questionCatalogRef.current && !questionCatalogRef.current.contains(event.target)) {
                setVisibleDialog(true);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [questionCatalogRef]);*/

    if (isLoading || changedCatalog) {
        return <Preloader/>;
    }

    function declareQuestionTree() {
        fetchCategory(accessToken, makeAuthenticatedRequest, setCategoryForProgress, setQuestionTree, setIsLoading, currentCategoryId).then(() => {
            if(prefilledAnswer.length === 0) {
                fetchAnswer(accessToken, makeAuthenticatedRequest, setIsWaiting, setPrefilledAnswer, currentQuestionId).then(() => {
                    fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setCategoryQuestions, setIsWaiting, currentCategoryId, false).then(() => {
                        setIsWaiting(false);
                        setMountSkipButton(true);
                    })
                });
            } else {
                setIsWaiting(false);
                setMountSkipButton(true);
            }
        });
    }

    function displayAnswer(answerType) {
        let answer;
        if(answerType === "yesno") {
            answer = <YesNo questionAnswers={question.answers} questionAnswerLayouts={question.answerlayout} whenSelected={handleCheck} prefilled={prefilledAnswer.answer} setWarning={setPrefilledWarning} setIsAnswerGiven={handlePrefilledGivenAnswer}/>
        } else if(answerType === "listelement") {
            answer = <ListElement questionAnswers={question.answers} questionAnswerLayouts={question.answerlayout} whenChecked={handleCheck} prefilled={prefilledAnswer.answer} singleOrMultiple={question.subtype} setTextbox={setPrefilledTextfieldbox} setWarning={setPrefilledWarning} setIsAnswerGiven={handlePrefilledGivenAnswer}/>
        } else if (answerType === "serviceprovider") {
            answer = <ServiceProvider questionAnswers={question.answers} prefilled={prefilledAnswer.answer} whenChecked={handleCheck} setIsAnswerGiven={handlePrefilledGivenAnswer} showCustomServiceProviders={setDisplayCustomServiceProviders}/>
        } else if(answerType === "textfield") {
            answer = <Textfield questionAnswers={question.answers} prefilled={prefilledAnswer.additional_text} texttype={question.answers[0].subtype} placeholderText={question.answers[0].placeholder} setIsAnswerGiven={handlePrefilledGivenAnswer}/>
        } else {
            answer = <div className="m-auto"><Preloader/></div>
        }
        return answer;
    }

    function handleAdditionalAnswer(additionalAnswer) {
        let additional;
        if(additionalAnswer.length === 0 || additionalAnswer.length === undefined) {
            if(additionalAnswer.subtype) {
                const subtypeArr = additionalAnswer.subtype.split(',');
                subtypeArr.forEach((subtype) => {
                    if(subtype === "textarea") {
                        additional = <AdditionalTextfield prefilled={getPrefilledAdditionalData(prefilledAnswer)} additionalInformation={additionalAnswer}/>
                    } else if(subtype === "none" || subtype === "taskpool") {
                        additional = <></>
                    } else if(subtype === "questionpool") {
                        addSubQuestions = true;
                        if(foreignSubQuestion.length === 0 && additionalAnswer[0]) {
                            additionalAnswer.target.forEach((target) => {
                                if(target.type === 'question') {
                                    target.object.forEach((question) => {
                                        fetchQuestion(accessToken, makeAuthenticatedRequest, setForeignSubQuestion, setIsLoading, question.id)
                                    })
                                }
                            })
                        } else if(foreignSubQuestion.length === 0) {
                            additionalAnswer.target.forEach((target) => {
                                if(target.type === 'question') {
                                    target.object.forEach((question) => {
                                        fetchQuestion(accessToken, makeAuthenticatedRequest, setForeignSubQuestion, setIsLoading, question.id)
                                    })
                                }
                            })
                        }
                        additional = <></>
                    }
                })
            } else if(getPrefilledAdditionalData(prefilledAnswer) && getPrefilledAdditionalData(prefilledAnswer).length !== 0 && getPrefilledAdditionalData(prefilledAnswer)[0].text !== "" && question.subtype !== "multiselect") {
                additional = <AdditionalTextfield prefilled={getPrefilledAdditionalData(prefilledAnswer)} additionalInformation={additionalAnswer}/>
            }
        } else {
            additionalAnswer.forEach((addAnswer) => {
                const subtypeArr = addAnswer.subtype.split(',');
                subtypeArr.forEach((subtype) => {
                    if(subtype === "questionpool") {
                        addSubQuestions = true;
                        if(foreignSubQuestion.length === 0 && question.answertype === "listelement") {
                            if(addAnswer.target.length !== 0) {
                                fetchQuestion(accessToken, makeAuthenticatedRequest, setForeignSubQuestion, setIsLoading, addAnswer.target[0].object[0].id)
                            }
                        }
                        if(foreignSubQuestion.length === 0 && question.answertype === "yesno") {
                            fetchQuestion(accessToken, makeAuthenticatedRequest, setForeignSubQuestion, setIsLoading, additionalAnswer.target[0].object[0].id)
                        }
                    }
                })
            })
        }
        return additional;
    }

    function displayCustomServiceProvider() {
        if(question.answertype === "serviceprovider" && displayCustomServiceProviders) {
            return <ListCustom
                questionCustomServiceProviders={serviceProviders}
                serviceProviderCategoryId={question.answers[0].subtype.split("_")[1]}
                whenSubmitClicked={handleDelete}
                whenClicked={handleCustomServiceProvider}
                displayCreateCustom={displayCreateCustom}
            />
        } else {
            return <></>
        }
    }

    function handleCustomServiceProvider(input, prefilledProvider) {
        if(input.target.checked) {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm} countries={countries}/>);
            setDisplayCreateCustom(false);
        } else if(input.type === "click" && prefilledProvider) {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm} prefilled={prefilledProvider} countries={countries} hasEdit={true}/>);
            setDisplayCreateCustom(false);
        } else if(input.type === "click") {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm} countries={countries}/>);
            setDisplayCreateCustom(false);
        } else {
            setFormCustomServiceProvider(<></>);
            setDisplayCreateCustom(true);
        }
    }

    function handleCheck(answer, checkbox) {
        if(question.subtype === "multiselect") {
            if(checkbox.target.checked) {
                const answerSubtypeArr = answer.subtype.split(',');
                answerSubtypeArr.forEach((subtype) => {
                    if(subtype === "textarea") {
                        additionalTextfield.push(answer);
                        setAdditionalTextfieldBox(<AdditionalTextfieldBox textfields={additionalTextfield} prefilled={prefilledAnswer}/>);
                    }
                    setIsAnswerGiven(true);
                })
                if(additionalAnswer.length === 0) {
                    setAdditionalAnswer([answer]);
                } else {
                    setAdditionalAnswer([...additionalAnswer, answer]);
                }
                if(answer.warningtxt !== null && answer.warningtxt !== '' && question.answertype !== 'serviceprovider' && !warning.includes(answer)) {
                    warning.push(answer);
                    setAlertBox(<AlertBox warnings={warning}/>);
                }
            } else {
                checkbox.target.classList.remove("checked");
                setWarning(warning.filter((filteredAnswer) => filteredAnswer.id !== answer.id))
                setAdditionalTextfield(additionalTextfield.filter((filteredAnswer) => filteredAnswer.id !== answer.id))
            }
        } else {
            if(checkbox.target.checked) {
                if(answer.id !== 77777 && answer.id !== 88888 && question.answertype === "serviceprovider") {
                    question.answers.forEach((answer) => {
                        if(answer.title === 'has_serviceprovider') {
                            setAdditionalAnswer(answer);
                        }
                    })
                } else if(answer.id === 77777 && question.answertype === "serviceprovider") {
                    question.answers.forEach((answer) => {
                        if(answer.title === 'no_serviceprovider') {
                            setAdditionalAnswer(answer);
                        }
                    })
                } else if(answer.id === 88888 && question.answertype === "serviceprovider") {
                    question.answers.forEach((answer) => {
                        if(answer.title === 'nothing_serviceprovider') {
                            setAdditionalAnswer(answer);
                        }
                    })
                } else {
                    setAdditionalAnswer(answer);
                }
                if(answer.warningtxt !== null && answer.warningtxt !== '' && question.answertype !== 'serviceprovider') {
                    warning.shift();
                    warning.push(answer);
                    setAlertBox(<AlertBox warnings={warning}/>);
                } else {
                    setAlertBox(<></>);
                }
                setIsAnswerGiven(true);
                if(answer.id === 99999 && question.answertype === "serviceprovider") {
                    setDisplayCreateCustom(false);
                    setDisplayCustomServiceProviders(true);
                    setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm} countries={countries}/>);
                }
                if(answer.id === 77777 || answer.id === 88888 && question.answertype === "serviceprovider") {
                    setDisplayCreateCustom(false);
                    setDisplayCustomServiceProviders(false);
                    setFormCustomServiceProvider(<></>);
                }
            } else {
                checkbox.target.classList.remove("checked");
                setAdditionalAnswer([]);
                setAlertBox(<></>);
                if(answer.id === 99999) {
                    setFormCustomServiceProvider(<></>);
                    setDisplayCustomServiceProviders(false);
                }
            }
        }
    }

    function handleFormAction(action, updatedServiceProviderId) {
        setFormAction(action)
        if(updatedServiceProviderId) {
            setCustomServiceProviderId(updatedServiceProviderId);
        }
    }

    function handlePrefilledGivenAnswer(answer) {
        setIsAnswerGiven(answer);
    }

    function checkForPrefilledSubquestions() {
        if(question.length !== 0 && question.answers !== undefined && question.answertype !== "textfield") {
            if(question.answertype === "serviceprovider") {
                let isServiceQuestionpool = false;
                if(prefilledAnswer.length !== 0 && prefilledAnswer.answer.includes(",")) {
                    prefilledAnswer.answer.split(",").forEach((answerId) => {
                        if(answerId === '77777') {
                            question.answers.forEach((answer) => {
                                if(answer.title === 'no_serviceprovider' && answer.subtype === 'questionpool') {
                                    isServiceQuestionpool = true;
                                }
                            })
                        } else if (answerId === '88888') {
                            question.answers.forEach((answer) => {
                                if(answer.title === 'nothing_serviceprovider' && answer.subtype === 'questionpool') {
                                    isServiceQuestionpool = true;
                                }
                            })
                        } else if (answerId !== '77777' && answerId !== '88888') {
                            question.answers.forEach((answer) => {
                                if(answer.title === 'has_serviceprovider' && answer.subtype === 'questionpool') {
                                    isServiceQuestionpool = true;
                                }
                            })
                        }
                    })
                    return isServiceQuestionpool;
                } else if(prefilledAnswer.length !== 0) {
                    if(prefilledAnswer.answer === '77777') {
                        question.answers.forEach((answer) => {
                            if(answer.title === 'no_serviceprovider' && answer.subtype === 'questionpool') {
                                isServiceQuestionpool = true;
                            }
                        })
                    } else if (prefilledAnswer.answer === '88888') {
                        question.answers.forEach((answer) => {
                            if(answer.title === 'nothing_serviceprovider' && answer.subtype === 'questionpool') {
                                isServiceQuestionpool = true;
                            }
                        })
                    }  else if (prefilledAnswer.answer !== '77777' && prefilledAnswer.answer !== '88888') {
                        question.answers.forEach((answer) => {
                            if(answer.title === 'has_serviceprovider' && answer.subtype === 'questionpool') {
                                isServiceQuestionpool = true;
                            }
                        })
                    }
                    return isServiceQuestionpool;
                }
            } else {
                if(prefilledAnswer.length !== 0 && prefilledAnswer.answer.includes(",")) {
                    let isQuestionpool = false;
                    prefilledAnswer.answer.split(",").forEach((answerId) => {
                        if(question.answers.find((answer) => answer.id == answerId) !== undefined) {
                            let answer = question.answers.find((answer) => answer.id == answerId);
                            const answerSubtypeArr = answer.subtype.split(',');
                            answerSubtypeArr.forEach((subtype) => {
                                if(subtype === "questionpool") {
                                    isQuestionpool = true
                                }
                            });
                        }
                    })
                    return isQuestionpool;
                } else if(prefilledAnswer.length !== 0) {
                    let isQuestionpool = false;
                    if(question.answers.find((answer) => answer.id == prefilledAnswer.answer) !== undefined) {
                        let answer = question.answers.find((answer) => answer.id == prefilledAnswer.answer);
                        const answerSubtypeArr = answer.subtype.split(',');
                        answerSubtypeArr.forEach((subtype) => {
                            if(subtype === "questionpool") {
                                isQuestionpool = true;
                            }
                        });
                    }
                    return isQuestionpool;
                }
            }
        }
    }

    function setPrefilledWarning(answerId) {
        let answer = question.answers.find((answer) => answer.id == answerId)
        if(answer && answer.warningtxt !== "" && answer.warningtxt !== null) {
            if(warning.length !== 0) {
                warning.forEach((warning) => {
                    if (warning.warningtxt !== answer.warningtxt) {
                        warning.push(answer);
                    }
                })
            } else {
                warning.push(answer);
            }
            setAlertBox(<AlertBox warnings={warning}/>);
        }
    }

    function setPrefilledTextfieldbox(answerId) {
        let answer = question.answers.find((answer) => answer.id == answerId)
        const answerSubtypeArr = answer.subtype.split(',');
        answerSubtypeArr.forEach((subtype) => {
            if(answer && subtype === "textarea") {
                additionalTextfield.push(answer);
                setAdditionalTextfieldBox(<AdditionalTextfieldBox textfields={additionalTextfield} prefilled={prefilledAnswer}/>);
            }
        })

    }

    function setSessionInformation(event) {
        if(question.answertype === "yesno") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: event.target.yesno.value,
            });
            if(event.target.textfield !== undefined && event.target.textfield !== "") {
                Object.assign(sessionInformation, {additional_data: [{
                    answer: event.target.yesno.value,
                    text: event.target.textfield.value
                }]});
            }
        } else if(question.answertype === "listelement") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: getCheckedCollection(question),
            });
            if((event.target.textfield !== undefined && event.target.textfield !== "") && question.subtype === "singleselect") {
                Object.assign(sessionInformation, {additional_data: [{
                    answer: event.target.list_element.value,
                    text: event.target.textfield.value
                }]});
            }
            if(question.subtype === "multiselect") {
                additionalTextfield.map((field) => {
                    sessionInformation['additional_data'].push({
                        answer: field.id,
                        text: getValueOfTextfield(field)
                    })
                })
            }
        } else if (question.answertype === "serviceprovider") {
            let customServiceProviders = serviceProviders.filter((provider) => provider.scope === "custom" && provider.provider_category_id == question.answers[0].subtype.split("_")[1]).map(provider => provider.id)
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: getCheckedCollection(question, customServiceProviders),
            });
            if(event.target.textfield !== undefined && event.target.textfield !== "") {
                Object.assign(sessionInformation, {additional_data: [{
                        text: event.target.textfield.value
                }]});
            }
        } else if(question.answertype === "textfield") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                additional_text: event.target.textfield.value,
            });
        }
    }

    function setCustomProviderInformation(event) {
        Object.assign(customProviderInformation, {
            company: event.target.company.value,
            street: event.target.street.value,
            housenumber: event.target.housenumber.value,
            zipcode: event.target.zipcode.value,
            city: event.target.city.value,
            country: event.target.country.value,
            eu: event.target.eu.value,
            provider_category_id: question.answers[0].subtype.split("_")[1],
            triggered_question_id: question.id
        });
        resetCustomProviderInformation(event)
    }

    function resetCustomProviderInformation(event) {
        event.target.company.value = "";
        event.target.street.value = "";
        event.target.housenumber.value = "";
        event.target.zipcode.value = "";
        event.target.city.value = "";
        event.target.country.value = "";
        event.target.eu.value = "";
    }

    function closeCustomForm() {
        setFormCustomServiceProvider(<></>);
        setDisplayCustomServiceProviders(false);
        document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
            if(provider.value === '99999') {
                provider.checked = false;
                provider.classList.remove("checked");
            }
        })
    }

    function handleDelete(customServiceProviderId) {
        deleteCustomServiceProvider(accessToken,makeGeneralRequest,setIsLoading, customServiceProviderId).then(() => {
            fetchServiceProviders(accessToken, makeAuthenticatedRequest, setServiceProviders ,setIsLoading);
        })
        setFormCustomServiceProvider(<></>);
        setDisplayCreateCustom(true);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(formAction === "submit_answer") {
            setSessionInformation(e);
            if(addSubQuestions) {
                let counter= 1;
                if(additionalAnswer.length === 0 || additionalAnswer.length === undefined) {
                    if(additionalAnswer.target[0].object !== undefined) {
                        let subQuestions = additionalAnswer.target[0].object;
                        subQuestions.map((subQuestion) => {
                            if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                counter++;
                            }
                        })
                    } else if(foreignSubQuestion.length !== 0) {
                        if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                            categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                        }
                    }
                } else {
                    additionalAnswer.forEach((addAnswer) => {
                        const answerSubtypeArr = addAnswer.subtype.split(',');
                        answerSubtypeArr.forEach((subtype) => {
                            if(subtype === "questionpool") {
                                if(addAnswer.target[0].object !== undefined) {
                                    let subQuestions = addAnswer.target[0].object;
                                    subQuestions.map((subQuestion) => {
                                        if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                            categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                            counter++;
                                        }
                                    })
                                } else if(foreignSubQuestion.length !== 0) {
                                    if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                        categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                    }
                                }
                            }
                        });
                    })
                }
            } else if(checkForPrefilledSubquestions() && additionalAnswer.length === 0) {
                let counter= 1;
                if(question.answertype === 'serviceprovider') {
                    let limiter = 0;
                    if(prefilledAnswer.answer.includes(",")) {
                        prefilledAnswer.answer.split(",").forEach((answerId) => {
                            if(answerId === '77777') {
                                let answer = question.answers.find((answer) => answer.title == 'no_serviceprovider');
                                const answerSubtypeArr = answer.subtype.split(',');
                                answerSubtypeArr.forEach((subtype) => {
                                    if(subtype === "questionpool") {
                                        if(answer.target[0].object !== undefined) {
                                            let subQuestions = answer.target[0].object;
                                            subQuestions.map((subQuestion) => {
                                                if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                    categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                    counter++;
                                                }
                                            })
                                        } else if(foreignSubQuestion.length !== 0) {
                                            if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                            }
                                        }
                                    }
                                });
                            } else if(answerId === '88888') {
                                let answer = question.answers.find((answer) => answer.title == 'nothing_serviceprovider');
                                const answerSubtypeArr = answer.subtype.split(',');
                                answerSubtypeArr.forEach((subtype) => {
                                    if(subtype === "questionpool") {
                                        if(answer.target[0].object !== undefined) {
                                            let subQuestions = answer.target[0].object;
                                            subQuestions.map((subQuestion) => {
                                                if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                    categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                    counter++;
                                                }
                                            })
                                        } else if(foreignSubQuestion.length !== 0) {
                                            if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                            }
                                        }
                                    }
                                });
                            } else if (answerId !== '77777' && answerId !== '88888') {
                                let answer = question.answers.find((answer) => answer.title == 'has_serviceprovider');
                                const answerSubtypeArr = answer.subtype.split(',');
                                answerSubtypeArr.forEach((subtype) => {
                                    if(subtype === "questionpool") {
                                        if(answer.target[0].object !== undefined && limiter < 1) {
                                            let subQuestions = answer.target[0].object;
                                            subQuestions.map((subQuestion) => {
                                                if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                    categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                    counter++;
                                                }
                                            })
                                            limiter++
                                        } else if(foreignSubQuestion.length !== 0) {
                                            if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                            }
                                        }
                                    }
                                });
                            }
                        })
                    } else if (prefilledAnswer.length !== 0) {
                        if(prefilledAnswer.answer === '77777') {
                            let answer = question.answers.find((answer) => answer.title == 'no_serviceprovider');
                            const answerSubtypeArr = answer.subtype.split(',');
                            answerSubtypeArr.forEach((subtype) => {
                                if(subtype === "questionpool") {
                                    if(answer.target[0].object !== undefined) {
                                        let subQuestions = answer.target[0].object;
                                        subQuestions.map((subQuestion) => {
                                            if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                counter++;
                                            }
                                        })
                                    } else if(foreignSubQuestion.length !== 0) {
                                        if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                            categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                        }
                                    }
                                }
                            });
                        } else if (prefilledAnswer.answer === '88888') {
                            let answer = question.answers.find((answer) => answer.title == 'nothing_serviceprovider');
                            const answerSubtypeArr = answer.subtype.split(',');
                            answerSubtypeArr.forEach((subtype) => {
                                if(subtype === "questionpool") {
                                    if(answer.target[0].object !== undefined) {
                                        let subQuestions = answer.target[0].object;
                                        subQuestions.map((subQuestion) => {
                                            if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                counter++;
                                            }
                                        })
                                    } else if(foreignSubQuestion.length !== 0) {
                                        if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                            categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                        }
                                    }
                                }
                            });
                        } else if (prefilledAnswer.answer !== '77777' && prefilledAnswer.answer !== '88888') {
                            let answer = question.answers.find((answer) => answer.title == 'has_serviceprovider');
                            const answerSubtypeArr = answer.subtype.split(',');
                            answerSubtypeArr.forEach((subtype) => {
                                if(subtype === "questionpool") {
                                    if(answer.target[0].object !== undefined) {
                                        let subQuestions = answer.target[0].object;
                                        subQuestions.map((subQuestion) => {
                                            if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                counter++;
                                            }
                                        })
                                    } else if(foreignSubQuestion.length !== 0) {
                                        if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                            categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                        }
                                    }
                                }
                            });
                        }
                    }
                } else {
                    if(prefilledAnswer.answer.includes(",")) {
                        prefilledAnswer.answer.split(",").forEach((answerId) => {
                            let answer = question.answers.find((answer) => answer.id == answerId);
                            const answerSubtypeArr = answer.subtype.split(',');
                            answerSubtypeArr.forEach((subtype) => {
                                if(subtype === "questionpool") {
                                    if(answer.target[0].object !== undefined) {
                                        let subQuestions = answer.target[0].object;
                                        subQuestions.map((subQuestion) => {
                                            if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                                categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                                counter++;
                                            }
                                        })
                                    } else if(foreignSubQuestion.length !== 0) {
                                        if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                            categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                                        }
                                    }
                                }
                            });
                        })
                    } else {
                        let answer = question.answers.find((answer) => answer.id == prefilledAnswer.answer);
                        if(answer.target[0].object !== undefined) {
                            let subQuestions = answer.target[0].object;
                            subQuestions.map((subQuestion) => {
                                if(!categoryQuestions.find(index => index.id === subQuestion.id)) {
                                    categoryQuestions.splice(currentIndex+counter, 0, subQuestion);
                                    counter++;
                                }
                            })
                        } else if(foreignSubQuestion.length !== 0) {
                            if(!categoryQuestions.find(index => index.id === foreignSubQuestion.id)) {
                                categoryQuestions.splice(currentIndex+1, 0, foreignSubQuestion);
                            }
                        }
                    }
                }
            }
            postAnswer(accessToken, makeGeneralRequest, setIsLoading, sessionInformation, isSkippable, questionTree).then(() => {
                fetchCategoryQuestions(accessToken, makeAuthenticatedRequest, setCategoryQuestions, setIsWaiting, currentCategoryId, false).then(function (newCategoryQuestions) {
                    handleAdditionalAnswer(additionalAnswer)
                    if(question.title === "Bitte geben Sie eine Bezeichnung für die Verarbeitungstätigkeit an!"){
                        setOpaCategory(sessionInformation.additional_text);
                    }
                    setAdditionalAnswer([]);
                    setAlertBox(<></>);
                    setWarning([]);
                    setAdditionalTextfield([]);
                    setPrefilledAnswer([]);
                    sessionInformation = {};
                    setIsSkippable(false);
                    addSubQuestions = false;
                    setIsAnswerGiven(false);
                    setDisplayCustomServiceProviders(false);
                    setMountSkipButton(false);
                    displayAnswer(null);
                    if(newCategoryQuestions[currentIndex+1]) {
                        navigate(`/categories/${currentCategory[0].id}/${toSlug(currentCategory[0].title)}/questions/${newCategoryQuestions[currentIndex+1].id}/${toSlug(newCategoryQuestions[currentIndex+1].title)}/${currentIndex+1}`);
                    } else {
                        if(parentCategory.length !== 0 && currentCategory[0].parent !== 0) {
                            navigate(`/categories/${parentCategory[0].id}/${toSlug(parentCategory[0].title)}`, {
                                state: {
                                    current_category: parentCategory[0].id
                                }
                            });
                        } else {
                            navigate("/categories");
                        }
                        successNotification(`Sie haben den Themenbereich "${currentCategory[0].title}" erfolgreich erfasst.`, true);
                    }
                })
            });
        } else if(formAction === "submit_custom_service_provider") {
            setCustomProviderInformation(e);
            postCustomServiceProvider(accessToken,makeGeneralRequest,setIsLoading, customProviderInformation).then(() => {
                fetchServiceProviders(accessToken, makeAuthenticatedRequest, setServiceProviders ,setIsLoading);
            });
            setDisplayCreateCustom(true);
        } else if(formAction === "edit_custom_service_provider") {
            setCustomProviderInformation(e);
            updateCustomServiceProvider(accessToken,makeGeneralRequest,setIsLoading,customProviderInformation, customServiceProviderId).then(() => {
                fetchServiceProviders(accessToken, makeAuthenticatedRequest, setServiceProviders ,setIsLoading);
            });
            setDisplayCreateCustom(true);
        }
        setFormCustomServiceProvider(<></>);
        setForeignSubQuestion([]);
    }

    function handleReturn() {
        setWarning([]);
        setAdditionalTextfield([]);
        setFormCustomServiceProvider(<></>);
        setPrefilledAnswer([]);
        setForeignSubQuestion([]);
        setIsAnswerGiven(false);
        setDisplayCustomServiceProviders(false);
        setMountSkipButton(false);
        displayAnswer(null);
        setServiceProviders([]);
        if(categoryQuestions[currentIndex-1]) {
            navigate(`/categories/${currentCategory[0].id}/${toSlug(currentCategory[0].title)}/questions/${categoryQuestions[currentIndex-1].id}/${toSlug(categoryQuestions[currentIndex-1].title)}/${currentIndex-1}`);
        } else {
            if(parentCategory.length !== 0 && currentCategory[0].parent !== 0) {
                navigate(`/categories/${parentCategory[0].id}/${toSlug(parentCategory[0].title)}`, {
                    state: {
                        current_category: parentCategory[0].id
                    }
                });
            } else {
                navigate("/categories");
            }
        }
    }

    return(
        <div className="flex flex-col text-center text-ere-content-blue justify-center content-center w-full">
            {categoryQuestions.length === 0 ?
                <div className="m-auto">
                    <Preloader/>
                </div>
            :
                <p>{currentIndex+1} / {categoryQuestions.length}</p>
            }
            {opaCategory ?
                <h1 className="text-xl mb-3">{opaCategory}</h1>
                :
                <h1 className="text-xl mb-3">{currentCategory.length !== 0 ? currentCategory[0].title : ""}</h1>
            }
            {question.hint ?
                <ModalQuestion questionTitle={question.title} popupAnswer={question.hint} />
            :
                <h2 className="text-base md:text-lg  w-fit self-center flex content-center relative">
                    {question.title}
                </h2>
            }
            {question.description ?
                <h3 className="rte-field text-sm md:text-base" dangerouslySetInnerHTML={{__html: question.description}}/>
                :
                <></>
            }
            {question.subtype === "multiselect" ?
                <h3 className="text-sm md:text-base">(Mehrfachauswahl möglich)</h3>
                :
                <></>
            }
            <div className="mb-8"/>
            <Form method="post" onSubmit={handleSubmit} className="flex flex-col text-start flex-nowrap lg:pb-[10%] mb-[100px] p-[20px] lg:p-0 z-0 lg:w-[calc(100%-60px)] lg:ml-auto w-[calc(100%-6px)] md:w-[calc(100%+12px)] md:ml-[-8px] mx-auto" ref={questionCatalogRef}>
                <ExitDialog visible={isVisibleDialog}
                            onClose={() => {
                                setVisibleDialog(false);
                                setIsBlocked(true);
                            }}
                ></ExitDialog>
                {displayAnswer(question.answertype)}
                {handleAdditionalAnswer(additionalAnswer)}
                {displayCustomServiceProvider()}
                {formCustomServiceProvider}
                {additionalTextfieldBox}
                {alertBox}
                <div className="text-sm flex justify-between gap-3 items-center absolute bottom-0 lg:bottom-[30px] left-[24px] lg:left-[55px] lg:w-[calc(100%-110px)] w-[calc(100%-48px)] ">
                    { isWaiting ?
                        <>
                            <div className="w-[50%] max-w-[148px] md:w-fit h-[45px]">
                                <button id="erecht24-back-button"  type="button" className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] p-[14px] max-w-[148px] bg-slate-50 text-ere-dark-gray border-ere-dark-gray">
                                    <Preloader/>
                                </button>
                            </div>
                            <button id="erecht24-next-button"  type="button" className="flex items-center justify-center h-[45px] float-right place-self-center btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] p-[14px] max-w-[148px] w-[50%] bg-slate-50 text-ere-dark-gray border-ere-dark-gray">
                                <Preloader/>
                            </button>
                        </>
                    :
                        <>
                            <div className="w-[50%] max-w-[148px] md:w-fit h-[45px]">
                                <button id="erecht24-back-button" type="button" onClick={() => {handleReturn()}} className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] p-[14px] max-w-[148px]">
                                    <BackIcon/>Zurück
                                </button>
                            </div>
                            { mountSkipButton ?
                                <>
                                    {(prefilledAnswer.length === 0 || prefilledAnswer.skipped === 1) && question.skippable && mountSkipButton ?
                                        <button id="erecht24-skip-button" type="submit" className="underline text-[14px] text-ere-link-blue uppercase font-[700] underline-offset-[3px]" onClick={() => {setIsSkippable(true)}}>Frage überspringen</button>
                                        :
                                        <></>
                                    }
                                </>
                            :
                                <></>
                            }
                            {isAnswerGiven ?
                                <button id="erecht24-next-button" type="submit" className="flex items-center justify-center h-[45px] float-right place-self-center btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] p-[14px] max-w-[148px] w-[50%]" onClick={() => handleFormAction("submit_answer")}>
                                    {currentIndex + 1 === categoryQuestions.length && (!addSubQuestions && !checkForPrefilledSubquestions()) ? "Abschließen" : "Weiter"}
                                    {currentIndex + 1 === categoryQuestions.length && (!addSubQuestions && !checkForPrefilledSubquestions()) ? "" : <BackIcon className="rotate-180"/>}
                                </button>
                            :
                                <button id="erecht24-next-button" type="submit" disabled className="flex items-center justify-center h-[45px] float-right place-self-center btn hover:btn-secondary btn-secondary-active-and-hover md:min-w-[148px] p-[14px] max-w-[148px] w-[50%] disabled:bg-slate-50 disabled:text-ere-dark-gray disabled:border-ere-dark-gray disabled:shadow-none" onClick={() => handleFormAction("submit_answer")}>
                                    {currentIndex + 1 === categoryQuestions.length && !addSubQuestions ? "Abschließen" : "Weiter"}
                                    {currentIndex + 1 === categoryQuestions.length && !addSubQuestions ? "" : <BackIcon className="rotate-180 contrast-0"/>}
                                </button>
                            }
                        </>
                    }
                </div>
            </Form>
            { categoryForProgress.length !== 0 && categoryQuestions.length !== 0 ?
                <Bar done={round(((currentIndex+1)/categoryQuestions.length)*100)}/>
            :
                <></>
            }
            { !isWaiting ?
                <span className="ert-loaded hidden">loaded</span>
            :
                <></>
            }
        </div>
    );
}