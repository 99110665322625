export let selectedMenuPoint = [];
export let currentQuestionCategory = [];
export let currentCatalog = [];

export function setSelectedMenuPoint(menuPoint, path) {
    selectedMenuPoint = [
        menuPoint,
        path
    ];
}

export function setCurrentQuestionCategory(questionCategory, path) {
    currentQuestionCategory = [
        questionCategory,
        path
    ];
}

export function setCurrentCatalog(catalog) {
    currentCatalog = [
        catalog
    ];
}

export function resetSelectedMenuPoint() {
    selectedMenuPoint = [];
}


export function resetCurrentQuestionCategory() {
    currentQuestionCategory = [];
}

export function resetCurrentCatalog() {
    currentCatalog = [];
}