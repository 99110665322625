import React from'react';
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-icon.svg";

export const GlobalFilter = ({filter, setFilter}) => {
    return(
        <span className="min-w-[150px] w-fit md:w-[320px] h-[37px] px-2 rounded-sm border-2 border-sky-600 border-opacity-40 gap-2 inline-flex items-center">
            <SearchIcon className="w-[20px] h-[20px]" />
            <input className="text-neutral-700 text-opacity-60 text-base font-normal outline-none bg-transparent" placeholder="Suchen..." value={filter || ''} onChange = {e => setFilter(e.target.value)} ></input>
        </span>
    )
}