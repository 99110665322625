import {useEffect, useState} from 'react';
import Meter from "./Meter";
import Indicator from "../Progress/Indicator";
import ModalCard from "../Risk/Popup/ModalCard.jsx";
import {globalQuestionProgress, globalRisk} from "../../../utils/helpers";

export default function Card() {
    const [questionProgress, setQuestionProgress] = useState({});
    const [risk, setRisk] = useState({});

    useEffect(() => {
        function waitForToken() {
            if(Object.keys(globalQuestionProgress).length !== 0 && Object.keys(globalRisk).length !== 0) {
                setQuestionProgress(globalQuestionProgress);
                setRisk(globalRisk);
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    }, [])

    return (
        <div id='risk-card' className="font-normal flex flex-col risk-card min-w-[250px] w-full h-fit justify-evenly bg-white rounded light-blue-border md:flex-row xl:justify-evenly mb-[24px]">
            <div className="flex lg:flex-row mx-[16px] my-[12px]  md:w-[50%]">
                <Meter riskValue={Object.keys(risk).length !== 0 ? risk.risk_value : 0}/>
                {Object.keys(risk).length !== 0 && risk.risk !== null ?
                    <div className="text-ere-grey-alternative  sm:w-full sm:max-w-[346px] text-left text-sm  md:inline h-auto self-center ml-[24px]">
                        {Object.keys(risk).length !== 0 ? <div className="text-base text-ere-content-blue font-bold">{risk.risk.title}</div> : <></>}
                        {Object.keys(risk).length !== 0 ? <div className="rte-field leading-normal" dangerouslySetInnerHTML={{__html: risk.risk.description}}/> : <></>}
                        {Object.keys(risk).length !== 0 ?
                            <>
                                {risk.risk ? <ModalCard title={risk.risk.extended_description_linktitle} content={risk.risk.extended_description}/>  : <></>}
                            </>
                            :
                            <></>
                        }
                    </div>
                :
                    <div className="md:max-w-[346px] text-left md:inline text-sm h-auto self-center ml-[24px]">
                        <p>Derzeit besteht kein Risiko</p>
                        {Object.keys(risk).length !== 0 && risk ?
                            <>
                                {risk.risk ? <ModalCard title={risk.risk.extended_linktitle} content={risk.risk.extended_description}/> : <></>}
                            </>
                            :
                            <></>
                        }
                    </div>
                }
            </div>

            <div className="flex lg:flex-row ml-[22px] mt-[12px] mb-[12px] mr-[12px]  md:w-[50%]">
                <Indicator  done={Object.keys(questionProgress).length !== 0 ? questionProgress.progress_value : 0} small={false}/>
                <div className="text-ere-grey-alternative w-fit h-auto self-center  text-sm md:inline ml-[21px]">
                    {Object.keys(questionProgress).length !== 0 ? <div className="text-base text-ere-content-blue font-bold">{questionProgress.progress.title}</div> : <></>}
                    {Object.keys(questionProgress).length !== 0 ? <div className="rte-field leading-normal" dangerouslySetInnerHTML={{__html: questionProgress.progress.description}}/> : <></>}
                    {Object.keys(risk).length !== 0 ?
                        <>
                            {questionProgress !== undefined && questionProgress.progress ? <ModalCard title={questionProgress.progress.extended_linktitle} content={questionProgress.progress.extended_description}/>  : <></>}
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
}