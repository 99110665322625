import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import {ReactComponent as EscapeButton} from "../../../assets/icons/escape.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container':{
        background: 'rgba(202, 235, 255, 0.40)',
        backdropFilter: 'blur(3px)',
        '@media (min-width: 320px)': {
            width:'100%',
        },
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        display:'flex',
        justifyContent:'center',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root':{
        minWidth:'98%',

        border: 'solid 1px #0094D8',
        borderRadius:'0',
        padding:'20px',
        paddingTop:'40px',
        boxShadow:'none',
        maxWidth:'250px',
        '@media (min-width: 380px)': {
            maxWidth:'100%',
            padding:'0',
        },

        margin:0,


        '@media (min-width: 340px)': {
            marginLeft:'unset',
            margin:'10px',

        },
    },
    '& .MuiPaper-root h2':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root h1':{
        fontSize:'24px',
        color:'#004059',
        marginBottom:'20px',
    },
    '& .MuiPaper-root p':{
        fontSize:'20px',
        color:'#004059',
        marginBottom:'20px',
        fontWeight:'bold',
        textAlign:'center'
    },
    '& .MuiPaper-root .hinweis':{
        fontSize:'16px',
        color:'#444444',
        marginTop:'24px',
        textAlign:'center',
        fontWeight:'300'
    },
    '& .MuiPaper-root .btns-container':{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'24px',
    },
    '& .MuiPaper-root .btn':{
        width:'197px',
        height:'47px',
        fontSize:'14px',
        color:'white',
        pointer:'cursor !important',
        fontWeight:'900',
        borderRadius:'2px',
        border:'none'

    },
    '& .MuiPaper-root .cancel-btn':{
        backgroundColor:'#BBBBBB',
        pointer:'cursor !important',

    },
    '& .MuiPaper-root .cancel-btn:hover':{
        backgroundColor:'#999',
        pointer:'cursor !important',
    },
    '& .MuiPaper-root .save-btn':{
        backgroundColor:'#0094D8',
        pointer:'cursor !important',
    },
    '& .MuiPaper-root .save-btn:hover':{
        backgroundColor:'white',
        color:'#0094D8',
        border: '2px solid #0094D8',
        pointer:'cursor !important',
    },
    '& .MuiPaper-root .MuiButton-root:first-child':{
        width:'197px !important',
        height:'47px !important',
        fontSize:'14px !important',
        color:'white !important',
        pointer:'cursor !important',
        fontWeight:'900     ',
        borderRadius:'2px !important' ,
        backgroundColor:'#BBBBBB !important',
        border:'none !important',
    },
    '& .MuiPaper-root .MuiButton-root:last-of-type':{
        width:'197px !important',
        height:'47px !important',
        fontSize:'14px !important',
        color:'white !important',
        pointer:'cursor !important',
        fontWeight:'900 !important',
        borderRadius:'2px !important' ,
        backgroundColor:'#0094D8 !important',
        border:'none !important',
    },
    '& .MuiPaper-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root:first-child:focus':{
        outline:'-webkit-focus-ring-color auto thin',
    },
    '& .MuiPaper-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root:last-of-type:focus':{
        outline:'-webkit-focus-ring-color auto thin',
    },
    '& .MuiPaper-root .escape-btn:focus':{
        outline:'-webkit-focus-ring-color auto thin',
        borderRadius: '0'
    },
    '& .MuiPaper-root .MuiDialogActions-root button':{
        cursor:'pointer !important',
    },
    '& .MuiPaper-root .MuiDialogActions-root button:focus':{
        outline:'solid 2px #e59700 !important',
    },
    '& .MuiPaper-root .css-uhb5lp':{
        overflowY:'unset !important',
    },
    '& .MuiPaper-root ':{
        overflowY:'unset !important',
    }
}));

export default function CustomProcessAccessDialog({ onClose, visible = false, url }) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(visible) {
            setOpen(true);
        }
    }, [visible]);

    return (
        <React.Fragment>
            <BootstrapDialog
                open={open}
                aria-labelledby="customized-dialog-title"
                className="min-w-[400px]"
            >
                <IconButton
                    className="escape-btn"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                        onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >

                    <EscapeButton/>
                </IconButton>
                <DialogContent>
                    <iframe src={url} height="1000px" width="1200px" />
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};