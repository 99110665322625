import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {setInQuestionCatalog, toSlug} from "../../../utils/helpers";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as AttentionIcon } from "../../../assets/icons/attention-icon.svg";
import {useAuth} from "../../../hooks/useAuth";
import Preloader from "../../General/Preloader";
import Indicator from "../Progress/Indicator";
import {setCurrentCatalog, setSelectedMenuPoint} from "../../../utils/breadcrumb";

export default function Box({category, hasChildren, skippedQuestions}) {
    const lastChanged = new Date(category.answer_progress_by_category_only.last_changes_at)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {makeAuthenticatedRequest} = useAuth();

    useState(() => {},[makeAuthenticatedRequest,category.id])

    if (isLoading) {
        return (
            <div id="category_box" className="category w-full flex-grow text-start h-[140px] bg-white light-blue-border-category flex flex-col justify-between py-[12px] px-[16px] hover:shadow-lg hover:scale-105 transition-all duration-500 hover:bg-ere-category-hover focus:shadow-lg focus:scale-105 focus:bg-ere-category-hover group">
                <div className="flex flex-col items-center h-full justify-center w-full">
                    <Preloader/>
                </div>
            </div>
        );
    }

    function whenClicked() {
        if(hasChildren) {
            navigate(`/categories/${category.id}/${toSlug(category.title)}`, {
                state: {
                    current_category: category.id
                }
            });
        } else {
            setInQuestionCatalog(true);
            setSelectedMenuPoint("Mein Datenschutzstatus", "/categories");
            setCurrentCatalog(category.title);
            navigate(`/categories/${category.id}/${toSlug(category.title)}/questions/${category.firstSimpleQuestion.id}/${toSlug(category.firstSimpleQuestion.title)}/0`)
        }
    }

    return (
        <button id="category_box" type="button" onClick={e => whenClicked(e, category)} className="category_box category w-full  flex-grow text-start     h-[140px] bg-white light-blue-border-category flex flex-col justify-between py-[12px] px-[16px]  hover:shadow-lg hover:scale-105 transition-all duration-500 hover:bg-ere-category-hover focus:shadow-lg focus:scale-105 focus:bg-ere-category-hover group">
            <div className="flex flex-col items-start">
                <h3 className="text-[18px] sm:text-[16px] med:text-[18px]  text-ere-content-blue font-normal text-left">{category.title}</h3>
                {category.answer_progress_by_category_only.last_changes_at ?
                    <span className="text-ere-grey-alternative text-[12px]">Zuletzt bearbeitet: <span className="inline-block">{('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'} </span> </span>
                :
                    <span className="text-ere-grey-alternative text-[12px]">Noch nicht bearbeitet</span>
                }
                { skippedQuestions !== undefined ?
                    <span className="text-ere-orange text-[12px]"><AttentionIcon style={{"width": "14px"}} className="width-[14px] inline mr-1 mb-1"/>{skippedQuestions.skipped_question.length} {skippedQuestions.skipped_question.length > 1 ? ' übersprungene Fragen' : ' übersprungene Frage'}</span>
                :
                    <></>
                }
            </div>
            <div className="flex justify-between w-full items-center">
                {(process.env.REACT_APP_SHOW_CATEGORY_STATE === "true") ?
                    <Indicator done={category.answer_progress_by_category_only.progress_value} small={true}/>
                :
                    <></>
                }
                <ArrowRight className="hover:scale-125 group-hover:scale-125 transition-all ease-in-out duration-1000 item-right"/>
            </div>
        </button>
    );
}