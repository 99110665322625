import {useEffect, useState} from "react";
import {fetchCountries} from "../../../utils/api/countries";
import {useAuth} from "../../../hooks/useAuth";
import {fetchAddresses, fetchCorporateForms} from "../../../utils/api/adresses";

export default function MasterData({task, type}) {
    const [isLoading, setIsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [corporateForms, setCorporateForms] = useState([])
    const [addresses, setAddresses] = useState([]);
    const { makeAuthenticatedRequest, accessToken } = useAuth();

    useEffect(() => {
        if(countries.length === 0) {
            fetchCountries(accessToken, makeAuthenticatedRequest, setCountries, setIsLoading);
        }
        if(corporateForms.length === 0) {
            fetchCorporateForms(accessToken, makeAuthenticatedRequest, setCorporateForms, setIsLoading);
        }
        fetchAddresses(accessToken, makeAuthenticatedRequest, setAddresses, setIsLoading, type);
    }, [makeAuthenticatedRequest])

    const selectCountries = Object.keys(countries).map((country) => (
        <>
            { addresses.length !== 0 ?
                <>
                    {country !== undefined && addresses[0].country === country ?
                        <option value={country} selected>{countries[country]}</option>
                    :
                        <option value={country}>{countries[country]}</option>
                    }
                </>
            :
                <option value={country}>{countries[country]}</option>
            }
        </>
    ))

    const selectCorporateForms = Object.keys(corporateForms).map((corporateForm) => (
        <>
            { addresses.length !== 0 ?
                <>
                    {corporateForm !== undefined && addresses[0].corporate_form === corporateForm ?
                        <option value={corporateForm} selected>{corporateForms[corporateForm]}</option>
                    :
                        <option value={corporateForm}>{corporateForms[corporateForm]}</option>
                    }
                </>
                :
                <option value={corporateForm}>{corporateForms[corporateForm]}</option>
            }
        </>
    ))

    return (
        <div className="flex flex-col text-start flex-nowrap pb-[30px] text-base">
            <div className="flex flex-wrap mb-[24px]">
                <div className="w-full">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-password">Firmenname *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="company" type="text" defaultValue={addresses.length !== 0 ? addresses[0].company : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
            </div>
            <div className="flex flex-wrap  mb-[24px]  md:grid md:grid-cols-2 gap-[21px]">
                <div className="w-full  md:w-auto mb-2 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Vorname *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="firstname" type="text" defaultValue={addresses.length !== 0 ? addresses[0].firstname : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                <div className="w-full md:w-auto ">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-last-name">Nachname *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="lastname" type="text" defaultValue={addresses.length !== 0 ? addresses[0].lastname : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
            </div>
            { task && task.task.subtype === "organisation" || type === "organisation" ?
                <div className="flex flex-wrap  mb-[24px]">
                    <div className="w-full">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-password">Geschäftsführer</label>
                        <textarea className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="managing_director" defaultValue={addresses.length !== 0 && addresses[0].managing_director ? addresses[0].managing_director : ''} rows="4"/>
                    </div>
                </div>
                :
                <></>
            }
            <div className="flex flex-wrap  mb-[24px] md:grid md:grid-cols-2 gap-[21px]">
                <div className="w-full md:w-auto mb-2 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Straße *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="street" type="text" defaultValue={addresses.length !== 0 ? addresses[0].street : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                <div className="w-full md:w-auto">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-last-name">Hausnummer *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="housenumber" type="text" defaultValue={addresses.length !== 0 ? addresses[0].housenumber : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
            </div>
            <div className="flex flex-wrap md:grid md:grid-cols-2 gap-[21px]">
                <div className="w-full md:auto  mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Postleitzahl *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="zipcode" type="text" defaultValue={addresses.length !== 0 ? addresses[0].zipcode : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                <div className="w-full md:auto mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-last-name">Ort *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="city" type="text" defaultValue={addresses.length !== 0 ? addresses[0].city : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
            </div>
            <div className="flex flex-wrap  mb-[24px] ">
                <div className="w-full ">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-password">Land *</label>
                    <select className="text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none selectcountry" name="country" required
                            onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie ein Element in der Liste aus.')}
                            onInput={e => e.target.setCustomValidity('')}
                    >
                        <option value="">Bitte auswählen</option>
                        <hr/>
                        <option value="DE">Deutschland</option>
                        <option value="AT">Österreich</option>
                        <option value="CH">Schweiz</option>
                        <hr/>
                        {selectCountries}
                    </select>
                </div>
            </div>
            <div className="flex flex-wrap mb-[24px]">
                <div className="w-full">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-password">E-Mail *</label>

                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="email" type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="beispiel@adresse.de" defaultValue={addresses.length !== 0 ? addresses[0].email : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
            </div>
            <div className="flex flex-wrap  md:grid md:grid-cols-3 gap-[21px]">
                <div className="w-full md:auto mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Telefon *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="telephone" type="text" defaultValue={addresses.length !== 0 ? addresses[0].telephone : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                <div className="w-full md:w-auto  mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Fax</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="fax" type="text" defaultValue={addresses.length !== 0 ? addresses[0].fax : ''}/>
                </div>
                <div className="w-full md:w-auto mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Mobiltelefon</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="mobile" type="text" defaultValue={addresses.length !== 0 ? addresses[0].mobile : ''}/>
                </div>
            </div>
            <div className="flex flex-wrap md:grid md:grid-cols-2 gap-[21px]">
                <div className="w-full md:w-auto mb-[24px]">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-first-name">Website *</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="website" type="text" defaultValue={addresses.length !== 0 ? addresses[0].website : ''} required
                           onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                           onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                <div className="w-full md:w-auto ">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-[8px]" htmlFor="grid-last-name">Gesellschaftsform *</label>
                    <select className="text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none selectcountry" name="corporate_form" required
                            onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie ein Element in der Liste aus.')}
                            onInput={e => e.target.setCustomValidity('')}
                    >
                        <option value="">Bitte auswählen</option>
                        {selectCorporateForms}
                    </select>
                </div>
            </div>
        </div>
    );
}