import {apiUrl} from "../helpers";
import {successNotification, warningNotification} from "../../notify";

export async function fetchTask(accessToken, makeAuthenticatedRequest, setState, setIsLoading, taskId) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tasks/${taskId}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        // make authenticated request
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data[0]);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchTasks(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tasks`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        // make authenticated request
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postTaskDone(accessToken, makeGeneralRequest,setIsLoading,taskId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        setIsLoading(true);
        const url = `${apiUrl}/tasks/${taskId}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        // the request body
        const body = JSON.stringify({
            state: "done"
        });
        // authenticated request
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        successNotification("Aufgabe erfolgreich abgeschlossen", true);
        return response;
    } catch (error) {
        warningNotification("Aufgabe konnte nicht erledigt werden", false);
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postUpload(accessToken, makeGeneralRequest, setIsLoading, formInformation, contractId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/contracts/${contractId}`;

        const customHeaders = {
            Accept: "multipart/form-data",
            Authorization: `Bearer ${accessToken}`
        };

        const response = await makeGeneralRequest(url, customHeaders, 'POST', formInformation);
        successNotification("Datei erfolgreich hochgeladen", true);
        return response;
    } catch (error) {
        warningNotification("Die Datei konnte nicht hochgeladen werden", false);
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postMasterData(accessToken,makeGeneralRequest,setIsLoading, formInformation, projectId) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        setIsLoading(true);
        const url = `${apiUrl}/addresses/${projectId}`;

        const customHeaders = {
            Accept: "application/json, text/javascript, */*; q=0.01",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        // the request body
        const body = JSON.stringify({
            address_type: formInformation.address_type,
            managing_director: formInformation.managing_director,
            corporate_form: formInformation.corporate_form,
            firstname: formInformation.firstname,
            lastname: formInformation.lastname,
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            email: formInformation.email,
            telephone: formInformation.telephone,
            fax: formInformation.fax,
            mobile: formInformation.mobile,
            website: formInformation.website,
        });
        // authenticated request

        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        successNotification("Stammdaten erfolgreich hochgeladen", true);
        return response;
    } catch (error) {
        warningNotification("Stammdaten konnten nicht hochgeladen werden", false);
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postTaskComment(accessToken, makeGeneralRequest, setIsLoading, formInformation) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        setIsLoading(true);
        const url = `${apiUrl}/task-pool-comments`;

        const customHeaders = {
            Accept: "multipart/form-data",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        // the request body
        const body = JSON.stringify({
            user_id: formInformation.user_id,
            user_firstname: formInformation.user_firstname,
            user_lastname: formInformation.user_lastname,
            user_is_data_protection_officer: formInformation.user_is_data_protection_officer,
            taskpool_id: formInformation.taskpool_id,
            comment: formInformation.comment,
        });
        // authenticated request

        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        successNotification("Kommentar veröffentlicht", true);
        return response;
    } catch (error) {
        warningNotification("Kommentar konnte nicht veröffentlicht werden", true);
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}