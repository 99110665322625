import Tour from "reactour";
import "../../assets/css/reactour.css";
import {ReactComponent as BackIcon} from "../../assets/icons/icon-forward.svg";
import {useEffect, useState} from "react";
import {fetchTutorial, postTutorialStep} from "../../utils/api/tutorial";
import {useAuth} from "../../hooks/useAuth";
import manincircle from "../../assets/images/man-in-circle.png";
import {ReactComponent as TutorialIcon} from "../../assets/icons/sidebar/tutorial.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {inQuestionCatalog} from "../../utils/helpers";

export default function Tutorial(){
    const [showTutorial, setShowTutorial]= useState(false);
    const { makeAuthenticatedRequest, makeGeneralRequest, accessToken} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [tutorial, setTutorial] = useState([]);
    const accent = '#0094D8';
    const navigate= useNavigate();
    const location = useLocation();

    useEffect(()=> {
        if(tutorial.length === 0) {
            fetchTutorial(accessToken, makeAuthenticatedRequest, setTutorial, setIsLoading, '12a52b4f-56a0-4d80-b330-c282c7d6460f');
        }
    },[makeAuthenticatedRequest]);

    function toggleOpen(){
        setShowTutorial(false);
        let bodyElement = document.querySelector('body');
        bodyElement.style.overflow ='';
        postTutorialStep(accessToken, makeGeneralRequest, setIsLoading, '12a52b4f-56a0-4d80-b330-c282c7d6460f', tutorial[0].steps.length);
    }

    function getSteps() {
        let stepsArr = [];
        if(tutorial.length !== 0 && !tutorial.message) {
            tutorial[0].steps.forEach((step) => {
                stepsArr.push({
                    selector: '#'+step.htmlid,
                    content:
                        <>
                            <div style={{position:"absolute", top:'-30px', left:'45%', width:'80px', height:'80px', objectFit:'cover',borderRadius:'50%',  border:'solid 1.5px #0094D8', overflow:'hidden',backgroundColor:'#66BFE8', borderBottom:'none'}}>
                                <img src={process.env.REACT_APP_URL + manincircle} alt="nesto" style={{width:'100%', height:'auto', position:"absolute", top:'-5px'}} />
                            </div>
                            <h1 style={{color: '#004059', fontSize:'24px',fontFamily:'Lato', fontWeight: '700', marginBottom:'17px'}}>{step.title}</h1>
                            <p style={{color: '#444', fontSize:'16px', fontFamily:'Lato'}}>
                                {step.description}
                            </p>
                        </>,
                    style: {
                        backgroundColor: 'white',
                        borderRadius:'4px',
                        maxWidth:'670px',
                        fontFamily:'Lato',
                    }
                })
            })
        }
        return stepsArr;
    }
    const shouldAppear = (location.pathname === "/projects" || inQuestionCatalog);

    return (
        <>
            {tutorial.length !== 0 ?
                <>
                    <button
                        className={`group text-[14px]  h-[45px] p-[3px] hidden lg:flex items-center justify-center transition-width duration-300 ease-linear w-fit right-[24px] self-end md:-mb-[52px] z-[30] ml-auto ${shouldAppear ? "!hidden" : ""} `}
                        onClick={() => {
                            if (location.pathname === "/categories") {
                                setShowTutorial(true);
                                let bodyElement = document.querySelector('body');
                                bodyElement.style.overflow = 'hidden';
                            } else {
                                navigate("/categories");
                                setTimeout(() => {
                                    setShowTutorial(true);
                                    let bodyElement = document.querySelector('body');
                                    bodyElement.style.overflow = 'hidden';
                                }, 2000);
                            }
                        }}
                    >
                        <TutorialIcon className="group-hover:scale-125 transition-all duration-300 ease-out"/>
                        <span className="text-base ml-[8px] text-ere-link-blue underline underline-offset-4">Tutorial starten</span>
                    </button>
                    <span className="ert-tutorial-loaded hidden">loaded</span>
                </>
            :
                <></>
            }


            { showTutorial && !tutorial.message &&
                <Tour
                    isOpen={showTutorial}
                    steps={getSteps()}
                    onClickHighlighted={(e) => {
                        e.stopPropagation()
                        console.log('No interaction')
                    }}
                    /* isOpen={tutorial.length !== 0}*/

                    badgeContent={(curr, tot) => `${curr} / ${tot}`}
                    children={<h1 style={{
                        color: '#004059',
                        fontSize: '16px',
                        fontWeight: '400',
                        fontFamily: 'Lato',
                        marginTop: '26px'
                    }}>
                        Datenschutz-Management-Lösung Tutorial
                    </h1>}
                    onRequestClose={toggleOpen}
                    prevButton={<button style={{
                        width: 148,
                        height: 45,
                        background: '#0094D8',
                        borderRadius: 2,
                        border: '2px #0094D8 solid',
                        color: 'white',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontWeight: '900',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}><BackIcon style={{display: 'inline', rotate: '180deg'}}/>ZURÜCK</button>}
                    nextButton={<button style={{
                        width: 148,
                        height: 45,
                        background: '#0094D8',
                        borderRadius: 2,
                        border: '2px #0094D8 solid',
                        color: 'white',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontWeight: '900',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',

                    }}>WEITER<BackIcon style={{display: 'inline'}}/></button>}
                    lastStepNextButton={<button style={{
                        width: 148,
                        height: 45,
                        background: '#0094D8',
                        borderRadius: 2,
                        border: '2px #0094D8 solid',
                        color: 'white',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontWeight: '900',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}>ABSCHLIESSEN<BackIcon style={{display: 'inline'}}/></button>}
                    showNavigation={false}
                    accentColor={accent}
                    getCurrentStep={(curr) => {
                        postTutorialStep(accessToken, makeGeneralRequest, setIsLoading, '12a52b4f-56a0-4d80-b330-c282c7d6460f', curr + 1);
                    }}
                />
            }
        </>
    )
}
