import {apiUrl} from "../helpers";

export async function fetchAddresses(accessToken, makeAuthenticatedRequest, setState, setIsLoading, addressType) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        setIsLoading(true);
        const url = `${apiUrl}/addresses/${addressType}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchCorporateForms(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/legal-forms`;
        const customHeaders = {
            Accept: "application/json",
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}