import {apiUrl} from "../helpers";

export async function fetchQuestion(accessToken, makeAuthenticatedRequest, setState, setIsLoading,questionId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/questions/${questionId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };

        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}