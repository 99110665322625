import {useEffect, useState} from "react";
import {fetchOwnProcessingActivities, postOwnProcessingActivity} from "../../../utils/api/ownProcessingActivities";
import {useAuth} from "../../../hooks/useAuth";
import {GlobalFilter} from "./Table/GlobalFilter";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {COLUMNS} from "./Table/columns";
import SelectColumnFilter from "../OwnProcessingActivities/Table/Filter";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowUpBlue } from "../../../assets/icons/arrow-up-blue.svg";
import '../../../assets/css/table.css';
import '../../../assets/css/opa-table.css';
import {useNavigate} from "react-router-dom";

export default function Overview() {
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const { makeAuthenticatedRequest, makeGeneralRequest, accessToken } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(accessToken) {
            if(activities.length === 0) {
                fetchOwnProcessingActivities(accessToken, makeAuthenticatedRequest, setActivities, setIsLoading)
            }
        }
    }, [makeAuthenticatedRequest, postOwnProcessingActivity]);


    const listActivities = Array.from(Object.values(activities))?.map(activity => (
        <div>
            {activity.id}: {activity.title}
        </div>
    ))

    function createOwnProcessingActivity() {
        postOwnProcessingActivity(accessToken, makeGeneralRequest, setIsWaiting).then((response) => {
            navigate(response)
        });
    }

    const defaultSort = [{ id: 'lastChanged', desc: true }];
    const tableInstance = useTable({
        columns: COLUMNS,
        data: activities,
        defaultColumn:{ Filter: SelectColumnFilter },
        sortDescFirst:true,
        enableSortingRemoval:true,
        initialState: {
            sortBy: [
                {
                    id: 'lastChanged',
                    desc: true, // sort by name in descending order by default
                },
            ],
        },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,state,setGlobalFilter} = tableInstance
    const {globalFilter} = state



    return (
        <div className="w-[100%] lg:flex-row">
            <div className="flex flex-col md:flex-none md:flow-root md:items-center">
                <h1 className="text-2xl font-bold text-ere-content-blue outlet-title self-start float-left mb-[16px]">Eigene
                    Verarbeitungstätigkeiten</h1>

            </div>
            <p className="xl:text-[16px] mb-[24px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Sie haben an dieser Stelle die Möglichkeit, eigenständig Verarbeitungstätigkeiten zu erstellen und zu
                jedem Zeitpunkt anzupassen. Für die vollständige Erstellung der Verarbeitungstätigkeit nach den
                gesetzlichen Anforderungen, müssen Sie alle Fragen beantworten. Darüber hinaus werden für Sie Aufgaben
                generiert, um für Sie die benötigten To-Do's in Bezug auf die Verarbeitungstätigkeit festzuhalten. Diese
                Aufgaben sollten Sie erledigen, um die Datenschutzkonformität in Bezug auf die konkrete
                Verarbeitungstätigkeit zu gewährleisten.
            </p>
            {isWaiting ?
                <button
                    className="h-[45px] w-[355px] btn btn-secondary hover:btn-secondary-active-and-hover text-[14px] uppercase mt-auto font-black py-[8px] px-[12px] mb-[24px] m-auto">
                    ÖFFNE VERARBEITUNGSTÄTIGKEIT...
                </button>
                :
                <button id="erecht24-create-own-processing-activity"
                        className="h-[45px] w-[355px] btn btn-secondary hover:btn-secondary-active-and-hover text-[14px] uppercase mt-auto font-black py-[8px] px-[12px] mb-[24px]"
                        onClick={() => {
                            createOwnProcessingActivity()
                        }}>
                    VERARBEITUNGSTÄTIGKEIT HINZUFÜGEN +
                </button>
            }

            {listActivities.length !== 0 ?
                <div className="h-fit md:w-full w-full">
                    <div className="w-[100%] lg:flex-row overflow-x-scroll md:overflow-x-visible">
                        <div className="filter-wrapper flex items-center md:justify-end mb-[24px] md:mt-[-59px]">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        </div>
                        <table {...getTableProps()} state={state} defaultSort={defaultSort}
                               className="w-full light-blue-border text-left text-sm mb-[24px] tasks-table-border opa-table ">
                            <thead className="light-blue-border uppercase h-[54px] text-ere-content-blue">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th className="px-[24px] bg-ere-background-grey h-[65px] " {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            <div className="flex-col inline-flex align-middle pr-[8px]">
                                                {column.render('Header')}
                                            </div>
                                            <div className="h-fit inline-block align-middle">
                                                {column.isSorted ?
                                                    (!column.isSortedDesc ?
                                                            <div className="flex flex-col">
                                                                <ArrowUp className="sort-icon inline ml-1 mb-1"/>
                                                                <ArrowUpBlue
                                                                    className="sort-icon rotate-180 inline ml-1 "/>
                                                            </div>
                                                            :
                                                            <div className="flex flex-col">
                                                                <ArrowUpBlue className="sort-icon inline ml-1 mb-1"/>
                                                                <ArrowUp className="sort-icon rotate-180 inline ml-1 "/>
                                                            </div>
                                                    )
                                                    :
                                                    <div className="arrows-container flex flex-col">
                                                        <ArrowUp className="sort-icon inline ml-1 mb-1"/>
                                                        <ArrowUp className="sort-icon rotate-180 inline ml-1 "/>
                                                    </div>
                                                }

                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="font-[400] text-ere-grey-alternative h-[53px] bg-white" {...row.getRowProps()} >
                                        {row.cells.map((cell) => {
                                            if (cell.column.Header === "Tätigkeit") {
                                                return (
                                                    <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                        <span className="">{cell.render('Cell')}</span>
                                                    </td>
                                                )
                                            }/*else if (cell.column.Header === "Letzte Änderung") {
                                                    if (cell.render('Cell').props.cell.value === null) {
                                                        return <td className="border-y border-ere-border-grey px-[24px]">-</td>
                                                    } else {
                                                        const lastChanged = new Date(cell.render('Cell').props.cell.value)
                                                        return (
                                                            <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                                {('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'}
                                                            </td>
                                                        )
                                                    }
                                                }*/ else if (cell.column.Header === "Kategorie") {
                                                if (!cell.render('Cell').props.cell.value) {
                                                    return <td
                                                        className="border-y border-ere-border-grey px-[24px]">-</td>
                                                } else {
                                                    return (
                                                        <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                            <span className="">{cell.render('Cell')}</span>
                                                        </td>
                                                    )
                                                }
                                            } else if (cell.column.Header === "") {
                                                return (
                                                    <td className="whitespace-nowrap pr-[24px] h-[56px] text-right flex items-center justify-end min-w-[170px]  border-y" {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td className="border-y border-ere-border-grey px-[24px]" {...cell.getCellProps()}>
                                                        <span className="">{cell.render('Cell')}</span>
                                                    </td>
                                                )
                                            }
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                ""
            }
        </div>
    )
}