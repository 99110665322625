import {useState} from "react";
import {AuthContext} from "./AuthContext";
import {apiUrl, getCookieContentByName, setCookie, setAccessToken, setLoggedInUser} from "../utils/helpers";
import {useErrorBoundary} from "react-error-boundary";
import {redirect, useNavigate} from "react-router-dom";

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [user,setUser] = useState(null);
    const [needAccessToken, setNeedAccessToken ] = useState(true);
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const fetchAccessToken = async () => {

        let envParamsForFetch = '';


        if (process.env.REACT_APP_STAGE === 'local') {

            /*
                We can not transfer httpOnly-cookies from localhost to other domains
                As a workaround, we set the jwt-cookie with JavaScript with a valid user in dev-system
                Backend checks this with http-header Stage, only in development (local)
             */

            if (process.env.REACT_APP_DEFAULT_JWT === '') {
                throw new Error ("REACT_APP_DEFAULT_JWT is empty, jwt is needed for setting the cookie");
            }

            if (process.env.REACT_APP_COOKIE_NAME === '') {
                throw new Error ("REACT_APP_COOKIE_NAME is empty, cookie name is needed for setting the cookie");
            }

            // set the default cookie in development
            if (getCookieContentByName(process.env.REACT_APP_COOKIE_NAME) == '') {
                setCookie(process.env.REACT_APP_COOKIE_NAME, process.env.REACT_APP_DEFAULT_JWT, 30);
            }

            envParamsForFetch =  {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getCookieContentByName(process.env.REACT_APP_COOKIE_NAME)}`,
                    Stage: 'local'
                },
            }

        } else if (process.env.REACT_APP_STAGE === 'shadow') {

            envParamsForFetch = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json'
                }
            }

        } else {
            throw new Error ('Environment not set');
        }

        if (needAccessToken === true) {
            const response = await fetch (`${apiUrl}/login24`, envParamsForFetch);

            if (response.ok) {
                const data = await response.json();
                setAccessToken(data.success.token);
                setLoggedInUser(data.success.user);
                setUser(data.success.user);
                setNeedAccessToken(false);
            } else {
                navigate("/no-session");
            }
        }
    };

    //   authenticated requests
    const makeAuthenticatedRequest = async (url, additionalHeaders = {}, returnRawResponse) => {

        let attemptCount = 0;
        const maxAttempts = 2;

        const defaultHeaders = {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json'
        };
        while (attemptCount < maxAttempts) {

            const headers = { ...defaultHeaders, ...additionalHeaders };

            let response = await fetch(url, { headers });
            attemptCount++;

            if (response.status === 401 && attemptCount === 0 ) {
                setNeedAccessToken(true);
                await fetchAccessToken();
                response = await fetch(url, { headers: { ...headers, 'Authorization': `Bearer ${accessToken}` } });
            } else {

                if (returnRawResponse) {
                    return response;
                } else {
                    return response.json();
                }

            }
        }
        throw new Error('Unable to authenticate after retrying.');
    };


    const makeGeneralRequest = async (
        url,
        additionalHeaders = {},
        method,
        body = null
    ) => {
        let attemptCount = 0;
        const maxAttempts = 2;

        const defaultHeaders = {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        while (attemptCount < maxAttempts) {
            let requestOptions = {
                method: method,
                headers: { ...defaultHeaders, ...additionalHeaders },
            };

            if (body && (method === "POST" || method === "PUT" || method === "DELETE")) {
                requestOptions.body = body;
            }

            let response = await fetch(url, requestOptions);
            attemptCount++;

            if (response.status === 401 && attemptCount <= 1) {
                setNeedAccessToken(true);
                await fetchAccessToken();
                requestOptions.headers["Authorization"] = `Bearer ${accessToken}`;
                response = await fetch(url, requestOptions);
            } else if (response.ok) {
                return response.json();
            }
        }

        throw new Error("Unable to authenticate after retrying.");
    };

    const contextValue = {
        accessToken,
        needAccessToken,
        user,
        fetchAccessToken,
        makeAuthenticatedRequest,
        makeGeneralRequest
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};