import {useEffect, useState} from "react";
import { ReactComponent as YesIcon } from "../../../assets/icons/yes-icon.svg";
import { ReactComponent as NoIcon } from "../../../assets/icons/no-icon.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-icon.svg";
import { ReactComponent as CheckIconWhite } from "../../../assets/icons/checkiconwhite.svg";
import Preloader from "../../General/Preloader";
import Popup from "./Hints/Popup";


export default function YesNo({questionAnswers, questionAnswerLayouts, whenSelected, prefilled, setWarning, setIsAnswerGiven}) {
    const [answers, setAnswers] = useState([]);
    const [answerLayout, setAnswerLayout] = useState("default");
    const [isWaiting, setIsWaiting] = useState(true);
    const [answerClassParameters, setAnswerClassParameters]  = useState("md:flex grid grid-cols-2 md:flex-wrap justify-center gap-[20px] self-center answers-wrapper md:w-full mb-[15px] h-auto");

    useEffect(() => {
        setAnswers(questionAnswers);
        setAnswerLayout(questionAnswerLayouts);
        changeAnswerDisplay();
    },[questionAnswers, prefilled, questionAnswerLayouts, answerClassParameters])

    useEffect(() => {
        if (prefilled) {
            setIsAnswerGiven(true);
        }
        document.ert_shadow.querySelectorAll('[name="yesno"]').forEach((radio) => {
            if (prefilled && prefilled === radio.value) {
                radio.checked = true;
                radio.classList.add("checked");
                setWarning(radio.value);
            }
        })
    }, [prefilled, document.ert_shadow.querySelectorAll('[name="yesno"]').length, questionAnswers]);

    function changeAnswerDisplay() {
        setAnswerLayout(questionAnswerLayouts);
        if(answerLayout === "one_column") {
            setAnswerClassParameters("grid gap-[20px] mb-[15px] one_column_layout");
        } else if (answerLayout === "two_columns") {
            setAnswerClassParameters("grid grid-cols-1  md:grid-cols-2 gap-[20px] mb-[15px] two_column_layout");
        } else {
            setAnswerClassParameters("md:flex grid grid-cols-1 md:flex-wrap justify-center gap-[20px] self-center answers-wrapper md:w-full mb-[15px] h-auto box_layout_yes_no");
        }
        setIsWaiting(false);
    }

    function labelChecked(event) {
        if(event.target.checked ) {
            document.ert_shadow.querySelectorAll('[name="yesno"]').forEach((radio) => {
                if(radio.value === event.target.value) {
                    radio.classList.add("checked");
                } else {
                    radio.classList.remove("checked");
                }
            })
        }
    }

    const listAnswers = answers?.map((answer) => (
        <label id={`erecht24-yesno-${answer.id}`} key={answer.id} className={`w-auto h-auto relative cursor-pointer ${answer.tooltip ? "" : ""}`}>
            <input type="radio" className="peer sr-only" name="yesno" value={answer.id} onChange={
                (e) => {
                    whenSelected(answer, e);
                    labelChecked(e);
                }
            }/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px] z-10  peer-checked:animate-appearanswer">
                <CheckIcon/>
            </span>
            <div className={`peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 min-w-[120px] xs:min-w-[160px] s:min-w-[170px] sm:w-[196px] h-[127px] items-center text-center bg-white p-[10px] cursor-pointer transition-all active:scale-95 flex justify-center overflow-visible light-blue-border-category ${answer.tooltip ? "" : ""}`}>
                { answer.tooltip ?
                    <Popup popupAnswer={answer.tooltip} toLeftMobile />
                :
                    <></>
                }
                <div>
                    { answer.icon ?
                        <img src={answer.icon} alt={'icon'} className="w-[40px] h-[40px] mb-[8px]" />
                    :
                        <div className="text-[40px]">
                            {answer.title === "yes" ? <YesIcon /> : <NoIcon />}
                        </div>
                    }
                    <header className="flex flex-col justify-center items-center">
                        <p className="text-base mt-[5px]">{answer.title === "yes" ? "Ja" : "Nein"}</p>
                    </header>
                </div>
            </div>
        </label>
    ))

    const listAnswersCompressed = answers?.map((answer) => (
        <label id={`erecht24-yesno-${answer.id}`} key={answer.id} className={`w-auto h-auto relative cursor-pointer ${answer.tooltip ? "" : ""}`}>
            <input type="radio" className="peer sr-only" name="yesno" value={answer.id} onChange={
                (e) => {
                    whenSelected(answer, e);
                    labelChecked(e);
                }
            }/>
            <span className="absolute top-[8px] left-[17px] opacity-0 transition-all peer-checked:opacity-100 z-10 peer-checked:animate-appearanswer">
                <CheckIconWhite/>
            </span>
            <div className={`peer-checked:border-ere-checkbox-blue peer-checked:border-2 peer-checked:border-opacity-100 peer-checked:bg-ere-checkbox-blue peer-checked:text-white peer-checked:pl-3 bg-white p-[12px] px-4 relative cursor-pointer transition-all active:scale-95 flex overflow-visible light-blue-border-category ${answer.tooltip ? "has-tooltip" : ""}`}>
                <div className={`${prefilled && answer.id === prefilled.split(",").find((id) => id === answer.id) ? "ring-ere-checkbox-blue" : "ring-ere-link-blue"}`}>
                    <header className="flex flex-col">
                        {answer.tooltip ?
                            <Popup popupAnswer={answer.tooltip} toLeftMobile />
                        :
                            <></>
                        }
                        <div>
                            <header className="flex flex-col justify-center items-center">
                                <p className="text-base">{answer.title === "yes" ? "Ja" : "Nein"}</p>
                            </header>
                        </div>
                    </header>
                </div>
            </div>
        </label>
    ))

    return (
        <div className={answerClassParameters}>
            { isWaiting ?
                <Preloader/>
            :
                <>
                    {answerLayout === "default" && !isWaiting ?
                        listAnswers
                    :
                        listAnswersCompressed
                    }
                </>
            }
        </div>
    );
}