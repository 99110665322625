import {accessToken, apiUrl} from "../helpers";

export async function fetchServiceProviders(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/service-providers`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postCustomServiceProvider(accessToken, makeGeneralRequest, setIsLoading, formInformation) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/service-providers`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`
        };
        const body = JSON.stringify({
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            eu: formInformation.eu,
            provider_category_id: formInformation.provider_category_id,
            triggered_question_id: formInformation.triggered_question_id
        });
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function updateCustomServiceProvider(accessToken, makeGeneralRequest, setIsLoading, formInformation, serviceProviderId) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/service-providers/${serviceProviderId}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`
        };
        // the request body
        const body = JSON.stringify({
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            eu: formInformation.eu,
            provider_category_id: formInformation.provider_category_id
        });
        // authenticated request
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function deleteCustomServiceProvider(accessToken, makeGeneralRequest, setIsLoading, serviceProviderId) {

    if (!accessToken) {
        setIsLoading(false);
        return;
    }

    try {
        const url = `${apiUrl}/service-providers/${serviceProviderId}`;

        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`
        };

        const response = await makeGeneralRequest(url, customHeaders, "DELETE");
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}