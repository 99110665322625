import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";
import DeleteProvider from "../CustomServiceProvider/DeleteProvider";


export default function ListCustom({questionCustomServiceProviders, serviceProviderCategoryId, whenSubmitClicked, whenClicked, displayCreateCustom}) {
    const listCustomServiceProviders = questionCustomServiceProviders?.filter(function(provider) {
        if(provider.scope === "custom" && provider.provider_category_id == serviceProviderCategoryId) {
            return provider;
        }}).map((provider) => (
        <p key={provider.id} className="text-ere-grey-alternative mb-2 text-base">
            {provider.name}, {provider.street} {provider.housenumber}, {provider.zipcode} {provider.city}, {provider.country_name}
            <button type="button" onClick={e => whenClicked(e, provider)}><EditIcon className="ml-2" /></button>
            <button type="button"><DeleteProvider whenSubmitClicked={whenSubmitClicked} providerId={provider.id}  /></button>
        </p>
    ))

    return(
        <div className="mt-[40px]">
            {listCustomServiceProviders ? listCustomServiceProviders : ""}
            {displayCreateCustom ?
                <button type="button" onClick={e => whenClicked(e)}>
                    <h3 className="ml-[1%] w-full text-sm text-ere-link-blue font-bold uppercase mt-4">Weitere Dienstleister hinzufügen <PlusIcon className="w-[22px] h-auto ml-1 inline mb-[4px]" /></h3>
                </button>
            :
                <></>
            }
        </div>
    );
}