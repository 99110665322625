export default function AttentionIcon( {riskvalue = 0, isDashboard}) {

    let fillColor = '';
    let riskLevel = '';
    if (riskvalue > 66) {
        fillColor = 'bg-ere-orange';
        riskLevel = 'Hohes Risiko';
    } else if (riskvalue > 33) {
        fillColor = 'bg-ere-yellow';
        riskLevel = 'Mittleres Risiko';
    } else {
        fillColor = 'bg-ere-green';
        riskLevel = 'Niedriges Risiko';
    }
    return (
        <>
            <div className={`w-[12px] h-[12px] rounded-[50%] mr-[8px] inline-block ${fillColor}`}></div>
            {
                isDashboard ? "" : <span>{riskLevel}</span>
            }


        </>


    );
}