import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {fetchClients, loginToClient} from "../../utils/api/clients";
import {ReactComponent as BackIcon} from "../../assets/icons/back-icon.svg";
import {useAuth} from "../../hooks/useAuth";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {setInQuestionCatalog} from "../../utils/helpers";
import {fetchToken} from "../../utils/api/token";

export default function ShowClientDetail() {
    const location = useLocation();
    const [client, setClient] = useState([]);
    const [clients, setClients] = useState([]);
    const currentClient = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const [isLoading, setIsLoading] = useState(true);
    const { makeGeneralRequest, accessToken, makeAuthenticatedRequest } = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        setSelectedMenuPoint("Meine Mandanten", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        fetchClients(accessToken, makeAuthenticatedRequest, setClients, setIsLoading).then(()=> {
            if(clients.length !== 0) {
                setClient(clients.find((element) => element.id == currentClient))
            }
        })
    },[clients.length, makeAuthenticatedRequest]);

    const changeClient = (event, clientId) => {
        event.preventDefault();
        loginToClient(accessToken, makeGeneralRequest, setIsLoading, client, clientId).then(() => {
            navigate('/');
        });
        fetchToken();
        localStorage.clear();
    }

    return(
        <>
            {client.length !== 0 ?
                   <div className="flex flex-col text-ere-content-blue w-full">
                   <h1 className="text-xl mb-3">Mandant: {client.company}</h1>
                    <table className="min-w-full text-left text-sm font-light overflow-y-auto">
                        <tbody>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Name der Firma</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.company : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Vorname</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.firstname : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Nachname</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.lastname : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Straße</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.street : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Hausnummer</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.housenumber : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">PLZ</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.zipcode : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Ort</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.city : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">Land</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.country : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                {/*Hier Telefonnummer der Kontaktperson?*/}
                                <td className=" px-6 py-4">Telefon</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.telephone : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                {/*Hier Telefonnummer der Kontaktperson?*/}
                                <td className=" px-6 py-4">Mobil</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.mobile : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                {/*Benötigen wir hier die Faxnummer der Kontaktperson?*/}
                                <td className=" px-6 py-4">Fax</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.fax : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className=" px-6 py-4">E-Mail</td>
                                <td className=" px-6 py-4">{client.organisation_address !== null ? client.organisation_address.email : '-'}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                            <td className=" px-6 py-4">Projekt erstellt am</td>
                                <td className=" px-6 py-4">{client.created_at}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <br />
                    <br />

                    <Link to={-1} className="md:w-fit">
                        <button id={`erecht24-change-client-${client.id}`} className="w-full h-full float-right btn hover:btn-secondary btn-secondary-active-and-hover flex items-center justify-center " onClick={(event) => changeClient(event, client.id)}>
                            <BackIcon  className="rotate-180"/>
                            Zum Mandant wechseln
                        </button>
                    </Link>
                </div>
                :
                <></>
            }
        </>
    );
}