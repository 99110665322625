import Overview from "../../components/Content/Client/Overview";
import {useEffect, useState} from "react";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {setInQuestionCatalog} from "../../utils/helpers";
import {useLocation} from "react-router-dom";
import {fetchErtProjects} from "../../utils/api/clients";
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../../components/General/Preloader";

//note from Danijela: here I have changed projects -> clients, of course api has to be coded now.

export default function ShowList() {
    const location = useLocation();
    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken} = useAuth();

    useEffect(() => {
        setSelectedMenuPoint("Meine Mandanten", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        if(accessToken) {
            if(clients.length === 0) {
                fetchErtProjects(accessToken, makeAuthenticatedRequest, setClients, setIsLoading);
            }
        }
    },[makeAuthenticatedRequest])

    return <>
        <h1 className="text-2xl font-bold text-ere-content-blue outlet-title self-start mt-[16px] mb-[16px]">Meine Mandanten</h1>
        <div className="m-0 flex flex-col risk-card md:w-full h-fit lg:bg-white  md:p-0 md:flex-row xl:justify-between w-full">
            <div className="w-[100%] lg:flex-row">
                <p className="xl:text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                    Fügen Sie Ihr Projekt aus dem eRecht24 Premium Projekt Manager hinzu, um schnell und einfach Rechtstexte wie Impressum,
                    Datenschutzerklärung und Cookie-Einwilligung zu erzeugen und somit wichtige Aufgaben erleden zu können.
                </p>

            </div>
        </div>
        {isLoading ?
            <Preloader/>
        :
            <>
                {clients.length === 0 ?
                    <p className="text-ere-grey-alternative">Es sind keine Mandanten hinterlegt</p>
                :
                    <Overview ertProjects={clients}/>
                }
            </>
        }

    </>
}