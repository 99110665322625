import {apiUrl} from "../helpers";

export async function fetchTutorials(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tutorials`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchTutorialsWithSteps(accessToken, makeAuthenticatedRequest, setState, setIsLoading) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tutorials/currentsteps`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function fetchTutorial(accessToken, makeAuthenticatedRequest, setState, setIsLoading, tutorialId) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tutorials/${tutorialId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const data = await makeAuthenticatedRequest(url, customHeaders);
        setState(data);
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}

export async function postTutorialStep(accessToken, makeGeneralRequest, setIsLoading, tutorialId, currentStep) {
    if (!accessToken) {
        setIsLoading(false);
        return;
    }
    try {
        const url = `${apiUrl}/tutorials/savestep/${tutorialId}`;
        const customHeaders = {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}` // bearer token
        };
        const body = JSON.stringify({
            step: currentStep
        });
        const response = await makeGeneralRequest(url, customHeaders, 'POST', body);
        return response;
    } catch (error) {
        console.log(error.message);
    } finally {
        setIsLoading(false);
    }
}