import Overview from "../../components/Content/Contract/Overview";

export default function ShowList() {
    return(
        <>
            <h1 className="text-2xl font-bold mb-[16px] text-ere-content-blue outlet-title self-start mt-[16px]">Meine Dokumente</h1>
            <p className="xl:text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Erstellen und verwalten Sie hier dauerhaft alle datenschutzrelevanten Dokumente und Verträge für Ihr Unternehmen, wie den Bericht für die Verarbeitungstätigkeiten und TOMs (Technische und organisatorische Maßnahmen) und Auftragsverarbeitungsverträge (AV-Verträge). Bereits vorhandene Dokumente können Sie an dieser Stelle hochladen und dauerhaft hier speichern.
            </p>
            <Overview/>
        </>
    );
}