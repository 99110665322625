export default function FormCustom({whenSaved, whenClosed, prefilled, hasEdit, countries}) {
    const isPrefilledEu = (eu) => {
        return eu == 1;
    };
    const isPrefilledThirdCountry = (eu) => {
        return eu == 0;
    };

    const selectCountries = Object.keys(countries).map((country) => (
        <>
            {prefilled && prefilled.country === country ?
                <option value={country} selected>{countries[country]}</option>
                :
                <option value={country}>{countries[country]}</option>
            }
        </>
    ))

    return(
        <>
            <div>
                <button type="button" className="blockv w-[33px] float-right btn-secondary hover:btn-secondary-active-and-hover hover:!px-[4px] w-[30px] p-[4px]" onClick={() => whenClosed()}>
                    <p className="block uppercase tracking-wide font-bold text-sm">x</p>
                </button>
            </div>
            <div className="mb-[20px] text-base">
                {hasEdit ?
                    <p className="block tracking-wide text-ere-content-blue text-sm  mb-2 ">Bearbeitung von "{prefilled.company}"</p>
                :
                    <></>
                }

                <div id="custom-service-provider-form"  className="flex  ">
                    <div className="w-full">
                        <label className="block tracking-wide text-ere-content-blue mb-[9px]" htmlFor="grid-password">Firmenname *</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue mb-[25px] px-4 leading-tight focus:outline-none h-[47px] rounded-[2px]"
                               name="company" type="text" defaultValue={prefilled ? prefilled.company : ''} required
                               onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                               onInput={e => e.target.setCustomValidity('')}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap md:grid md:grid-cols-2 ">
                    <div className="w-full md:max-w-1/2 md:w-auto ">
                        <label className="block tracking-wide text-ere-content-blue  mb-[9px]" htmlFor="grid-first-name">Straße *</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue mb-[25px] px-4 leading-tight focus:outline-none h-[47px] rounded-[2px]"
                               name="street" type="text" defaultValue={prefilled ? prefilled.street : ''} required
                               onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                               onInput={e => e.target.setCustomValidity('')}
                        />
                    </div>
                    <div className="w-full md:max-w-1/2 md:w-auto md:ml-[21px]">
                        <label className="block tracking-wide text-ere-content-blue mb-[9px]" htmlFor="grid-last-name">Hausnummer *</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue mb-[25px] px-4 leading-tight focus:outline-none h-[47px] rounded-[2px]"
                               name="housenumber" type="text" defaultValue={prefilled ? prefilled.housenumber : ''} required
                               onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                               onInput={e => e.target.setCustomValidity('')}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap md:grid md:grid-cols-2 ">
                    <div className="w-full md:max-w-1/2 md:w-auto ">
                        <label className="block tracking-wide text-ere-content-blue mb-[9px]" htmlFor="grid-first-name">Postleitzahl *</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue mb-[25px] px-4 leading-tight focus:outline-none h-[47px] rounded-[2px]"
                               name="zipcode" type="text" defaultValue={prefilled ? prefilled.zipcode : ''} required
                               onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                               onInput={e => e.target.setCustomValidity('')}
                        />
                    </div>
                    <div className="w-full md:max-w-1/2 md:w-auto md:ml-[21px]">
                        <label className="block tracking-wide text-ere-content-blue mb-[9px]" htmlFor="grid-last-name">Ort *</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue mb-[25px] px-4 leading-tight focus:outline-none h-[47px] rounded-[2px]"
                               name="city" type="text" defaultValue={prefilled ? prefilled.city : ''} required
                               onInvalid={e => e.target.setCustomValidity('Bitte füllen Sie dieses Feld aus.')}
                               onInput={e => e.target.setCustomValidity('')}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6">
                    <div className="w-full md:w-6/12">
                        <label className="block tracking-wide text-ere-content-blue  mb-[9px]"
                               htmlFor="grid-first-name">Land *</label>
                        <select className="text-ere-content-blue block w-full border border-ere-checkbox-blue px-4 leading-tight focus:outline-none selectcountry h-[47px] mb-[25px]" name="country" required
                                onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie ein Element in der Liste aus.')}
                                onInput={e => e.target.setCustomValidity('')}
                        >
                            <option value="">Bitte auswählen</option>
                            <hr/>
                            <option value="DE">Deutschland</option>
                            <option value="AT">Österreich</option>
                            <option value="CH">Schweiz</option>
                            <hr/>
                            {selectCountries}
                        </select>
                    </div>
                    <div className="w-full md:max-w-6/12 md:w-auto md:ml-[21px]">
                        <label className="block tracking-wide text-ere-content-blue mb-[9px]" htmlFor="grid-last-name">Sitz
                            des Unternehmens *</label>
                        <label className="flex text-ere-grey-alternative items-center mb-[2px]">
                            <input style={{accentColor: '#056688'}} className="w-4 h-4 bg-ere-checkbox-blue border-ere-checkbox-blue focus:ring-ere-link-blue" name="eu" type="radio" value="1"  defaultChecked={prefilled ? isPrefilledEu(prefilled.eu) : false} required
                                   onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie eine dieser Optionen aus.')}
                                   onInput={e => e.target.setCustomValidity('')}
                            />
                            <span className="ml-2">Europäische Union</span>
                        </label>
                        <label className="flex text-ere-grey-alternative items-center">
                            <input style={{accentColor: '#056688'}} className="w-4 h-4 bg-ere-checkbox-blue border-ere-checkbox-blue focus:ring-ere-link-blue" name="eu" type="radio" value="0" defaultChecked={prefilled ? isPrefilledThirdCountry(prefilled.eu) : false}  required
                                   onInvalid={e => e.target.setCustomValidity('Bitte wählen Sie eine dieser Optionen aus.')}
                                   onInput={e => e.target.setCustomValidity('')}
                            />
                            <span className="ml-2 ">Drittstaat</span>
                        </label>
                    </div>
                </div>
                { !hasEdit ?
                    <div className="w-full flex justify-center">
                    <button type="submit" className="place-self-center btn-secondary hover:btn-secondary-active-and-hover px-10 hover:px-10 h-[36px] hover:h-[36px]" onClick={() => whenSaved("submit_custom_service_provider")}>
                        <p className="block uppercase tracking-[0.233px] font-bold text-sm">Dienstleister hinzufügen</p>
                    </button>
                    </div>
                :
                    <div className="w-full flex justify-center">
                    <button type="submit" className="place-self-center btn-secondary  hover:btn-secondary-active-and-hover px-10 hover:px-10 h-[36px] hover:h-[36px]" onClick={() => whenSaved("edit_custom_service_provider", prefilled.id)}>
                        <p className="block uppercase tracking-[0.233px] font-bold text-sm">Dienstleister hinzufügen</p>
                    </button>
                    </div>
                }
            </div>
        </>
    );
}