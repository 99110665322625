import {useEffect, useState, Fragment} from "react";
import Preloader from "../../General/Preloader";
import {useAuth} from "../../../hooks/useAuth";
import '../../../assets/css/table.css';
import SkippedQuestionsTable from "./SkippedQuestionsTable";
import {fetchSkippedQuestions} from "../../../utils/api/answer";

export default function Overview() {
    const [skippedQuestions, setSkippedQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken} = useAuth();

    useEffect(()=> {
        if(accessToken) {
            if(skippedQuestions.length === 0) {
                fetchSkippedQuestions(accessToken, makeAuthenticatedRequest, setIsLoading, setSkippedQuestions).then(() => {
                    setIsLoading(false);
                });
            }
        }
    },[makeAuthenticatedRequest]);

    if (isLoading) {
        return <Preloader/>;
    }

    return(
        <div className={`h-fit md:w-full w-full`}>
            <div className="w-[100%] lg:flex-row">
                <ul className="list-disc text-ere-grey-alternative w-full text-sm overflow-x-scroll md:overflow-x-visible">
                    { skippedQuestions.length !== 0 ?
                        <SkippedQuestionsTable skippedQuestions={Array.from(Object.values(skippedQuestions))}/>
                    :
                        <>Es existieren derzeit keine übersprungenen Fragen.</>
                    }
                </ul>
            </div>
        </div>
    );
}