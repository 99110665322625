import {loggedInUser} from "../../utils/helpers";


export default function Greeting() {
    return (
       <div className="mb-[40px]">
           <h1 className="text-ere-content-blue font-bold text-2xl mb-[16px] mt-[23px]">Willkommen zurück{loggedInUser ? `, ${loggedInUser.firstname} ${loggedInUser.lastname}!` : ''}</h1>
           <p className="text-base text-ere-grey-alternative leading-[normal]">Hier finden Sie den aktuellen Stand und Datenschutzstatus Ihres Unternehmens sowie die dringendsten Aufgaben zur Risikominimierung. Zudem können Sie jederzeit den Fortschritt Ihrer Arbeit verfolgen sowie die entsprechende Risikobewertung.​</p>
       </div>
    );
}