import {useEffect, useState} from "react";
import * as React from 'react';
import {fetchCategories} from "../../utils/api/categories";
import {checkForChildren, globalQuestionProgress, setInQuestionCatalog} from "../../utils/helpers";
import Box from "../../components/Content/Category/Box";
import Overview from "../../components/Content/OwnProcessingActivities/Overview"
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../../components/General/Preloader";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Card from "../../components/Content/Risk/Card.jsx";
import {ReactComponent as PlusIcon } from "../../assets/icons/plus-icon.svg";
import {ReactComponent as EscapeButton} from "../../assets/icons/escape.svg";
import {ReactComponent as CheckIcon} from "../../../src/assets/icons/check.svg";
import {fetchSkippedQuestions} from "../../utils/api/answer";
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from 'react-confetti';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container':{
        background: 'rgba(202, 235, 255, 0.40)',
        backdropFilter: 'blur(3px)',
        zIndex:9001,
        '@media (min-width: 320px)': {
            width:'100%',
        },
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },

    '& .MuiPaper-root':{
        minWidth:'120px',
        '@media (min-width: 1024px)': {
            minWidth:'800px',
        },
        border: 'solid 1px #0094D8',
        borderRadius:'0',
        padding:'20px',
        paddingTop:'30px',
        boxShadow:'none',
        maxWidth:'300px',
        '@media (min-width: 380px)': {
            maxWidth:'800px',
            padding:'40px',
        },
        margin:0,
        '@media (min-width: 340px)': {
            marginLeft:'unset',
            margin:'35px',
        },
    },
    '& .MuiPaper-root h2':{
        fontSize:'20px !important',
        color:'#004059',
        marginBottom:'24px',
        textAlign:'center',
        fontWeight:'700 !important'
    },
    '& .MuiPaper-root p':{
        fontSize:'16px !important',
        color:'#444444',
        marginBottom:'40px',
        textAlign:'center',
        fontWeight:'400 !important'
    },
    '& .MuiPaper-root .btn':{
        minHeight:'47px',
        height:'fit-content',
        fontSize:'14px',
        color:'white',
        pointer:'cursor !important',
        fontWeight:'900',
        borderRadius:'2px',
        border:'none'
    },
    '& .MuiPaper-root .save-btn':{
        backgroundColor:'#0094D8',
        pointer:'cursor !important',
        padding:'14px 50px !important',
        marginLeft:'auto',
        marginRight:'auto',
        textTransform:'uppercase',


    },
    '& .MuiPaper-root .save-btn:hover':{
        backgroundColor:'white !important',
        color:'#0094D8 !important',
        border: '2px solid #0094D8',
        pointer:'cursor !important',
        padding:'14px 50px !important',
    },
    '& .MuiPaper-root .check-icon':{
        marginLeft:'auto !important',
        marginRight:'auto !important',
        marginBottom:'20px',
        '@media (min-width: 768px)': {
            width:'70px',
            height:'70px',
        },
        '@media (min-width: 1024px)': {
            width:'127px',
            height:'127px',
            marginBottom:'40px',
        },
    },
    '&. MuiDialogActions-root':{
        justifyContent: 'center !important'
    },
    '&. MuiDialogActions-root .save-btn:hover':{
        backgroundColor:'#999',
        pointer:'cursor !important',
        border: '2px solid #0094D8 !important',
    },
    '&. MuiDialogActions-root button': {
        backgroundColor:'#0094D8 !important',
        pointer:'cursor !important',
        padding:'14px 50px !important',
        marginLeft:'auto !important',
        marginRight:'auto !important',
        textTransform:'uppercase !important'
    },
    '&. MuiDialogActions-root button:hover':{
        backgroundColor:'white !important',
        color:'#0094D8 !important',
        border: '2px solid #0094D8 !important',
        pointer:'cursor !important',
        padding:'14px 50px !important'
},

}));

export default function Category() {
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [skippedQuestionCategories, setSkippedQuestionCategories] = useState([]);
    const [modalOpen, setModalOpen] = React.useState(true);
    const { makeAuthenticatedRequest,accessToken } = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        setSelectedMenuPoint("Mein Datenschutzstatus", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        if(accessToken) {
            if(categories.length === 0) {
                setTimeout(function() {
                    fetchCategories(accessToken, makeAuthenticatedRequest, setCategories, setIsLoading);
                },500)
            }
        }
        if(skippedQuestionCategories.length === 0) {
            fetchSkippedQuestions(accessToken, makeAuthenticatedRequest, setIsLoading, setSkippedQuestionCategories);
        }
    },[makeAuthenticatedRequest])

    if (isLoading) {
        return <Preloader/>;
    }
    const listCategories = Array.from(Object.values(categories))?.map(category => (
        <Box key={category.id} category={category} skippedQuestions={skippedQuestionCategories[category.id]} hasChildren={checkForChildren(category)}/>
    ))

    if(globalQuestionProgress && globalQuestionProgress.progress_value === 100 && listCategories.length !== 0) {
        listCategories.push(
            <NavLink to={"/contracts"} id="document_box" className="category_box category w-full flex-grow h-[140px] bg-white light-blue-border-category flex flex-col hover:shadow-lg hover:scale-105 transition-all duration-500 hover:bg-ere-category-hover focus:shadow-lg focus:scale-105 focus:bg-ere-category-hover group">
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <PlusIcon className="w-[36px] h-[36px] ml-1 inline mb-[4px] mr-1"/>
                    <h3 className="text-[14px] text-ere-link-blue font-bold text-left uppercase">Jetzt Dokument erstellen</h3>
                </div>
            </NavLink>
        )
    }

    const handleClose = () => {
        setModalOpen(false);
        localStorage.setItem('checkedHundredPercent', true);
    };


    const size = useWindowSize;
    return(
        <>
            <div className="w-[100%] lg:flex-row">
                <div className="flex flex-col md:flex-none md:flow-root md:items-center mt-[16px]">
                    <h1 className="text-2xl font-bold text-ere-content-blue outlet-title self-start float-left relative w-full mb-[24px]">Mein Datenschutzstatus</h1>
                    <Card/>
                </div>
            </div>
            <p className="xl:text-[16px] mb-[24px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                Hier können Sie den Datenschutzstatus Ihres Unternehmens sehen. Durchlaufen Sie die Fragerunden der verschiedenen Kategorien. Nach Beenden der Fragerunden aller Kategorien können Sie Ihr Verzeichnis für Verarbeitungstätigkeiten sowie die Liste der vorhandenen technischen und organisatorischen Maßnahmen unter dem Menüpunkt „Meine Dokumente“ generieren, um die Dokumente im Falle einer Prüfung vorlegen zu können.
            </p>
            <div className="w-full category-wrapper grid md:grid-cols-3 sm:grid-cols-2 xxl:grid-cols-4 flex-wrap gap-[18px] mb-[40px]">
                {listCategories.length !== 0 ? listCategories : <Preloader/>}
            </div>


            {globalQuestionProgress && globalQuestionProgress.progress_value === 100 && listCategories.length !== 0 && !localStorage.getItem('checkedHundredPercent')?
                <>

                <React.Fragment>

                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={modalOpen}
                        className="min-w-[400px]"
                    >

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],}}
                        >
                            <EscapeButton />
                        </IconButton>
                        <Confetti
                            width={2000}
                            height={size.height}
                            style={{zIndex:10000, position:'fixed'}}
                        />
                        <DialogContent>

                            <Typography gutterBottom>
                                <CheckIcon className="check-icon"/>
                                <h2 className="rte-field block text-base font-bold text-center text-ere-grey-alternative">Geschafft! Sie haben alle notwendigen Informationen hinterlegt!</h2>
                                <p className="rte-field block text-base text-center text-ere-grey-alternative">Im nächsten Schritt können Sie per Klick auf die Schaltfläche “Jetzt Dokumente erstellen” Ihre Unterlagen generieren.</p>
                            </Typography>
                        </DialogContent>
                        <DialogActions className="btns-container" style={{justifyContent:'center !important'}} >
                            <Button class="btn save-btn" disableRipple autoFocus
                                    style={{
                                        width: 'fit-content',
                                        minHeight: 45,
                                        height: 'fit-content',
                                        background: '#0094D8',
                                        borderRadius: 2,
                                        border: '2px #0094D8 solid',
                                        color: 'white',
                                        fontFamily: 'Lato',
                                        fontSize: '14px',
                                        fontWeight: '900',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        marginLeft:'auto',
                                        marginRight:'auto',
                                        padding:'14px 50px !important',

                                    }}
                                    onClick={() => {
                                navigate("/contracts");
                                localStorage.setItem('checkedHundredPercent', true);
                            }}>
                                Jetzt Dokumente erstellen
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                </React.Fragment>
                </>
            :
                <></>
            }
            <Overview/>
        </>
    );
}